.dataCenterSlider {
   margin-top: 60px;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: start;
   gap: 13px;
   > h3 {
      margin: 0;
      font-family: var(--title-txt);
      font-size: clamp(18px, 10vw - 5rem, 32px);
   }
}

.dataCenterSliderElements {
   margin-top: 20px;
   overflow: scroll;
   scrollbar-width: none;
   width: 100%;
}

.dataCenterSliderElements > div:first-child {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: start;
   align-items: baseline;
   gap: 13px;
   scrollbar-width: none;
   width: calc(100% + 75%);
}
