.title {
   margin-top: 40px;
   font-family: var(--title-txt);
   font-size: 32px;
   font-weight: 700;
   line-height: 48px;
   letter-spacing: 0.02em;
   text-align: left;
}

.contentWrapper {
   position: relative;
}

.content {
   border: 1px solid #aeaeae;
   padding: 0;
   border-radius: 12px;
   // padding: 10px 0;
   margin-top: 15px;
   display: flex;
   justify-content: space-around;
   align-items: center;
   overflow-y: scroll;
   -ms-overflow-style: none;
   overflow: -moz-scrollbars-none;
   > div {
      min-width: calc(100% / 3);
      scroll-snap-align: start;
   }
   &::-webkit-scrollbar {
      width: 0;
      height: 0;
   }
}

.sliderContainer {
   position: relative;
}

.slider {
   display: flex;
   overflow-x: auto;
   scroll-snap-type: x mandatory;
   scroll-behavior: smooth;
}

.indicators {
   display: flex;
   justify-content: center;
   position: absolute;
   align-items: center;
   bottom: 10px;
   width: 100%;
}

.indicator {
   width: 7px;
   height: 7px;
   margin: 0 5px;
   border-radius: 50%;
   background-color: #ccc;
}

.active {
   background-color: #000;
   width: 9px;
   height: 9px;
}

@media screen and (max-width: 768px) {
   .title {
      font-size: 24px;
   }

   .content {
      height: 80px;
   }
}

@media screen and (max-width: 560px) {
   .content > div {
      min-width: calc(100% / 2);
   }
}
