.advantages {

  margin: 0 auto;
  margin-top: 80px;

  @media (max-width: 1280px) {
    width: 100%;
  }

  .title {
    font-size: 32px;
    margin-bottom: 20px;
    font-weight: 700;
    letter-spacing: 0.02em;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @media (max-width: 1280px) {
      grid-template-columns: 1fr;
    }
  }
}