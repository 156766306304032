.wrapper {
   margin: 50px auto 0 auto;
   display: flex;
   justify-content: space-between;
   gap: 20px;
}

.infoBlock {
   display: flex;
   align-items: center;
   width: fit-content;
   h3 {
      color: #2d3139;
      margin: 0;
      font-size: 90px;
   }
   p {
      margin-left: 6px;
   }
}

.numberBlock {
   display: flex;
   img {
      margin-bottom: 22px;
   }
}

@media screen and (max-width: 1280px) {
   .wrapper {
      flex-direction: column;
   }
   .infoBlock {
      width: 100%;
   }
}

@media screen and (max-width: 768px) {
   .zero5 {
      width: 90px;
      margin-bottom: 16px;
   }
   .zero3 {
      width: 60px;
   }
   .numberBlock {
      img {
         margin-bottom: 16px;
      }
   }
   .infoBlock {
      gap: 4px;
      h3 {
         font-size: 50px;
      }
   }
}
