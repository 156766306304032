.wrapper {
   display: flex;
   flex-wrap: nowrap;
   overflow-x: auto;
   overflow-y: hidden;
   white-space: nowrap;
   scrollbar-width: none;
   -ms-overflow-style: none;
   padding-bottom: 10px;
}

.wrapper::-webkit-scrollbar {
   display: none;
}

@media screen and (max-width: 720px) {
   .wrapper {
      height: 60px;
      width: 100%;
      align-items: start;
   }
}
