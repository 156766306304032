.wrapper {
   display: flex;
   flex-direction: column;
   justify-content: end;
   align-items: center;
   color: #fff;
   cursor: pointer;
   margin-bottom: 7px;
   height: 50px;
   p {
      margin: 0;
   }
}

.svg {
   height: 100%;
   display: flex;
   align-items: center;
}

.notificationCount {
   position: absolute;
   top: 5px;
   left: calc(50% + 17px);
   background: #f3cb67;
   height: 16px;
   width: 16px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 4px;
   color: #0b0c0e;
   font-size: 12px;
   font-weight: 500;
   line-height: 18px;
   border: 2px solid #000;
   transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 375px) {
   .wrapper {
      p {
         font-size: 12px;
      }
   }
}
