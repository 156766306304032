.orderPopup {
   box-sizing: border-box;
   width: 70%;
   padding: 16px;
   padding-bottom: 30px;
   border-radius: 12px;
   background-color: var(--grey-100);
   height: -moz-fit-content;
   height: fit-content;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: start;
   gap: 20px;
   z-index: 88;
   border: solid 1 px var(--black-400);
   h4,
   p {
      margin: 0;
   }
   h4 {
      font-size: 18px;
   }
   ul {
      padding: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
   }
}

.orderBody {
   align-items: baseline;
   width: 100%;
   display: flex;
   flex-wrap: nowrap;
   justify-content: center;
   align-items: center;
   > span:nth-child(1):not(img) {
      font-size: 14px;
      color: var(--grey-400);
   }
   > span:nth-child(2) {
      font-size: 14px;
      color: var(--grey-400);
      flex-grow: 10;
      height: 14px;
      border-bottom: 1px dashed var(--grey-200);
   }
   > span:last-child {
      font-size: 14px;
      font-weight: 500;
      color: var(--black-400);
   }
}
.orderBack {
   z-index: 999;
   position: fixed;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100dvh;
   background-color: rgba(22, 22, 22, 0.489);
   top: 0;
   left: 0;
   overflow: hidden;
   overflow-y: scroll;
}
.done {
   margin-bottom: 30px;
   align-items: baseline;
   width: 100%;
   display: flex;
   flex-wrap: nowrap;
   justify-content: center;
   > span:nth-child(1) {
      font-size: 14px;
      color: var(--black-400);
   }
   > span:nth-child(2) {
      flex-grow: 10;
      border-bottom: 1px dashed var(--grey-200);
   }
   > span:last-child {
      font-size: 14px;
      font-weight: 500;
      color: var(--black-400);
   }
}
.orderImage {
   width: 64px;
   height: 64px;
   border-radius: 8px;
   margin-right: 12px;
}
.popupHeader {
   margin-bottom: 30px;
   align-items: baseline;
   width: 100%;
   display: flex;
   flex-wrap: nowrap;
   justify-content: space-between;
   >hr {
      width: 100%;
      opacity: 0.3;
      color: var(--grey-200);
   }
   span:last-child {
      width: 25px;
      height: 25px;
      display: flex;
      grid-column: 9;
      border-radius: 8px;
      padding: 5px;
      border: 1px solid white;
      cursor: pointer;
      transition: 0.3s ease-in;
      &:hover {
         background-color: var(--grey-200);
      }
   }
}
@media screen and (max-width: 800px) {
   .orderPopup {
      width: 92%;
   }
}
