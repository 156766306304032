.wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   height: 92px;
}

.logo {
   margin-right: 31px;
   cursor: pointer;
}

.linksWrapper {
   display: flex;
   align-items: center;
}

.link,
.linkLaptop {
   display: flex;
   align-items: center;
   cursor: pointer;
   padding: 10px 18px;
   transition: all 0.3s ease-in-out;
   border-radius: 8px;
   text-wrap: nowrap;
   &:hover {
      background: #f8f8f8;

      div {
         border: 1px solid #e2e4e9;
      }
   }
}

.linkText {
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
   color: var(--black-400);

   @media (max-width: 1280px) {
      display: none;
   }
}

.linkImg {
   height: 20px;
   @media (max-width: 1280px) {
      margin: 0;
      padding: 10px;
   }

   &:hover {
      background: #e2e4e9;
   }
}

.mobileWrapper {
   display: none;
}

.mobilePosBlock {
   padding: 16px;
   justify-content: space-between;
   align-items: center;
   display: flex;
}

.comparePosition {
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: center;
   p {
      margin: 4px 0 0 0;
   }
}

.bgCall {
   background: #f8f8f8;
   margin-left: 10px;
   padding: 10px 16px;
}

.notificationCount {
   position: absolute;
   top: 1px;
   left: calc(50% + 4px);
   background: #ecd06c;
   height: 14px;
   width: fit-content;
   padding: 0 4px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 4px;
   color: #0b0c0e;
   font-size: 12px;
   font-weight: 400;
   line-height: 18px;
   border: 1px solid #fff;
   transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1280px) {
   .wrapper {
      padding: 0 10px;
   }

   .notificationCount {
      left: 25px;
      top: 5px;
   }

   .linkLaptop {
      padding: 0 20pxgit;
   }
   .comparePosition {
      padding: 0 2px;
      height: 44px;
   }
}

@media screen and (min-width: 1280px) {
   .linkLaptop {
      display: none;
   }
}

@media screen and (max-width: 768px) {
   .logo {
      margin-right: 5px;
      height: 30px;
   }

   .wrapper {
      display: none;
   }

   .link {
      margin-left: 6px;
      width: 44px;
   }

   .mobileWrapper {
      display: block;
   }

   .mobilePosBlock {
      padding-bottom: 0;
   }

   .notificationCount {
      top: 6px;
      left: 27px;
   }
}

@media screen and (max-width: 490px) {
   .bgCall {
      display: none;
   }
}

@media screen and (max-width: 320px) {
   .logo {
      margin-right: 20px;
      height: 30px;
   }

   .link {
      img {
         height: 14px;
      }
   }

   .notificationCount {
      top: 3px;
      left: 18px;
      font-size: 10px;
   }
}
