.news {
   margin: auto;
   width: inherit;
   max-width: 1280px;
   overflow-x: hidden;
   margin-top: 25px;
   min-height: 100px;
   border-radius: 10px;
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;
   overflow: initial;
}

.newsHeader {
   width: 100%;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;
}

.newsHeader > h3 {
   font-family: var(--title-txt);
   font-size: clamp(18px, 10vw - 5rem, 32px);
}

.newsHeader > p {
   position: relative;
   font-family: var(--content-txt);
   font-size: clamp(14px, 10vw - 5rem, 16px);
}

.newsHeader > p:hover {
   cursor: pointer;
}

.newsHeader > p::after {
   top: 5px;
   position: relative;
   display: inline-block;
   content: url(../../../accests/image/Arrow_arrow_right.svg);
}

.newsContent {
   overflow: scroll;
   scrollbar-width: none;
   width: 100%;
   overflow-y: hidden;
}

.newsContent > div:first-child {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: start;
   gap: 13px;
   scrollbar-width: none;
   overflow-y: hidden;
   width: 100%;
}
