.wrapper {
    margin: 50px auto 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    align-items: start;


    @media (max-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.frameBig {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 0px;
    border-radius: 16px;
    background: #eff1f6;
}

.titleNumber {
    font-size: 60px;
    font-family: "Noto Sans";
    font-style: normal;
    letter-spacing: 1.2px;
    font-weight: 700;
    color: #252831;
    padding-left: 20px;

    @media (max-width: 1200px) {
        font-size: 48px;
    }

    @media (max-width: 768px) {
        font-size: 36px;
        line-height: 40px;
    }
}

.text {
    font-size: 16px;
    color: #252831;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    font-family: Inter;

    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 24px;
    }
}

.answers {
    font-size: 32px;
    font-weight: 700;
    color: #252831;
    line-height: 48px;
    margin-top: 60px;

    @media (max-width: 1200px) {
        font-size: 28px;
        line-height: 40px;
    }

    @media (max-width: 768px) {
        font-size: 24px;
        line-height: 32px;
    }
}

.answer {
    margin-top: 8px;
    color: #555;
    font-size: 14px;
    line-height: 1.6;
    display: block;
}
.answerText{
    display: flex;
    flex-direction: column;
    gap:10px;
    margin-top: 20px;
}

.questionsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
    grid-auto-rows: min-content;
    align-items: start;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.questionFrame {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    border: 1px solid #e2e4e9;
    border-radius: 16px;
    background: #ffffff;
    transition: all 0.3s ease;
    cursor: pointer;
}

.questionContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.question {
    font-size: 20px;
    font-weight: 600;
    color: #252831;

    @media (max-width: 1200px) {
        font-size: 18px;
    }

    @media (max-width: 768px) {
        font-size: 16px;
    }
}

.iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: transform 0.3s;

    &.iconRotated {
        transform: rotate(180deg);
    }
}

.icon {
    width: 24px;
    height: 24px;
    transition: transform 0.3s;
}

.longText {
    font-size: 16px;
    color: #252831;
    line-height: 24px;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #e2e4e9;

    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 20px;
    }
}