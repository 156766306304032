.wrapper {
   margin-top: 70px;
}

.title {
   display: flex;
   justify-content: space-between;
}

.nameBlock {
   font-family: var(--title-txt);
   font-size: clamp(18px, 10vw - 5rem, 24px);
   font-weight: 700;
   line-height: 48px;
   letter-spacing: 0.02em;
}
@media  screen and (max-width: 650px) {
   .nameBlock {
      font-weight: 600;
   }
}
.linkForPage {
   display: flex;
   align-items: center;
   img {
      margin-left: 10px;
   }
}
.itemsWrapper {
   overflow-x: scroll;
   margin-top: 18px;
   scrollbar-width: none;
}
.sliderWrapper {
   display: flex;
   flex-wrap: nowrap;
   gap: 10px;
   justify-content: space-between;
}
