.newsPagin {
   margin-top: 20px;
   font-family: var(--content-txt);
   font-size: 12px;
   font-weight: 400;
   width: 100%;
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: start;
   align-items: center;
   color: var(--grey-500);
}

.newsPaginShow {
   width: 100%;
   white-space: nowrap;
}
@media screen and (max-width: 960px) {
   .newsPaginShow {
      width: 150px;
   }
}
.newsPaginController {
   margin-top: -34px;
   color: var(--black-400);
   font-size: 14px;
   display: flex;
   flex-wrap: nowrap;
   justify-content: center;
   align-items: center;
   gap: 8px;
   width: 100%;
   flex-grow: 10;
}
@media screen and (max-width: 960px) {
   .newsPaginController {
      width: 90%;
      margin-top: 0px;
   }
}
.newsPaginController > span {
   transition: 0.3s ease-in;
   border-radius: 8px;
   padding: 5px 9px;
   cursor: pointer;
}
.newsPaginController > span:first-child {
   margin-right: 15px;
}
.newsPaginController > span:last-child {
   margin-left: 15px;
}
@media screen and (max-width: 900px) {
   .newsPaginController > span:first-child {
      margin-right: 25px;
   }
   .newsPaginController > span:last-child {
      margin-left: 25px;
   }
}

.newsPaginController > span:not(span:first-child, span:last-child):hover {
   background-color: var(--grey-100);
}
.newsPaginController > span:not(span:first-child, span:last-child) {
   font-size: 12px;
}

.newsPaginController > span:is(span:first-child, span:last-child) {
   font-size: 14px;
   padding: 5px 0px;
   cursor: pointer;
}

.activePage {
   background-color: var(--grey-100);
}

@media screen and (max-width: 1300px) {
   .newsPagin {
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
   }
   .newsPaginController {
      justify-content: center;
   }
   .newsContentPage {
      gap: 10px;
      margin-top: 10px;
   }
}
@media screen and (max-width: 690px) {
   .newsPaginController {
      justify-content: space-around;
      justify-content: center;
      min-width: 340px;
      gap: 0;
   }
}
@media screen and (max-width: 650px) {
   .newsContentPage {
      justify-content: center;
      margin-top: 10px;
   }
   .newsPagin {
      margin-top: -5px;
   }
}
@media screen and (max-width: 450px) {
   .newsPagin {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: start;
   }
}

@media screen and (max-width: 320px) {
   .newsPaginController {
      min-width: 288px;
   }
   .newsPaginController > span:first-child {
      margin-right: 15px;
   }
   .newsPaginController > span:last-child {
      margin-left: 15px;
   }
}
