.contactsMap {
   width: 100%;
   height: 516px;
   margin: 17px 0px;
   border-radius: 12px;
   overflow: hidden;
   margin-bottom: -516px;
}

.popUp {
   white-space: nowrap;
   position: absolute;
   top: 0px;
   left: 70px;
   background-color: white;
   font-size: 14px;
   width: fit-content;
   border-radius: 12px;
   padding: 12px 18px;
}

.mapItemsList {
   overflow: scroll;
   scroll-snap-type: x mandatory;
   scrollbar-width: none;
   width: 318px;
   height: 540px;
   position: relative;
   overflow-x: hidden;

   top: -554px;
   left: 12px;
   z-index: 20;

   div:first-child {
      scroll-snap-type: x mandatory;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: start;
      gap: 2px;
      scrollbar-width: none;
      z-index: 20;
   }
}

.mapCreeper {
   max-width: 1300px;
   top: 248px;
   position: absolute;
   width: 90%;
   height: 555px;
   z-index: 5000;
   background-color: transparent;
   opacity: 1;
}

@media screen and (max-width: 680px) {
   .mapItemsList>div:first-child {
      flex-direction: column;
      width: 318px;
   }

   .mapItemsList {
      position: relative;
      flex-direction: row;
      width: 80%;
      margin: 0px auto;
      margin-top: -140px;
      z-index: 20;
   }
}

@media screen and (max-width: 675px) {
   .mapItemsList>div:first-child {
      width: 322px;
      justify-content: start;
      flex-wrap: nowrap;
      overflow-x: scroll;
      scrollbar-width: none;
      z-index: 1;
      margin-top: 30px;
   }

   .mapItemsList {
      justify-content: start;
      flex-wrap: nowrap;
      overflow-x: scroll;
      scrollbar-width: none;
      width: 340px;
      z-index: 1;
      margin: 0 auto;
      margin-top: 330px;
      height: 563px;
      margin-bottom: 200px;
   }
}