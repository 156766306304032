.errorMain {
   height: 67dvh;
   display: flex;
   flex-direction: column;
   justify-content: center;
}
.errorImage {
   position: absolute;
   z-index: -2;
   width: 100%;
   max-width: 1440px;
}
.errorMainContent {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: center;
   align-items: center;
   gap: 20px;
   > h3 {
      font-size: clamp(16px, 10vw - 5rem, 24px);
      white-space: nowrap;
      text-align: center;
   }
}

.errorButton {
   transition: 0.3s ease;
   color: #fff;
   background: #0b0c0e;
   padding: 12px 26px;
   border-radius: 8px;
   font-size: 14px;
   text-align: center;
   font-weight: 400;
   border: 2px solid #000;
   &:hover {
      box-shadow: 10px 5px 5px #aeaeae;
      cursor: pointer;
   }
}
@media screen and (max-width: 600px) {
   .errorMain {
      height: 36dvh;
   }
}
