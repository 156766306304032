.Post {
   display: flex;
   flex-direction: column;
   align-items: center;
}

@media screen and (max-width: 375px) {
   .Post {
      width: 92dvw;
   }
}

.newCardAutor {
   width: 100%;
   min-width: 323px;
   max-width: 848px;
   display: flex;
   flex-direction: column;
   align-items: start;
   width: 100%;
   text-align: center;

   h3 {
      font-size: 48px;
      font-weight: 700;
      line-height: 52px;
      text-align: center;
      margin: 32px 0;
   }
}

.newCardAutorBio {
   padding-left: 10px;
   align-items: center;
   gap: 17px;
   justify-content: center;
}

@media screen and (max-width: 600px) {
   .newCardAutor > h3 {
      line-height: 24px;
   }
}

.newsDate {
   font-size: 16px;
   font-weight: 500;
   line-height: 22px;
   color: #2d3139;
}

.newCardAutorInfo,
.newCardAutorBio {
   margin: 0;
   padding: 0;
   width: inherit;
   font-family: var(--content-txt);
   list-style: none;
   display: flex;
   cursor: pointer;
}

.newsViews {
   font-size: 12px;
   position: relative;
   top: 2px;
}

.newCardAutorInfo {
   color: var(--grey-400);
   font-size: 14px;
   gap: 10px;
   align-items: baseline;
   display: flex;
   justify-content: center;

   li {
      display: flex;
      align-items: center;
      gap: 3px;

      a {
         text-decoration: underline;
      }

      img {
         display: inline-block;
      }

      a {
         color: var(--grey-400);
      }
   }
}

@media screen and (max-width: 600px) {
   .newCardAutorInfo {
      flex-wrap: wrap;
   }

   .newCardAutorInfo > li > a:first-child {
      width: 100%;
   }
}

.bioAutor {
   margin-top: 6px;
   margin-bottom: 0;
   margin: 0px;
   font-size: clamp(12px, 10vw - 5rem, 14px);
}

.bioPostOwner {
   font-size: 16px;
   font-weight: 500;
   line-height: 22px;
   color: #2d3139;
}

.bioPostDescription {
   margin-top: 6px;
   margin-bottom: 0;
   font-size: clamp(12px, 10vw - 5rem, 14px);
}

.bioAvatar {
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   width: 76px;
   height: 76px;
   border-radius: 50%;
   transition: 0.3s ease-in;

   &:hover {
      transform: scale(1.05);
   }
}

@media screen and (max-width: 400px) {
   .bioAvatar {
      width: 56px;
      height: 56px;
   }
}

.newCardAutorPost {
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 26px;
}

@media screen and (max-width: 400px) {
   .newCardAutorPost {
      margin-top: 10px;
   }
}

.PostImage {
   max-width: 1280px;
   border-radius: 8px;
}

@media screen and (max-width: 1300px) {
   .PostImage {
      width: 100%;
   }
}

@media screen and (max-width: 375px) {
   .PostImage {
      width: 343px;
      height: 226px;
   }
}

.PostContent {
   max-width: 848px;
   margin-top: 64px;
   padding: 0;
   width: 100%;
   font-family: var(--content-txt);
   list-style: none;
   display: flex;
   flex-direction: column;
   gap: 19px;

   h4 {
      font-weight: 500;
      margin: 0;
      font-size: clamp(16px, 10vw - 5rem, 18px);
      font-family: var(--title-txt);
   }
   color: #434956;
   strong {
      color: #16181d;
   }

   div {
      display: flex;
      flex-direction: column;
      gap: 19px;

      p {
         color: var(--grey-400);
         margin: 0;
         font-size: clamp(14px, 10vw - 5rem, 16px);
         a {
            color: blue !important;
            font-weight: 400;
            text-decoration: underline;
         }
      }
   }
}

@media screen and (max-width: 1300px) {
   .PostContent {
      margin-top: 19px;
      line-height: 20px;
      gap: 7px;
   }

   .PostContent > div {
      margin-top: 19px;
      line-height: 20px;
      gap: 7px;
   }
}
