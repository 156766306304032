.partnerWrapper {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   min-width: 33%;
   height: 100px;
}

.partnerItem {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   position: relative;
   &:hover {
      cursor: pointer;
   }
}

.BGImage {
   width: 100%;
   height: 100%;
   position: absolute;
   left: -51px;
   top: 0;
}

.imageLogo {
   z-index: 1;
}

@media screen and (max-width: 768px) {
   .partnerWrapper {
      height: 80px;
   }
   .imageLogo {
      height: 40px;
   }
   .BGImage {
      display: none;
   }
}

@media screen and (max-width: 560px) {
   .partnerWrapper {
      min-width: 48%;
   }
}

@media screen and (max-width: 500px) {
   .imageLogo {
      height: 30px;
   }
}
