.miningMain {
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: center;
}
.miningMainList {
   margin-top: 50px;
   gap: 18px;
   width: 100%;
   padding: 0;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;
   align-items: baseline;
}
@media screen and (max-width: 600px) {
   .miningMainList {
      justify-content: start;
      margin-top: 20px;
      gap: 18px;
   }
}
.miningBanner {
   position: relative;
   > img {
      max-width: 100%;
      border-radius: 8px;
      min-width: 343px;
      min-height: 230px;
   }
   > div {
      position: absolute;
      margin-top: -36.8%;
      margin-left: 5%;
      width: 70%;
      > h3 {
         font-size: clamp(18px, 10vw - 5rem, 32px);
         color: white;
         font-family: var(--title-txt);
         font-weight: 700;
      }
      p {
         line-height: 28px;
         width: 45%;
         font-family: var(--content-txt);
         font-weight: 500;
         font-size: clamp(14px, 10vw - 5rem, 18px);
         color: white;
      }
      a {
         transition: 0.3s ease-in;
         cursor: pointer;
         font-family: var(--content-txt);
         color: var(--black-400);
         background: white;
         padding: 12px 16px;
         border-radius: 8px;
         font-size: 14px;
         text-align: center;
         font-weight: 500;
         border: 2px solid #000;
         &:hover {
            box-shadow: 3px 2px 2px #aeaeae;
            cursor: pointer;
         }
      }
   }
}
@media screen and (max-width: 730px) {
   .miningBanner > div > p {
      width: 100%;
      font-weight: 400;
      line-height: 20px;
   }
   .miningBanner > div {
      width: 85%;
   }
   .miningBanner > div > h3 {
      width: 100%;
   }
}
@media screen and (max-width: 650px) {
   .miningBanner > div {
      margin-top: -240px;
   }
}
@media screen and (max-width: 380px) {
   .miningBanner > div {
      min-height: 190px;
   }
}
@media screen and (max-width: 600px) {
   .miningBanner > div > button {
      padding: 8px 12px;
   }
}
@media screen and (max-width: 380px) {
   .miningBanner > div > button {
      margin-top: 90px;
   }
}
.miningReasons {
   margin-top: 60px;
   display: flex;
   flex-wrap: nowrap;
   justify-content: space-between;
   gap: 20px;
   align-items: center;
}
@media screen and (max-width: 1420px) {
   .miningReasons {
      flex-wrap: wrap;
      justify-content: center;
   }
}
@media screen and (max-width: 520px) {
   .miningReasons {
      margin-top: 20px;
   }
}

.miningReasonsItem {
   width: 416px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: start;
   gap: 18px;
   > p,
   span,
   h4 {
      margin: 0;
      text-align: center;
   }
   > h4 {
      font-weight: 600;
      font-size: clamp(18px, 10vw - 5rem, 24px);
   }
   > p {
      color: #252831;
      line-height: 28px;
      font-weight: 500s;
      font-size: clamp(14px, 10vw - 5rem, 18px);
   }
   > span {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      padding: 20px;
      border-radius: 1000px;
      width: 124px;
      height: 124px;
      background-color: var(--grey-100);
      transition: 0.3s ease-in;
      &:hover {
         background-color: var(--grey-200);
         > img {
            transform: scale(1.1);
         }
      }
      > img {
         transition: 0.7s ease-in;
      }
   }
}
@media screen and (max-width: 380px) {
   .miningReasonsItem > p {
      line-height: 20px;
   }
}
@media screen and (max-width: 520px) {
   .miningReasonsItem {
      width: 100%;
   }
}
@media screen and (max-width: 720px) {
   .miningReasonsItem > span {
      width: 64px;
      height: 64px;
      padding: 8px;
   }
}
.miningWtf {
   width: 100%;
   margin-top: 60px;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 20px 5%;
   justify-content: space-between;
   align-items: baseline;
}
@media screen and (max-width: 960px) {
   .miningWtf {
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
   }
}
.miningWtfLeftCol,
.miningWtfRightCol {
   height: 354px;
   max-width: 47%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: start;
   gap: 13px;
}
@media screen and (max-width: 960px) {
   .miningWtfLeftCol,
   .miningWtfRightCol {
      max-width: 100%;
      justify-content: start;
      gap: 18px;
      height: fit-content;
   }
}
.miningWtfHeader {
   font-size: clamp(18px, 10vw - 5rem, 32px);
   width: 100%;
   text-align: start;
   flex-grow: 100;
}
.miningWtfItem {
   display: flex;
   flex-wrap: nowrap;
   width: 100%;
   height: 102px;
   gap: 20px;
   border-radius: 8px;
   border: 1px solid transparent;
   transition: 0.3s ease-in;
   &:hover {
      transform: scale(1.05);
   }
   > span {
      display: flex;
      flex-direction: column;
      gap: 10px;
      > p {
         color: #252831;
         font-size: clamp(14px, 10vw - 5rem, 16px);
         line-height: 20px;
      }
      > h4 {
         font-size: clamp(16px, 10vw - 5rem, 20px);
      }
      > p,
      h4 {
         margin: 0;
      }
   }
   > p {
      margin: 0;
      font-weight: 800;
      font-size: clamp(18px, 10vw - 5rem, 32px);
      border-radius: 100px;
      box-sizing: border-box;
      background-color: #ecd06c;
      padding: clamp(20px, 10vw - 5rem, 30px);
      width: clamp(32px, 10vw - 5rem, 64px);
      height: clamp(32px, 10vw - 5rem, 64px);
      display: flex;
      justify-content: center;
      align-items: center;
   }
}
@media screen and (max-width: 960px) {
   .miningWtfItem {
      height: fit-content;
   }
}
.miningInvestingOComparis {
   width: 100%;
   margin-top: 60px;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 20px 5%;
   justify-content: space-between;
   align-items: baseline;
   > h3 {
      font-size: clamp(18px, 10vw - 5rem, 32px);
      width: 100%;
      text-align: start;
      flex-grow: 100;
   }
}
@media screen and (max-width: 960px) {
   .miningInvestingOComparis {
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px;
   }
}
.miningInvestingOComparisContent {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   gap: 10px;
   align-items: center;
}
@media screen and (max-width: 1400px) {
   .miningInvestingOComparisContent {
      justify-content: center;
      flex-wrap: wrap;
   }
}
.miningInvestingOComparisItem {
   box-sizing: border-box;
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: center;
   padding: 30px 4px 4px 4px;
   min-width: 308px;
   border: solid 2px var(--grey-100);
   border-radius: 12px;
   > h4 {
      font-size: clamp(18px, 10vw - 5rem, 24px);
   }
}

.miningInvestingOComparisItemImg {
   display: block;
   width: 100px;
   height: 100px;
   > img:nth-child(1) {
      width: 100%;
      height: 100%;
   }
   > img:nth-child(2) {
      position: relative;
      top: -90px;
      left: 10px;
      width: 80px;
      height: 80px;
   }
}
.miningInvestingOComparisItemContent {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 10px;
}
.bad,
.normal,
.good {
   display: flex;
   flex-direction: column;
   gap: 10px;
   box-sizing: border-box;
   width: 100%;
   border-radius: 12px;
   padding: 20px 12px;
   > p {
      margin: 0;
   }
   > p:nth-child(1) {
      font-weight: 500;
      color: var(--grey-400);
      font-size: clamp(14px, 10vw - 5rem, 18px);
   }
   > p:nth-child(2) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      gap: 10px;
      font-weight: 700;
      color: inherit;
      font-size: clamp(20px, 10vw - 5rem, 28px);
      > span:last-child {
         padding: 0;
         overflow: hidden;
         width: 100%;
         border-radius: 12px;
         height: 4px;
         background-color: white;
         margin-top: 14px;
         display: grid;
         grid-template-columns: repeat(10, 1fr);
         > span {
            margin-top: -2px;
            width: 100%;
            height: 14px;
            border-radius: 0;
            display: block;
         }
      }
   }
}
@media screen and (max-width: 450px) {
   .bad > p:nth-child(1),
   .normal > p:nth-child(1),
   .good > p:nth-child(1) {
      font-weight: 400;
   }
}
.bad {
   background-color: #e5484d1a;
   color: #e5484d;
}
.good {
   background-color: #46a7581a;
   color: #46a758;
}
.normal {
   background-color: #f76b151a;
   color: #f76b15;
}
.badLine {
   background-color: #e5484d;
}
.goodLine {
   background-color: #46a758;
}
.normalLine {
   background-color: #f76b15;
}

.miningWhyUs {
   margin-top: 60px;
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: start;
   gap: 28px;
   > h3,
   p {
      margin: 0;
   }
   > h3 {
      font-weight: 600;
      font-size: clamp(18px, 10vw - 5rem, 32px);
   }
   > p {
      line-height: clamp(20px, 10vw - 5rem, 28px);
      font-size: clamp(14px, 10vw - 5rem, 18px);
      width: 40%;
   }
   > div {
      margin-top: 0px;
   }
}
@media screen and (max-width: 850px) {
   .miningWhyUs > p {
      min-width: 88%;
   }
}

@media screen and (max-width: 400px) {
   .miningInvestingOComparisItem {
      min-width: 343px;
   }
}

@media screen and (max-width: 320px) {
   .miningInvestingOComparisItem {
      min-width: 298px;
   }
}
