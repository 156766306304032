.newsContentItemSmall {
   cursor: pointer;
   padding: 0;
   min-height: 372px;
   min-width: 416px;
   max-width: 416px;
}



.newsContentItemSmall>div:nth-child(1) {
   width: 99%;
   height: 277px;
   border-radius: 12px;
   background-position: center;
   background-size: 135%;
   background-repeat: no-repeat;

   &:hover {
      box-shadow: 1px 1px 30px #e2e4e9;
   }
}

.newsContentItemSmall>p {
   padding: 0px 8px;
   margin-top: 12px;
   display: flex;
   justify-content: space-between;
}

.newsContentItemSmall>p>span {
   position: relative;
   font-family: var(--content-txt);
   font-size: clamp(12px, 10vw - 5rem, 14px);
}

.newsContentItemSmall>h3 {
   margin-top: 1px;
   font-family: var(--content-txt);
   font-size: clamp(16px, 10vw - 5rem, 18px);
   font-weight: 500;
   line-height: 28px;
   margin-bottom: 11px;
   padding: 0px 8px;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
   line-clamp: 2;
   -webkit-box-orient: vertical;
}

.newsContentItemSmall>h4 {
   font-family: var(--content-txt);
   font-size: 18px;
   font-weight: 400;
   color: var(--grey-400);
   margin-top: -2px;
}

@media screen and (max-width: 500px) {
   .newsContentItemSmall {
      min-width: 300px;
      min-height: 296px;
   }

   .newsContentItemSmall>div:nth-child(1) {
      height: 196px;
   }
}