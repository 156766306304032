.footer {
   box-sizing: border-box;
   margin: 50px auto 20px auto;

   a {
      display: block;
      height: fit-content;

      img {
         cursor: pointer;
      }
   }
}

.footerLogo {
   div {
      cursor: pointer;
   }
   display: flex;
   align-items: center;
}

.logoText {
   margin-left: 24px;
   color: #4f4d55;
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
   letter-spacing: -0.05px;
}

.footerNav {
   margin-top: 12px;
   gap: 24px;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: space-between;
   align-items: baseline;
   > ul {
      width: 23%;
      &:first-child {
         width: 40%;
      }
   }
}

.footerNavColumn {
   padding: 0;
   margin: 0;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: start;
   list-style: none;
   font-family: var(--content-txt);
   gap: 21px;
   font-weight: 500;
   font-size: clamp(14px, 10vw - 5rem, 15px);
   li {
      border-bottom: 1px solid #fff;
      transition: all 0.3s ease;
      text-wrap: nowrap;
      &:hover {
         cursor: pointer;
         border-bottom: 1px solid #000;
      }
   }
   .mobile {
      display: none;
   }
}

.contactLinks {
   display: flex;
   gap: 8px;
}

.contactWrapper {
   display: flex;
   gap: 25px;
   > a {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #fff;
      transition: all 0.3s ease;
      > img {
         margin-right: 6px;
         border-bottom: 1px solid #fff;
      }
      &:hover {
         border-bottom: 1px solid #000;
         > img {
            border-bottom: 1px solid #fff;
         }
      }
   }
}

@media screen and (max-width: 1280px) {
   .footer {
      margin: 50px auto 100px auto;
   }
}

@media screen and (max-width: 890px) {
   .footerNav {
      flex-direction: column;
      > ul {
         width: 90%;
         &:first-child {
            width: 90%;
         }
      }
   }
   .footerLogo {
      flex-direction: column;
      align-items: start;
      > span {
         margin: 10px 0;
         font-weight: 400;
      }
   }

   .footerNavColumn {
      .noMobile {
         display: none;
      }
      .mobile {
         display: block;
      }
   }
}

@media screen and (max-width: 375px) {
   .contactWrapper {
      flex-direction: column;
   }
}
