.sliderItem,
.sliderItemActive {
   transition: 0.8s ease-in;
   box-sizing: border-box;
   width: 416px;
   padding: 2px 2px 18px 2px;
   border-radius: 8px;
   border: 2px solid var(--grey-200);
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: center;
   gap: 10px;

   &:hover {
      border: 2px solid var(--black-400);
   }
}

.dataCenterImg {
   border-radius: 8px;
   height: 248px;
   background-position: center;
   background-size: 200%;
   background-repeat: no-repeat;

   >img {
      opacity: 0;
   }
}

@media screen and (max-width: 700px) {

   .sliderItem,
   .sliderItemActive {
      min-width: 252px;
   }
}

.dataCenterList {
   display: flex;
   flex-direction: column;
   gap: 12px;
   padding: 0;

   >li {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 14px;
   }
}

.dataCenterInfoBlock {
   display: flex;
   flex-direction: column;
   width: 94%;
}

.dataCenterName {
   font-weight: 600;
   font-size: clamp(16px, 10vw - 5rem, 20px);
   margin: 0;
}

@media screen and (max-width:1120px) {
   .dataCenterName {
      font-weight: 500;
      min-height: 40px;
   }

   .sliderItem,
   .sliderItemActive {
      max-height: 400px;
   }
}

@media screen and (max-width: 600px) {
   .dataCenterName {
      font-weight: 500;
   }
}

.sliderItemActive {
   border: 2px solid var(--black-400);
}

.dataCenterImg {
   width: 100%;
   border-radius: 8px;
}

@media screen and (max-width: 700px) {
   .dataCenterImg {
      height: 178px;
   }
}

.have {
   color: #00ad64;
}

.hvnt {
   color: #d80000;
}

.moarButton {
   transition: 0.3s ease-in;
   display: inline-block;
   width: calc 85%;
   color: #fff;
   background: #0b0c0e;
   padding: 12px 26px;
   border-radius: 8px;
   font-size: 14px;
   text-align: center;
   font-weight: 400;
   border: 2px solid #000;

   &:hover {
      box-shadow: 10px 5px 5px #aeaeae;
      cursor: pointer;
   }
}