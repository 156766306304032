.dataCenterBaner {
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   padding: 5% 1% 13% 6%;
   border-radius: 10px;
   > img {
      max-width: 100%;
      border-radius: 8px;
      min-height: 230px;
   }
}
.bannerContent {
   color: white;
   width: 70%;
   height: 264px;

   h3 {
      font-size: clamp(18px, 10vw - 5rem, 32px);
      font-family: var(--title-txt);
      font-weight: 700;
      margin: 0;
   }
   p {
      line-height: 28px;
      width: 45%;
      font-family: var(--content-txt);
      font-weight: 500;
      font-size: clamp(14px, 10vw - 5rem, 18px);
      margin: 30px 0 0 0;
   }
}

@media screen and (max-width: 730px) {
   .bannerContent {
      width: 85%;
   }
   .bannerContent > h3 {
      width: 100%;
   }
   .bannerContent > p {
      width: 100%;
      font-weight: 400;
      line-height: 20px;
   }
}

.bannerButton {
   margin-top: 20px;
   display: block;
   width: fit-content;
   transition: 0.3s ease-in;
   cursor: pointer;
   font-family: var(--content-txt);
   color: var(--black-400);
   background: white;
   padding: 12px 16px;
   border-radius: 8px;
   font-size: 14px;
   text-align: center;
   font-weight: 500;
   border: 2px solid #000;
   &:hover {
      box-shadow: 3px 2px 2px #aeaeae;
      cursor: pointer;
   }
}

@media screen and (max-width: 600px) {
   .bannerButton {
      padding: 8px 12px;
   }
}
.dataCenterOptions {
   margin-top: 100px;
   // min-height: 400px;
   // margin-bottom: 280px;
   height: 800px;
   color: white;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
}
// @media screen and (max-width: 1350px) {
//    .dataCenterOptions {
//       height: 406px;
//    }
// }
@media screen and (max-width: 950px) {
   .dataCenterOptions {
      height: 1400px;
   }
}

@media screen and (max-width: 650px) {
   .dataCenterOptions {
      height: 1200px;
   }
}

@media screen and (max-width: 450px) {
   .dataCenterOptions {
      height: 1150px;
   }
}

.dataCenterOptionsContainer {
   color: white;
   position: absolute;
   left: 0%;
   width: 100%;
   background-color: var(--black-400);
   &::after {
      width: 100%;
      top: 49px;
      height: 50px;
      clip-path: polygon(0 0, 100% 50px, 100% 0%);
      background-color: var(--black-400);
      display: block;
      position: relative;
   }
   &::before {
      width: 100%;
      margin-top: -49px;
      height: 50px;
      clip-path: polygon(0 0, 0px 50px, 100% 50px);
      background-color: var(--black-400);
      display: block;
      content: '';
   }
   &::after {
      width: 100%;
      margin-top: -50px;
      height: 50px;
      clip-path: polygon(0 0, 100% 50px, 100% 0%);
      background-color: var(--black-400);
      display: block;
      content: '';
   }
}
// @media screen and (max-width: 950px) {
//    .dataCenterOptionsContainer {
//       height: 900px;
//    }
// }
.dataCenterElementInfo {
   width: 50%;
   display: flex;
   flex-direction: column;
   padding: 20px;
   > h4 {
      white-space: nowrap;
      font-size: var(--title-txt);
      margin: 0;
      font-size: clamp(16px, 10vw - 2rem, 28px);
   }
   > p {
      width: 400px;
      font-size: clamp(14px, 10vw - 5rem, 18px);
      line-height: 28px;
   }
}
@media screen and (max-width: 1300px) {
   .dataCenterElementInfo > p {
      width: 340px;
   }
   .dataCenterElementInfo > h4 {
      white-space: wrap;
   }
}
@media screen and (max-width: 1070px) {
   .dataCenterElementInfo > p {
      width: 310px;
      font-size: 16px;
   }
   .dataCenterElementInfo > h4 {
      width: 310px;
      white-space: nowrap;
      font-size: 20px;
   }
}

@media screen and (max-width: 600px) {
   .dataCenterElementInfo > h4 {
      width: 310px;
      white-space: nowrap;
      font-size: 20px;
      font-size: clamp(16px, 10vw - 2rem, 28px);
   }
}

@media screen and (max-width: 650px) {
   .dataCenterElementInfo > p {
      width: 280px;
      line-height: 22px;
      margin: 7px 0;
      font-size: clamp(14px, 10vw - 5rem, 18px);
   }
   .dataCenterElementInfo {
      padding: 10px;
   }
}
@media screen and (max-width: 450px) {
   .dataCenterElementInfo > p {
      margin: 9px 0;
      width: 240px;
   }
}

.dataCentersContainer {
   max-width: 1280px;
   margin: 0 auto;
   padding-top: 70px;
   // height: 630px;
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: center;
   gap: 60px;
}
// @media screen and (max-width: 950px) {
//    .dataCentersContainer {
//       height: 829px;
//    }
// }
.dataCentersContentTitle {
   height: 0;
   margin: 0;
   width: 1280px;
   text-align: start;
   font-size: clamp(18px, 10vw - 5rem, 32px);
   color: white;
   font-family: var(--title-txt);
   font-weight: 700;
}
@media screen and (max-width: 1280px) {
   .dataCentersContentTitle {
      width: 90%;
      padding: 0px 10px;
   }
}
.dataCenterContentWrapper {
   width: 1280px;
   // height: 350px;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;
   gap: 10px 10px;
   padding-bottom: 80px;
}

@media screen and (max-width: 1280px) {
   .dataCenterContentWrapper {
      width: 90%;
      gap: 18px;
      justify-content: center;
   }
}
@media screen and (max-width: 750px) {
   .dataCenterContentWrapper {
      flex-direction: row;
      align-items: center;
      width: 100%;
      // height: 552px;
   }
}
.dataCenterElement {
   width: 632px;
   height: 163px;
   border-radius: 8px;
   border: solid 1px rgba(255, 255, 255, 0.116);
   background-color: rgba(255, 255, 255, 0.116);
   display: flex;
   justify-content: space-between;
   transition: 0.3s ease-in;
   &:hover {
      transform: scale(1.01);
   }
}

.secondPrice {
   p {
      margin: 0;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 12px;
   }
   span {
      display: block;
      margin-top: 4px;
      font-size: 15px;
   }
}
@media screen and (max-width: 1430px) {
   .dataCenterElement {
      overflow: hidden;
      width: 45%;
   }
}
@media screen and (max-width: 950px) {
   .dataCenterElement {
      width: 90%;
      height: 163px;
   }
   .dataCenterElement > img {
      width: auto;
   }
}
@media screen and (max-width: 650px) {
   .dataCenterElement {
      width: 90%;
      height: 133px;
   }
   // .dataCenterElement > img {
   //    width: 33%;
   // }
}
@media screen and (max-width: 450px) {
   .dataCenterElement {
      width: 95%;
      height: 123px;
   }
   // .dataCenterElement > img {
   //    width: 33%;
   // }
}
