.containerCourses {
   display: flex;
   justify-content: space-between;
   align-items: center;
   height: 40px;
   background: #f8f8f8;
   margin-top: 20px;
}

.coursesWrapper {
   width: 100%;
}

@media screen and (max-width: 768px) {
   .mobileHidden {
      overflow: hidden;
   }
}
