.news {
   min-height: 100px;
   border-radius: 10px;
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;
   overflow: initial;
   scroll-behavior: smooth;
   box-sizing: border-box;
}

.newsContent {
   overflow-x: scroll;
   width: 100%;
   overflow-y: hidden;
}

.newsHeaderPage {
   width: 100%;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   // justify-content: start;
   gap: 12px;
   // align-items: center;
   flex-direction: column;
   > h3 {
      margin: 0;
   }
}

@media screen and (max-width: 1000px) {
   .newsHeaderPage {
      align-items: start;
      flex-direction: column;
      gap: 20px;
   }

   .newsHeaderPage > h3 {
      margin: 0;
   }
}

@media screen and (max-width: 500px) {
   .newsHeaderPage {
      flex-wrap: wrap;
      gap: 9px;
   }

   .newsHeaderPage > h3 {
      margin: 0;
   }
}

.newsHeaderPage > h3 {
   font-family: var(--title-txt);
   font-size: clamp(18px, 10vw - 5rem, 32px);
}

.newsHeaderPageTabs {
   display: flex;
   justify-content: space-between;
   align-items: center;
   max-width: 342px;
   height: 54px;
   border-radius: 9px;
   background-color: var(--grey-100);
   font-family: var(--content-txt);
}

.newsHeaderPageTabs > span {
   display: flex;
   position: relative;
   border-radius: 8px;
   width: 31%;
   padding: 12px 16px;
   margin: 0px 6px;
   height: 20px;
}

.newsHeaderPageTabs > span:hover {
   cursor: pointer;
   background-color: var(--grey-100);
}

.newsHeaderPageTabs > span > span:last-child {
   position: relative;
   font-size: 12px;
   left: 6px;
   height: 20px;
   width: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 4px;
   color: #000;
   background-color: #f3cb67;
}

.newsHeaderPageActive {
   background-color: #ffff;
}

.newsContentPage {
   margin-top: 30px;
   width: 100%;
   display: flex;
   flex-wrap: wrap;
   flex-direction: row;
   justify-content: space-between;
   gap: 1%;
}
@media screen and (max-width: 1450px) {
   .newsContentPage {
      height: fit-content;
      gap: 2%;
   }
}
@media screen and (max-width: 950px) {
   .newsContentPage {
      gap: 2%;
      padding-bottom: 20px;
   }
}
@media screen and (max-width: 650px) {
   .newsContentPage {
      flex-direction: column;
      justify-content: start;
      align-items: center;
   }
}
@media screen and (max-width: 500px) {
   .newsHeaderPageTabs {
      width: 100%;
      height: 44px;
      font-size: 14px;
   }

   .newsHeaderPageTabs > span {
      width: auto;
      padding: 8px 14px;
   }
}

@media screen and (max-width: 320px) {
   .newsHeaderPageTabs {
      max-width: 290px;
      min-width: 290px;
      > span {
         padding: 6px 8px;
      }
   }
}
