.main {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
.whyUsText{
    font-size: 32px;
    font-weight: 700;
    color: #252831;
    line-height: 48px;
}


.headerPr {
    align-self: flex-start;
    margin-bottom: -40px;
    margin-top: 80px;
    font-size: 32px;
    font-weight: 700;
}

.whyUs {
    margin-top: 74px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 20px;

    >h3,
    p {
        margin: 0;
    }

    >h3 {
        font-weight: 600;
        font-size: clamp(18px, 10vw - 5rem, 32px);
    }

    >p {
        line-height: clamp(20px, 10vw - 5rem, 28px);
        font-size: clamp(14px, 10vw - 5rem, 18px);
        width: 40%;
    }

    >div {
        margin-top: 0px;
    }
}

@media screen and (max-width: 850px) {
    .whyUs>p {
        min-width: 88%;
    }
}