.profileMain {
   > h3 {
      margin: 0;
      margin-top: 30px;
      margin-bottom: 30px;
      font-weight: 700;
      font-size: clamp(18px, 10vw - 5rem, 32px);
   }
}

@media screen and (max-width: 650px) {
   .profileMain > h3 {
      font-weight: 600;
   }
}
.profileController {
   display: flex;
   padding: 0;
   flex-direction: row;
   flex-wrap: nowrap;
   gap: 20px;
   justify-content: baseline;
}
@media screen and (max-width: 750px) {
   .profileController {
      flex-direction: column;
   }
}
.profileControllerRightCol {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   box-sizing: border-box;
   border-radius: 12px;
   padding: 20px;
   background-color: var(--grey-100);
   min-width: 343px;
}
@media screen and (max-width: 400px) {
   .profileControllerRightCol {
      padding: 5px 5px;
   }
}
