.wrapper {
   position: relative;
   width: 100%;
   margin-right: 86px;
   z-index: 4;
}

.searchImg {
   position: absolute;
   top: 12px;
   left: 14px;
}

.input {
   width: 100%;
   padding: 12px 16px 12px 46px;
   border-radius: 8px;
   border: 2px solid #e2e4e9;

   &::placeholder {
      font-family: Arial;
      font-size: 14px;
      line-height: 20px;
   }
}

.select {
   position: absolute;
   top: 50px;
   left: 0;
   z-index: 1;
   width: 111%;
}

.cross {
   position: absolute;
   right: -55px;
   top: 10px;
   z-index: 1;
   cursor: pointer;
}

.optionsWrapper {
   border: 1px solid #e2e4e9;
   border-top: none;
   border-radius: 10px;
   background: #f8f8f8;
   overflow: hidden;
   max-height: 205px;
   overflow-y: scroll;
   overflow: -moz-scrollbars-none;
   &::-webkit-scrollbar {
      //width: 0;
      //height: 0;
   }
}
.closeSelect {
   position: fixed;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
}

.option {
   color: #000;
   display: flex;
   width: 100%;
   align-items: center;
   padding: 0 77px 0 24px;
   height: 36px;
   font-family: var(--title-txt);
   font-size: 14px;
   font-weight: 500;
   line-height: 28px;
   border-bottom: 1px solid #e2e4e9;
   position: relative;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   &:last-child {
      border-bottom: none;
   }
   &:hover {
      background: #e2e4e9;
   }
}

@media screen and (max-width: 1280px) {
   .wrapper {
      //z-index: 0;
   }
}

@media screen and (max-width: 900px) {
   .wrapper {
      width: 86.5%;
      padding: 11px 16px;
      display: none;
   }

   .searchImg {
      top: 17px;
      left: 25px;
   }
   .cross {
      right: -41px;
      top: 19px;
   }
   .select {
      top: 56px;
      width: 105%;
      margin-left: 17px;
   }
}

@media screen and (max-width: 640px) {
   .wrapper {
      width: 80.5%;
   }
   .select {
      width: 106%;
   }
   .input {
      padding: 14px 16px 14px 46px;
   }
   .searchImg {
      top: 23px;
      left: 28px;
      height: 22px;
   }
}

@media screen and (max-width: 520px) {
   .wrapper {
      width: 79.5%;
   }
   .select {
      width: 107%;
   }
}

@media screen and (max-width: 490px) {
   .wrapper {
      width: 77.5%;
   }
}

@media screen and (max-width: 450px) {
   .wrapper {
      width: 75.5%;
   }
}

@media screen and (max-width: 320px) {
   .wrapper {
      width: 68.5%;
   }
}
