.wrapper {
   font-family: Inter, sans-serif;
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
   background: #f8f8f8;
   margin: 10px 16px 0 0;
   padding: 12px 16px;
   border-radius: 8px;
   transition: all 0.3s ease-in-out;
   &:hover {
      box-shadow: 4px 3px 3px gray;
      cursor: pointer;
   }
}

.active {
   background: var(--black-400);
   color: #fff;
}
