.wrapper {
   background: #efeded;
   color: #000;
   font-family: var(--content-txt);
   padding: 24px;
   margin-top: 20px;
   border-radius: 16px;
}

.title {
   font-size: 20px;
   font-weight: 700;
   line-height: 28px;
}

.selectWrapper {
   display: flex;
   align-items: center;
   gap: 20px;
   > div {
      min-width: 46.8%;
   }
}

.label {
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
   margin-top: 20px;
}

.optionWraper {
   display: flex;
   margin-top: 16px;
   align-items: center;
}

.option {
   display: flex;
   margin-right: 16px;
   gap: 8px;
}

.optionText {
   font-size: 16px;
   font-weight: 500;
   line-height: 22px;
   margin-top: 2px;
}

.input {
   width: 95%;
   border-radius: 8px;
   padding: 10px;
   margin-top: 10px;
   border: 1px solid #a9a9a9;
   transition: all 0.3s ease-in-out;

   &:hover {
      box-shadow: 10px 5px 5px #aeaeae;
   }
}

.inputSearch {
   transition: all 0.3s ease-in-out;
   border: none;
   &:hover {
      box-shadow: 10px 5px 5px #aeaeae;
   }
}

.crossImg {
   position: absolute;
   top: 10px;
   right: 8px;
   transition: all 0.4s ease;
}

.rotateImg {
   transform: rotate(180deg);
}

.posA {
   position: relative;
}

.inputText {
   color: #8c94a6;
   background: #fff;
   border-radius: 8px;
   padding: 12px;
   &::placeholder {
      color: #8c94a6;
   }
}

.optionCheckBox {
   height: 24px;
   width: 24px;
   border-radius: 6px;
   background: #fff;
   border: 1px solid #6d6d6d;
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;
   transition: all 0.3s ease-in-out;

   &:hover {
      box-shadow: 3px 2px 2px #aeaeae;
   }
}
.optionActiveCheckBox {
   background: #000;
}

.callbackForm {
   display: flex;
   gap: 20px;
   align-items: center;
   margin-top: 16px;
   button {
      width: 65%;
      height: 44px;
      padding: 1px 18px;
      background-color: var(--black-400);
      transition: all 0.3s ease-in-out;
      color: var(--grey-100);
      font-family: var(--content-txt);
      border-radius: 8px;
      font-size: 14px;
      text-align: center;
      font-weight: 400;
      border: 2px solid #000;
      &:hover {
         box-shadow: 10px 5px 5px #aeaeae;
         cursor: pointer;
      }
   }
   p {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: #8c94a6;
      a {
         color: #000;
         text-decoration: underline;
      }
   }
}

.select {
   display: flex;
   justify-content: space-between;
   align-items: center;
   line-height: 20px;
   position: relative;
   cursor: pointer;
   width: 95%;
   border-radius: 8px;
   margin-top: 10px;
   border: 1px solid #a9a9a9;
   background: #fff;
   padding: 8px;
   font-size: 12px;
   p {
      margin: 0;
   }
   transition: all 0.3s ease-in-out;

   &:hover {
      box-shadow: 10px 5px 5px #aeaeae;
   }
}

.crossWrapper {
   border-radius: 50%;
   background: red;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 6px;
}

.optionsWrapper {
   position: absolute;
   top: 42px;
   left: 0px;
   display: flex;
   flex-direction: column;
   z-index: 1;
   border: 1px solid #0a090b1f;
   width: 100%;
   border-radius: 8px;
   background: #fff;
   overflow: scroll;
   max-height: 220px;
}

.optionSelect {
   padding: 9px 10px 9px 21px;
   border-bottom: 1px solid #e2e4e9;
   text-wrap: nowrap;
   &:last-child {
      border: none;
   }
   &:hover {
      background: #f8f8f8;
      cursor: pointer;
   }
}

.closeFilter {
   position: fixed;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   background: none;
   opacity: 0;
}

@media screen and (max-width: 768px) {
   .callbackForm {
      button {
         width: 55%;
      }
   }
}

@media screen and (max-width: 600px) {
   .selectWrapper {
      flex-direction: column;
      gap: 0px;
      > div {
         min-width: 100%;
      }
   }
   .callbackForm {
      flex-direction: column;
      button {
         width: 100%;
         margin-top: 10px;
      }
      p {
         align-self: start;
      }
   }
}

@media screen and (max-width: 510px) {
   .optionWraper {
      flex-direction: column;
      align-items: start;
   }
   .option {
      margin-top: 8px;
   }
}
