._{
    display: flex;
    align-items: center;
    background: #2AABED;
    gap: 8px;
    border-radius: 8px;
    padding: 2px 20px 2px 16px;
    height: 40px;
    cursor: pointer;
    transition: all .3s ease;
}
._:hover{
    opacity: .9;
}
.text1{
    color: #FFFFFF;
    font-weight: 500;
    font-size: 11px;
    white-space: nowrap;
}
.text2{
    color: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
}
@media screen and (max-width: 768px) {
    ._{
        width: calc(50% - 42px);
        justify-content: center;
    }
}

@media screen and (max-width: 376px) {
    ._{
        width: calc(50% - 42px);
    }
    .text1{
        font-size: 10px;
    }
    .text2{
        font-size: 12px;
    }
}