.sucModal {
   box-sizing: border-box;
   width: 375px;
   padding: 32px;
   padding-bottom: 30px;
   border-radius: 12px;
   background-color: var(--grey-100);
   height: -moz-fit-content;
   height: fit-content;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;
   gap: 28px;
   z-index: 88;
   border: solid 1 px var(--black-400);
   h4,
   p {
      margin: 0;
      text-align: center;
   }
   h4 {
      font-weight: 700px;
      font-size: 20px;
   }
   p {
      color: var(--grey-300);
      font-size: 16px;
   }
   img {
      margin-top: 11px;
   }
   span {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
   }
}

.sucBody {
   align-items: baseline;
   width: 375px;
   display: flex;
   flex-wrap: nowrap;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   > span:nth-child(1):not(img) {
      font-size: 14px;
      color: var(--grey-400);
   }
   > span:nth-child(2) {
      font-size: 14px;
      color: var(--grey-400);
      flex-grow: 10;
      height: 14px;
      border-bottom: 1px dashed var(--grey-200);
   }
   > span:last-child {
      font-size: 14px;
      font-weight: 500;
      color: var(--black-400);
   }
}
.sucBack {
   z-index: 999;
   position: fixed;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
   background-color: rgba(22, 22, 22, 0.489);
   top: 0;
   left: 0;
   overflow: hidden;
   overflow-y: scroll;
}

.btnCom {
   display: inline-block;
   font-family: var(--content-txt);
   transition: 0.3s ease;
   color: var(--black-400);
   width: 100%;
   background: var(--grey-200);
   margin: 0 auto;
   padding: 12px 26px;
   border-radius: 8px;
   font-size: 14px;
   text-align: center;
   font-weight: 500;
   border: none;
   &:hover {
      box-shadow: 10px 5px 5px #aeaeae;
      cursor: pointer;
   }
}
.shopping {
   width: 100%;
   transition: 0.3s ease-in;
   display: inline-block;
   color: #fff;
   background: #0b0c0e;
   padding: 12px 26px;
   border-radius: 8px;
   font-size: 14px;
   text-align: center;
   font-weight: 400;
   border: 2px solid #000;
   &:hover {
      box-shadow: 10px 5px 5px #000;
      cursor: pointer;
   }
}
