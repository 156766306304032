.newsContentItemSmallAlt {
   display: flex;
   flex-direction: column;
   cursor: pointer;
   gap: 6px;
   padding: 0;
   width: 32%;
   margin-bottom: 25px;
   transition: 0.3s ease-in;
   &:hover {
      transform: scale(1.01);
   }
   > span:first-child {
      height: 232px;
      display: block;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
   }
}

.newsContentItemSmallAlt > span {
   border-radius: 12px 12px 0 0;
}

.textWrapper {
   border: 1px solid #e2e4e9;
   margin-top: -6px;
   border-bottom-right-radius: 12px;
   border-bottom-left-radius: 12px;
   padding: 24px;
   color: #596173;
}
.theme {
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
}
.title {
   font-size: 24px;
   font-weight: 700;
   line-height: 28px;
   color: #0b0c0e;
   height: 60px;
   margin-top: 8px;
   overflow: hidden;
}

.author {
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
   color: #80899c;
   margin-top: 10px;
}

@media screen and (max-width: 1200px) {
   .newsContentItemSmallAlt {
      width: 49%;
   }
}
@media screen and (max-width: 768px) {
   .newsContentItemSmallAlt {
      width: 100%;
   }
}
