.formOrder {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   gap: 20px;
   flex-wrap: nowrap;
}
@media screen and (max-width: 1341px) {
   .formOrder,
   .orderingContent {
      width: 100%;
   }
}
@media screen and (max-width: 1341px) {
   .formOrder {
      flex-wrap: wrap;
   }
}
.orderingContent {
   display: flex;
   flex-direction: column;
   justify-content: start;
   gap: 20px;
   flex-wrap: nowrap;
   h3 {
      display: flex;
      align-items: baseline;
      justify-content: start;
      gap: 8px;
      flex-wrap: nowrap;
      span:first-child {
         font-size: clamp(18px, 10vw - 5rem, 32px);
      }
      span:last-child {
         font-weight: 400;
         font-size: clamp(14px, 10vw - 5rem, 18px);
      }
   }
}
.rightColMain {
   box-sizing: border-box;
   width: 309px;
   padding: 16px;
   border-radius: 12px;
   background-color: var(--grey-100);
   height: fit-content;
}
.fomrLeftCol {
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: start;
   gap: 20px;
   flex-wrap: nowrap;
   width: calc(100% - 329px);
}
@media screen and (max-width: 750px) {
   .fomrLeftCol,
   .rightColMain {
      width: 100%;
   }
}
.formSwitcherSwitch {
   cursor: pointer;
   display: flex;
   align-items: center;
   text-align: center;
   min-height: 46px;
   padding: 5px 18px;
   border-radius: 8px;
   p {
      margin: 0;
      border-radius: 8px;
      font-size: clamp(16px, 10vw - 5rem, 16px);
      color: var(--grey-400);
   }
}
@media screen and (max-width: 865px) {
   .formSwitcherSwitch {
      width: 100%;
   }
}
.formSwitcher {
   box-sizing: border-box;
   width: fit-content;
   display: flex;
   flex-direction: row;
   justify-content: start;
   align-items: center;
   gap: 30px;
   border-radius: 8px;
   padding: 5px 5px;
   background-color: var(--grey-100);
   div {
      cursor: pointer;
      display: flex;
      align-items: center;
      text-align: center;
      min-height: 46px;
      padding: 5px 18px;
      border-radius: 8px;
      p {
         margin: 0;
         border-radius: 8px;
         font-size: clamp(16px, 10vw - 5rem, 16px);
         color: var(--grey-400);
      }
   }
   .active {
      font-size: clamp(16px, 10vw - 5rem, 16px);
      background-color: white;
      p {
         color: black;
      }
   }
}
@media screen and (max-width: 865px) {
   .formSwitcher {
      flex-wrap: wrap;
      gap: 6px;
   }
}
@media screen and (max-width: 865px) {
   .formSwitcher > div {
      width: 100%;
   }
}
.removed {
   position: relative;
   top: 12px;
   font-size: 13px;
   color: var(--blue-200);
   width: 100%;
   display: flex;
   justify-content: end;
   text-decoration: line-through;
}
.total {
   align-items: baseline;
   width: 100%;
   display: flex;
   flex-wrap: nowrap;
   justify-content: center;
   span:first-child {
      font-size: 18px;
      color: (--grey-400);
   }
   span:nth-child(2) {
      flex-grow: 10;
      border-bottom: 1px dashed var(--grey-200);
   }
   span:last-child {
      font-weight: 700;
      font-size: 20px;
      color: var(--black-400);
   }
}
.units {
   align-items: baseline;
   width: 100%;
   display: flex;
   flex-wrap: nowrap;
   justify-content: center;
   span:nth-child(1) {
      font-size: 14px;
      color: var(--grey-400);
   }
   span:nth-child(2) {
      flex-grow: 10;
      border-bottom: 1px dashed var(--grey-200);
   }
   span:last-child {
      font-size: 14px;
      font-weight: 500;
      color: var(--black-400);
   }
}

.promoError {
   margin-top: 10px;
   font-size: 12px;
   color: var(--orange-100);
}
.checkPromo {
   width: 100%;
   display: flex;
   flex-wrap: nowrap;
   justify-content: space-around;
   align-items: center;
   gap: 25px;
   > input {
      border: 1px solid var(--black-100);
      border-radius: 8px;
      font-family: var(--content-txt);
      color: var(--blue-200);
      padding: 14px;
      flex-grow: 2;
   }
   & > input:focus-visible {
      border-radius: 8px;
      outline: solid 2px #ecd06c;
   }
   > button {
      border: none;
      background-color: var(--grey-100);
      &:hover {
         cursor: pointer;
      }
   }
}
.itemsList {
   width: 100%;
   font-size: 14px;
   margin-bottom: 20px;
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: start;
   padding: 0;
   gap: 12px;
}
.priceProcessor {
   line-height: -20px;
   padding: 0;
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: baseline;
   width: 100%;
   gap: 8px;
}
.removed {
   position: relative;
   top: 12px;
   font-size: 13px;
   color: var(--blue-200);
   width: 100%;
   display: flex;
   justify-content: end;
   text-decoration: line-through;
}
.total {
   align-items: baseline;
   width: 100%;
   display: flex;
   flex-wrap: nowrap;
   justify-content: center;
   > span:first-child {
      font-size: 18px;
      color: (--grey-400);
   }
   > span:nth-child(2) {
      flex-grow: 10;
      border-bottom: 1px dashed var(--grey-200);
   }
   > span:last-child {
      font-weight: 700;
      font-size: 20px;
      color: var(--black-400);
   }
}
.listItem {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   width: 100%;
   > span:first-child {
      max-width: 80%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
   }
   > span:nth-child(2) {
      flex-grow: 10;
      border-bottom: 1px dashed var(--grey-200);
   }
}
.units {
   align-items: baseline;
   width: 100%;
   display: flex;
   flex-wrap: nowrap;
   justify-content: center;
   > span:nth-child(1) {
      font-size: 14px;
      color: var(--grey-400);
   }
   > span:nth-child(2) {
      flex-grow: 10;
      border-bottom: 1px dashed var(--grey-200);
   }
   > span:last-child {
      font-size: 14px;
      font-weight: 500;
      color: var(--black-400);
   }
}
.orderButton {
   transition: 0.3s ease-in;
   width: 100%;
   color: #fff;
   background: #0b0c0e;
   padding: 12px 26px;
   border-radius: 8px;
   font-size: 14px;
   text-align: center;
   font-weight: 500;
   border: 2px solid #000;
   &:hover {
      box-shadow: 10px 5px 5px #aeaeae;
      cursor: pointer;
   }
}
