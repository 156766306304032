.faqHeader {
   > h3 {
      margin: 0;
      margin-bottom: 18px;
      font-family: var(--title-txt);
      font-size: clamp(18px, 10vw - 5rem, 32px);
   }
}
.faqContent {
   box-sizing: border-box;
   display: flex;
   flex-direction: row;
   gap: 3%;
   flex-wrap: nowrap;
   > ul {
      > li {
         > span:first-child {
            &:hover {
               cursor: pointer;
            }
         }
      }
   }
}
@media screen and (max-width: 900px) {
   .faqContent {
      flex-wrap: wrap;
   }
}
