.wrapper {
   height: auto;
   color: #16181d;
}

.title {
   font-size: 32px;
   font-weight: 700;
   letter-spacing: 0.02em;
   margin-top: 28px;
}

.subTitle {
   font-size: 24px;
   font-weight: 700;
   line-height: 32px;
   margin-top: 32px;
}

.svg {
   width: 40px;
   margin-right: 12px;
   svg {
      width: 30px;
   }
}

.subTitle2 {
   font-size: 18px;
   font-weight: 600;
   line-height: 28px;
}

.blockWrapper {
   display: flex;
   margin-top: 24px;
   width: 60%;
}

.text {
   margin-top: 12px;
   color: #434956;
   font-size: 16px;
   font-weight: 400;
   line-height: 22px;
}

@media screen and (max-width: 768px) {
   .svg {
      width: 35px;
   }

   .blockWrapper {
      width: 100%;
   }

   .subTitle {
      font-size: 18px;
      margin-top: 22px;
   }

   .title {
      font-size: 19px;
   }

   .blockWrapper {
      flex-direction: column;
   }
}

// @media screen and (max-width: 375px) {
//    .subTitle {
//       font-size: 13px;
//    }
//    .title {
//       font-size: 19px;
//    }
// }
