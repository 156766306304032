.wrapper {
   margin-top: 66px;
   position: relative;
}

.title {
   display: flex;
   justify-content: space-between;

   a {
      font-weight: 500;
   }
}

.nameBlock {
   font-size: 32px;
   font-weight: 700;
   line-height: 48px;
   letter-spacing: 0.02em;
}

.wrapperArrow {
   display: flex;
   justify-content: center;
   align-items: center;
   background: #fff;
   border-radius: 50%;
   position: absolute;
   height: 40px;
   width: 40px;
   padding: 0 !important;
   top: calc(50% + 9px);
   z-index: 1;
   box-shadow: 0px 8px 32px 0px #0000001a;

   fill: #0b0c0e;
   transition: all 0.3s ease;

   &:hover {
      fill: #f3cb67;
   }
}

.leftPos {
   position: absolute;
   cursor: pointer;
   z-index: 1;
   transform: rotate(180deg);
   left: -19px;
}

.rightPos {
   right: -20px;
   z-index: 1;
   cursor: pointer;
}

.linkForPage {
   display: flex;
   align-items: center;
   gap: 12px;
   fill: #0b0c0e;
   transition: all 0.3s ease;
   margin: 3px 8px 0 0;

   &:hover {
      fill: #f3cb67;
      color: #f3cb67;
   }
}

.itemsWrapper {
   overflow-x: scroll;
   margin-top: 18px;
   scrollbar-width: none;
}

.sliderWrapper {
   padding: 0 16px !important;
   margin: 0 -16px !important;
   min-height: 100%;
   height: 100%;

   @media screen and (max-width: 1280px) {
      padding: 0 16px !important;
      width: 100%;
      max-width: none;

      .swiper {
         height: 100%;
         width: 100%;
      }

      .swiper-wrapper {
         height: 100%;
         align-items: stretch;
      }

      .sliderContainer {
         width: 100%;
         margin: 0 auto;
         margin: 0 -16px;
         width: calc(100% + 32px);
      }

      .swiperSlide {
         width: auto !important;
         flex-shrink: 0;
      }
   }

   .swiper {
      height: 100%;
   }

   .swiper-wrapper {
      height: 100%;
      align-items: stretch;
   }

   >div {
      padding: 16px 0;

      >div {
         display: flex;
         gap: 20px;
      }
   }
}

.swiperSlide {
   height: auto !important;

   >div {
      height: 100%;
   }
}

.mobileSliderContainer {
   margin: 20px -16px 0;
   padding: 0;
   width: calc(100% + 32px);
   box-sizing: border-box;
}

.mobileSliderWrapper {
   padding: 0 16px !important;
   width: 100% !important;

   .swiper-wrapper {
      display: flex;
      align-items: stretch;
   }
}

.mobileSwiperSlide {
   width: 308px !important;
   height: auto !important;
   flex-shrink: 0;

   >div {
      height: 97%;
      width: 100%;
   }
}

/*.mobileSliderWrapper {
   display: flex;
   align-items: center;
   gap: 20px;
   margin-top: 4px !important;
   overflow-x: scroll;
   scroll-snap-type: x mandatory;
   scroll-behavior: smooth;
   box-sizing: border-box;
   -ms-overflow-style: none;
   overflow: -moz-scrollbars-none;
   scrollbar-width: none;
   min-height: 100%;
   height: 100%;

   .swiper {
      height: 100%;
   }

   .swiper-wrapper {
      height: 100%;
      align-items: stretch;
   }

   &:-webkit-scrollbar {
      width: 0;
   }
}*/

@media screen and (max-width: 768px) {
   .wrapper {
      margin-top: 40px;
   }

   .nameBlock {
      font-size: 24px;
   }

   .mobileSliderWrapper {
      padding: 0 !important;
   }

   .mobileSwiperSlide {
      width: 48% !important;
   }
}

@media screen and (max-width: 768px) {
   .nameBlock {
      font-size: 18px;
   }

   .linkForPage {
      font-size: 14px;
   }

   .title {
      margin-top: 20px;
   }

   .container {
      padding: 0 16px;
      width: 100%;
      box-sizing: border-box;
   }
}

@media screen and (max-width: 675px) {
   .leftPos {
      left: 0px;
   }

   .rightPos {
      right: -18px;
   }
}

/*@media screen and (max-width: 600px) {
   .mobileSliderWrapper {
      margin-top: 1px;
      gap: 12px;
   }
}*/
@media screen and (max-width: 600px) {
   .mobileSwiperSlide {
      width: 238px !important;
   }

   .mobileSliderWrapper {
      padding: 0 !important;
   }
}

@media screen and (max-width: 320px) {
   .linkForPage {
      p {
         display: none;
      }
   }

   .mobileSwiperSlide {
      width: 278px !important;
   }
}