.calculator-container {
    background: #EFF1F6;
    border-radius: 8px;
    box-sizing: border-box;
    padding: 24px;
    //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    margin-top: 32px;

    @media (max-width: 768px) {
        padding: 16px;
    }

    .calculator-layout {
        position: absolute;
        /* Позволяет задать блок вне потока документа */
        top: 0;
        /* Выравниваем по верхней границе родителя */
        left: 50%;
        transform: translateX(-50%);
        /* Выравниваем по левой границе родителя */
        width: 100vw;
        /* Занимает всю ширину окна браузера */
        height: 100%;
        /* Занимает всю высоту родителя */
        background: #EFF1F6;
        /* Цвет совпадает с фоном основного контейнера */
        z-index: -1;
        /* Убирает элемент на задний план, чтобы он не перекрывал другие */
    }
}

.calculator-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .firstLightning {
        width: 21.134px;
        height: 29.151px;
        transform: rotate(-11.097deg);
    }

    .secondLightning {
        width: 40.091px;
        height: 55.941px;
        transform: rotate(15deg);
    }

    .calculator-title {
        font-family: "Noto Sans";
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 0.64px;
        margin: 0;
    }

    .lightning-icon {
        width: 24px;
        height: 24px;
    }
}

.calculator-form {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .form-group {
        display: flex;
        flex-direction: column;
        gap: 8px;

        label {
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            color: #333;
        }
    }
}

.form-control {
    height: 40px;
    padding-left: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 14px;
    transition: border-color 0.2s;

    input {
        padding: 0;
        padding-left: 12px;
    }

    &:focus {
        outline: none;
        border-color: #007bff;
    }

    &.readonly {
        background-color: #f5f5f5;
        cursor: not-allowed;
    }

    &.result {
        font-weight: 600;
        color: #333;
    }
}

.formula-note {
    font-size: 12px;
    color: #666;
    margin-top: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

// Стили для больших экранов
@media (min-width: 769px) {
    .calculator-container {
        width: 100%;
    }

    .calculator-form {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px 32px;
    }

    .formula-note {
        grid-column: 1 / -1;
    }
}

@media (max-width: 1140px) {
    .calculator-form {
        grid-template-columns: repeat(1, 1fr);
    }
}