.crums {
   margin-top: 29px;
   margin-bottom: 24px;
   width: 100%;
   display: flex;
   justify-content: start;
   align-items: center;
   flex-wrap: nowrap;
   gap: 12px;
   font-family: var(--title-txt);
   font-size: 14px;
   color: var(--grey-500);
   text-wrap: nowrap;

   >div,
   span {
      display: flex;
      justify-content: start;
      align-items: center;
      flex-wrap: nowrap;
      gap: 5px;
   }

   span,
   a {
      transition: all 0.3s ease;
      border-bottom: 1px solid #fff;

      &:hover {
         border-bottom: 1px solid #000;
         cursor: pointer;
      }
   }

   p {
      margin: 0;
   }
}

@media screen and (max-width: 500px) {
   .crums {
      margin-top: 10px;
      margin-bottom: 15px;
      gap: 6px;
   }
}

// .crumsActive:hover {
//    cursor: pointer;
//    text-decoration: underline;
// }

.crums>span>a>img {
   display: block;
   width: 16px;
   height: 16px;
}

.limited {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1;
   line-clamp: 1;
   -webkit-box-orient: vertical;
}

.light {
   color: #fff;

   span,
   a {
      color: #fff;
      border-bottom: 1px solid transparent;

      &:hover {
         border-bottom: 1px solid #fff;
      }
   }
}