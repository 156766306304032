.wrapper {
   border: 1px solid #e2e4e9;
   border-radius: 12px;
   min-width: 308px;
}

.mainImage {
   padding: 5px 0;
   border-bottom: 1px solid #e2e4e9;
   display: flex;
   justify-content: center;
   align-items: center;
}

.imageItem {
   height: 240px;
}

.description {
   padding: 15px;
}

.name {
   font-family: var(--title-txt);
   font-size: 18px;
   font-weight: 600;
   line-height: 28px;
   height: 58px;
   max-width: 278px;
}

.descriptionValue {
   font-family: var(--content-txt);
   font-size: 14px;
   font-weight: 400;
   line-height: 18px;
   color: var(--grey-400);
   margin-top: 8px;
   span {
      margin-left: 4px;
      font-weight: 600;
      color: var(--black-400);
   }
}

.labels {
   display: flex;
   align-items: center;
   padding: 0 15px 5px 15px;
   font-family: var(--content-txt);
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
   height: 22px;
   max-width: 268px;
   overflow-y: scroll;
   -ms-overflow-style: none;
   overflow: -moz-scrollbars-none;
   &::-webkit-scrollbar {
      width: 0;
      height: 0;
   }
   div {
      margin-right: 8px;
   }
}

.sallary {
   border: 1px solid #0090ff;
   border-radius: 8px;
   color: #0090ff;
   padding: 0 6px;
}

.newItem {
   border: 1px solid #f76b15;
   border-radius: 8px;
   color: #f76b15;
   padding: 0 6px;
}

.hit {
   border: 1px solid #46a758;
   border-radius: 8px;
   color: #46a758;
   padding: 0 6px;
   min-width: 72px;
}

.otherTag {
   border-radius: 8px;
   padding: 0 6px;
}

.discountWrapper {
   display: flex;
   height: 22px;
   margin: 6px 0 2px 0;
}

.pricesWrapper {
   display: flex;
   font-family: var(--content-txt);
   font-size: 15px;
   align-items: start;
   flex-direction: column;
   padding: 0 15px;
   div {
      margin-right: 6px;
   }
}

.total_price {
   font-size: 18px;
   font-weight: 800;
   line-height: 32px;
   color: var(--black-400);
}

.price {
   color: #80899c;
   font-size: 13.13px;
   font-weight: 400;
   line-height: 20px;
   text-decoration: line-through;
}

.salleryPrice {
   font-size: 14px;
   font-weight: 400;
   line-height: 20px;
   color: var(--black-400);
   background: #ecd06c;
   border-radius: 4px;
   padding: 1px 6px;
}

.activeBlock {
   display: flex;
   align-items: center;
   padding: 15px;
}

.labelActive {
   margin-left: 12px;
   height: 44px;
   width: 44px;
   border-radius: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #f8f8f8;
   transition: all 0.3s ease-in-out;
   position: relative;
   span {
      position: absolute;
      font-size: 13px;
      font-weight: 300;
      top: 35px;
      left: 107%;
      color: #000;
      background: #aeaeae;
      box-shadow: 0px 0px 8px 1px #aeaeae;
      border-radius: 4px;
      padding: 4px 8px;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      z-index: 1;
      text-wrap: nowrap;
      z-index: 0;
   }
   &:hover {
      cursor: pointer;
      background: #e2e4e9;
      span {
         opacity: 1;
         z-index: 1;
      }
   }
}

.compareActive {
   background: #ecd06c;
}

// @media screen and (max-width: 1500px) {
//    .labelActive {
//       height: 43px;
//       width: 46px;
//    }
// }

@media screen and (max-width: 1350px) {
   .wrapper {
      min-width: 30%;
   }
}

@media screen and (max-width: 1070px) {
   .wrapper {
      min-width: 32%;
   }
}
@media screen and (max-width: 985px) {
   .wrapper {
      min-width: 48%;
   }
}

@media screen and (max-width: 740px) {
   .total_price {
      font-size: 18px;
   }
}
@media screen and (max-width: 670px) {
   .wrapper {
      min-width: 308px;
   }
   .labelActive {
      width: 44px;
   }
}

// @media screen and (max-width: 600px) {
//    .labelActive {
//       height: 36px;
//       width: 44px;
//    }
// }

@media screen and (max-width: 375px) {
   .labelActive {
      span {
         display: none;
      }
   }
}
