.wrapper {
   width: 100%;
   // margin-top: 29px;
   padding-top: 40px;
}

.wrappeSelect {
   margin-top: 29px;
}

.wrapperItemReview {
   width: 100%;
   margin-bottom: 29px;
}

.title {
   font-size: 23px;
   font-weight: 700;
   line-height: 28px;
   color: #0b0c0e;
   margin: 43px 0 25px 0;
}

.text {
   font-size: 16px;
   font-weight: 400;
   line-height: 22px;
   color: #434956;
   h2 {
      margin: 0 0 24px 0;
      color: #16181d;
      font-weight: 700;
   }

   h3 {
      margin: 24px 0 12px 0;
      color: #16181d;
      font-weight: 600;
   }

   p {
      margin: 0 0 12px 0;
      color: #434956;
      line-height: 22px;
      strong {
         span {
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
         }
      }
   }
   p {
      > a {
         font-weight: 400;
         color: blue !important;
         text-decoration: underline;
         > strong {
            font-weight: 400;
            color: blue !important;
            text-decoration: underline;
         }
         > span {
            font-weight: 400;
            color: blue !important;
            text-decoration: underline;
         }
      }
   }
   li {
      p {
         margin: 0;
      }
   }
}

.description {
   margin-top: 10px;
}

.descriptionValue {
   font-family: var(--content-txt);
   font-size: 20px;
   font-weight: 400;
   line-height: 28px;
   color: var(--grey-400);
   margin-top: 12px;
   span {
      margin-left: 4px;
      font-weight: 600;
      color: var(--black-400);
   }
}

.titleAvatar {
   display: flex;
}

.avatarIcon {
   background: red;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 40px;
   width: 40px;
   color: #fff;
   margin-right: 10px;
}

.nameAndCreated {
   p {
      padding: 0;
      margin: 0;
   }
   span {
      padding: 0;
      margin: 0;
   }
}

.mainContent {
   display: flex;
   gap: 8%;
}

.starWrapperModal {
   height: 27px;
   width: 27px;
   &:hover {
      cursor: pointer;
   }
}

.starWrapper {
   height: 20px;
   width: 20px;
}

.rating {
   margin-top: 12px;
   display: flex;
   align-items: center;
   gap: 5px;
}

.ratingModal {
   display: flex;
   align-items: center;
   margin-top: 6px;
   gap: 10px;
}

.ratingModalS {
   display: flex;
   align-items: center;
   margin-top: 13px;
   gap: 12px;
   margin-bottom: 28px;
}

.descrComment {
   margin-top: 15px;
   p {
      margin: 0;
   }
}

.wrapperModal {
   padding: 24px;
   color: #000;
   border: 1px solid #e2e4e9;
   border-radius: 12px;
   width: fit-content;
   margin-bottom: 24px;
   width: 90%;
}

.modalTitle {
   font-size: 20px;
   font-weight: 700;
   line-height: 28px;
}

.inputField {
   width: calc(100% - 24px);
   border: 1px solid #e2e4e9;
   padding: 14px 12px;
   margin: 8px 0 14px 0;
   border-radius: 8px;
   &::placeholder {
      color: #8c94a6;
   }
}

.textArea {
   height: 78px;
   font-family: sans-serif;
}

.inputWrapper {
   margin-top: 10px;
}

.crossWrapper {
   border-radius: 50%;
   background: red;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 6px;
}

.buttonModal {
   background: #000;
   color: #fff;
   padding: 12px 61px;
   border-radius: 8px;
   width: fit-content;
   margin-top: -7px;
   &:hover {
      cursor: pointer;
   }
}

.reviewWrapper {
   width: 82%;
}

.inputLabel {
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
}

.buttonWrapper {
   width: 34%;
   display: flex;
   padding: 24px;
   color: #000;
   border: 1px solid #e2e4e9;
   border-radius: 12px;
   height: fit-content;
   gap: 12px;
   flex-wrap: wrap;
   p {
      margin: 6px 0 6px -2px;
      color: #596173;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
   }
}

.test {
   display: flex;
   flex-direction: column;
}

.textButton {
   font-size: 54px;
   font-weight: 700;
   margin-right: 16px;
}

.showButtonModal {
   padding: 13px 0;
   width: 100%;
   background: #000;
   color: #fff;
   text-align: center;
   border-radius: 8px;
   margin-top: 11px;
   &:hover {
      cursor: pointer;
   }
}

.charWrapper {
   display: flex;
   align-items: center;
   padding: 13px 0;
   border-bottom: 1px solid #e2e4e9;
   width: 60%;
}

.iconWrapper {
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 15.5px;
   height: 24px;
   width: 24px;
}

.nameChar {
   width: 51%;
   font-size: 18px;
   font-weight: 400;
   line-height: 28px;
   color: #596173;
   display: flex;
}

.rModContent {
   display: flex;
   flex-wrap: nowrap;
}

@media screen and (max-width: 1280px) {
   .buttonWrapper {
      width: 360px;
   }
}
@media screen and (max-width: 768px) {
   .title {
      font-size: 20px;
      margin: 29px 0 30px 0;
   }
   .reviewWrapper {
      width: 100%;
   }
   .buttonWrapper {
      margin-bottom: 24px;
   }
   .mainContent {
      flex-direction: column-reverse;
   }
   .wrapperModal {
      margin-top: 0;
      margin-bottom: 24px;
      width: calc(100% - 48px);
   }
   .charWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      font-size: 15px;
   }
   .nameChar {
      text-wrap: nowrap;
      font-size: 14px;
   }
}

@media screen and (max-width: 600px) {
   .textButton {
      margin-right: 10px;
      letter-spacing: -2px;
   }
   .buttonWrapper {
      padding: 16px;
      width: calc(100% - 32px);
   }
}

@media screen and (max-width: 400px) {
   .rModContent {
      align-items: center;
   }
   .textButton {
      font-size: 44px;
   }
   .starWrapperModal {
      height: 27px;
      width: 27px;
   }

   .buttonWrapper {
      p {
         text-wrap: nowrap;
         font-size: 13px;
      }
   }
}
