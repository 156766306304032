.wrapper {
   width: 40px;
   height: 24px;
   border-radius: 6px;
   background: #c6cad2;
   position: relative;
   transition: all 0.3s ease-in-out;
   padding: 0 2px;
   cursor: pointer;
}

.activeWrapper {
   background: #ecd06c;
}

.checkBox {
   transition: all 0.3s ease;
   border-radius: 4px;
   height: 20px;
   width: 20px;
   background: #fff;
   position: absolute;
   top: 2px;
   left: 2px;
}

.activeBox {
   left: 22px;
}

.switcherWrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 16px;
   height: 30px;
}

.switcherName {
   color: #0b0c0e;
   font-size: 16px;
   font-weight: 500;
   line-height: 22px;
   letter-spacing: -0.3px;
}
