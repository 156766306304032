.wrapper {
   position: relative;
   width: 100%;
   padding-top: 10px;
   margin-right: 57px;
   z-index: 101;
   margin: 10px;
   border-top: 1px solid #e2e4e9;
}

.searchImg {
   position: absolute;
   bottom: 8px;
   left: 14px;
}

.input {
   width: 100%;
   padding: 10px 16px 10px 46px;
   border-radius: 8px;
   border: 2px solid #e2e4e9;
   width: calc(100% - 100px);

   &::placeholder {
      font-family: Arial;
      font-size: 14px;
      line-height: 20px;
   }
}

.select {
   z-index: 1;
   width: calc(100% - 36px);
   margin-bottom: 10px;
}

.cross {
   position: absolute;
   right: 44px;
   bottom: 6px;
   z-index: 1;
   cursor: pointer;
}

.optionsWrapper {
   border: 1px solid #e2e4e9;
   border-top: none;
   border-radius: 10px;
   background: #f8f8f8;
   overflow: hidden;
   max-height: 184px;
   overflow-y: scroll;
   overflow: -moz-scrollbars-none;
   height: 300px;
   &::-webkit-scrollbar {
      width: 0;
      height: 0;
   }
   &:first-child {
      border-top: 1px solid #e2e4e9;
   }
}

.option {
   color: #000;
   display: flex;
   width: 100%;
   align-items: center;
   padding: 0 77px 0 24px;
   height: 36px;
   font-family: var(--title-txt);
   font-size: 14px;
   font-weight: 500;
   line-height: 28px;
   border-bottom: 1px solid #e2e4e9;
   position: relative;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   overflow: hidden;
   &:last-child {
      border-bottom: none;
   }
   &:hover {
      background: #e2e4e9;
   }
}
