.ldsSpinner {
   /* change color here */
   color: #1c4c5b;
}
.ldsSpinner,
.ldsSpinner div,
.ldsSpinner div:after {
   box-sizing: border-box;
}
.ldsSpinner {
   color: currentColor;
   display: inline-block;
   position: relative;
   width: 80px;
   height: 80px;
}
.ldsSpinner div {
   transform-origin: 40px 40px;
   animation: ldsSpinner 1.2s linear infinite;
}
.ldsSpinner div:after {
   content: ' ';
   display: block;
   position: absolute;
   top: 3.2px;
   left: 36.8px;
   width: 6.4px;
   height: 17.6px;
   border-radius: 20%;
   background: currentColor;
}
.ldsSpinner div:nth-child(1) {
   transform: rotate(0deg);
   animation-delay: -1.1s;
}
.ldsSpinner div:nth-child(2) {
   transform: rotate(30deg);
   animation-delay: -1s;
}
.ldsSpinner div:nth-child(3) {
   transform: rotate(60deg);
   animation-delay: -0.9s;
}
.ldsSpinner div:nth-child(4) {
   transform: rotate(90deg);
   animation-delay: -0.8s;
}
.ldsSpinner div:nth-child(5) {
   transform: rotate(120deg);
   animation-delay: -0.7s;
}
.ldsSpinner div:nth-child(6) {
   transform: rotate(150deg);
   animation-delay: -0.6s;
}
.ldsSpinner div:nth-child(7) {
   transform: rotate(180deg);
   animation-delay: -0.5s;
}
.ldsSpinner div:nth-child(8) {
   transform: rotate(210deg);
   animation-delay: -0.4s;
}
.ldsSpinner div:nth-child(9) {
   transform: rotate(240deg);
   animation-delay: -0.3s;
}
.ldsSpinner div:nth-child(10) {
   transform: rotate(270deg);
   animation-delay: -0.2s;
}
.ldsSpinner div:nth-child(11) {
   transform: rotate(300deg);
   animation-delay: -0.1s;
}
.ldsSpinner div:nth-child(12) {
   transform: rotate(330deg);
   animation-delay: 0s;
}
@keyframes ldsSpinner {
   0% {
      opacity: 1;
   }
   100% {
      opacity: 0;
   }
}
h1 {
   font-family: var(--title-txt);
}
.loading {
   width: 100%;
   height: 40dvh;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 400px;
   }
}
