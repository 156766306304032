.advertisement {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    border-radius: 8px;
    border: 1px solid #E2E4E9;
    //box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 20px;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;

    @media (max-width: 1280px) {
        flex-direction: column-reverse;
        padding: 16px;
        margin-bottom: 0px;
    }

    &__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-right: 2rem;
        padding-left: 40px;
        justify-content: space-between;
        gap: 0px;

        @media (max-width: 768px) {
            margin-bottom: 0;
            width: 100%;
            gap: 0px;
        }
    }

    &__title {
        font-size: 32px;
        margin-bottom: 20px;

        @media (max-width: 768px) {
            font-size: 18px;
            margin-bottom: 16px;
        }
    }

    &__subtitle {
        font-size: 18px;
        color: #666;
        width: 55%;
        margin-top: 0;
        margin-bottom: 40px;

        @media (max-width: 768px) {
            font-size: 14px;
            margin-bottom: 25px;
            width: 100%;
        }
    }


    &__form {
        display: flex;
        gap: 1rem;
        margin-bottom: 40px;

        @media (max-width: 1280px) {
            flex-direction: column;
            margin-bottom: 0;
            gap: 16px;
        }
    }

    &__input {
        flex: 1;
        padding: 0.75rem 1rem;
        border: 1px solid #ddd;
        font-size: 1rem;
        border-radius: 8px;
        background: #F7F7F9;

        &:focus {
            outline: none;
            border-color: #666;
        }
    }

    &__submit {
        background: transparent;
        color: black;
        border-radius: 8px;
        border: 2px solid #0B0C0E;
        padding: 20px 30px;
        cursor: pointer;
        font-size: 1rem;
        white-space: nowrap;
        transition: all .3s ease;

        &:hover {
            background-color: #fff;
            border: 2px solid #0b0c0e;
            color: #0b0c0e;
        }

        @media screen and (max-height:1280px) {
            background: #0b0c0e;
            color: #fff;
            padding: 8px 12px;
        }
    }


    &__image {
        flex: 1;
        max-width: 50%;
        display: flex;
        justify-content: end;
        max-height: 95%;

        @media (max-width: 1280px) {
            max-width: 100%;
            width: 100%;
            justify-content: start;
            margin-left: 0px;
            margin-top: 0px;
        }
    }

    img {
        width: 90%;
        height: auto;
        display: block;
        border-radius: 4px;

        @media (max-width: 1280px) {
            height: 25vh;
            width: auto;
            transform: rotateZ(180deg) rotateX(180deg);
        }
    }
}

.crossWrapper {
    border-radius: 50%;
    background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
}