.title {
   font-size: 32px;
   font-weight: 700;
   line-height: 48px;
   letter-spacing: 0.02em;
   color: #0b0c0e;
   span {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      color: #434956;
   }
}

.actionTable {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 20px;
}

.addItem {
   padding: 10px 20px;
   border-radius: 8px;
   background: #f8f8f8;
   color: #0b0c0e;
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   span {
      font-size: 30px;
      font-weight: 100;
      margin: 0 4px 4px 0;
   }
   &:hover {
      background: #cdcdcd;
   }
}

.switcher {
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
   text-align: left;
   p {
      color: #0b0c0e;
      margin: 0px 10px 0px 0px;
   }
   div {
      margin-top: 0;
   }
}

.itemsWrapper {
   display: flex;
   overflow-x: auto;
   justify-content: start;
   -ms-overflow-style: none;
   overflow: -moz-scrollbars-none;
   &::-webkit-scrollbar {
      width: 0;
      height: 0;
   }
   > div {
      p {
         display: none;
      }
   }
   > div:first-child {
      p {
         display: block;
      }
   }
}

.content {
   border: 1px solid #e2e4e9;
   border-radius: 12px;
   margin-top: 15px;
}

.noContent {
   text-align: center;
   font-weight: 400;
   height: 400px;
   padding-top: 30px;
   span {
      font-weight: 700;
      &:hover {
         text-decoration: underline;
         cursor: pointer;
      }
   }
}

@media screen and (max-width: 768px) {
   .title {
      font-size: 28px;
      span {
         font-size: 16px;
      }
   }
}

@media screen and (max-width: 430px) {
   .title {
      font-size: 18px;
      span {
         font-size: 12px;
      }
   }
   .addItem {
      padding: 8px 16px;
      font-size: 12px;
   }
   .switcher {
      margin-top: 10px;
      p {
         font-size: 12px;
      }
   }
}

@media screen and (max-width: 320px) {
   .addItem {
      font-size: 11px;
      padding: 4px 8px;
   }
   .switcher {
      margin-top: 0px;
   }
}
