.content {
   display: flex;
   gap: 30px;
   > div {
      width: 50%;
   }
}

.name {
   font-family: var(--title-txt);
   font-size: 32px;
   font-weight: 700;
   line-height: 48px;
   letter-spacing: 0.02em;
   color: #0b0c0e;
}

.nameMobile {
   font-family: var(--title-txt);
   font-size: 32px;
   font-weight: 700;
   line-height: 48px;
   letter-spacing: 0.02em;
   color: #0b0c0e;
   display: none;
}
.dataCentersAmount {
   color: #0b0c0e;
}
.infoLabel {
   display: flex;
   flex-direction: column;
   gap: 12px;
   padding: 0;
   margin: 28px 0;
   > li {
      display: flex;
      align-items: center;
      gap: 18px;
      align-items: center;
      font-size: 18px;

      > span {
         color: var(--grey-400);
      }
   }
}

.have {
   color: #00ad64;
}
.hvnt {
   color: #d80000;
}

.descr {
   font-size: 16px;
   font-weight: 400;
   line-height: 28px;
   text-align: left;
   color: #596173;
}

.video {
   margin-top: 28px;
   iframe {
      border-radius: 8px;
      width: 100%;
      height: 300px;
   }
}

@media screen and (max-width: 1280px) {
   .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      > div {
         width: 100%;
      }
   }
   .name {
      display: none;
   }
   .nameMobile {
      display: block;
   }
}

@media screen and (max-width: 900px) {
   .video {
      iframe {
         height: 400px;
      }
   }
}

@media screen and (max-width: 768px) {
   .video {
      iframe {
         height: 300px;
      }
   }
}
