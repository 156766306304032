hr {
   opacity: 0.3;
   color: var(--grey-200);
}

.profileOrderingDone {
   display: none;
}

.profileOrderingDone {
   display: none;
}

.orderingDone {
   width: calc(100% - 34px);
   border-radius: 12px;
   background-color: white;
   border: 2px solid var(--grey-200);
   padding: 15px;
}

.profileOrderingDoneActive {
   width: calc(100% + 40px);
   display: flex;
   flex-direction: column;
   gap: 20px;
}

.orderingDoneHeader {
   margin: 0;
   display: flex;
   justify-content: space-between;

   >p {
      margin: 0;
      font-size: 12px;
      font-family: var(--content-txt);

      >span {
         margin-left: 12px;
         color: var(--grey-400);
      }
   }
}

@media screen and (max-width: 500px) {
   .orderingDoneHeader>p {
      flex-shrink: 10;
   }

   .orderingDoneHeader>span {
      margin-left: 5px;
   }
}

.statusOrderingDoneMobile {
   font-size: 12px;
   font-family: var(--content-txt);
   color: #d36f1d;
   border-radius: 4px;
   padding: 4px 8px;
   background-color: #fcf1e9;
   display: none;
}

@media screen and (max-width: 500px) {
   .statusOrderingDoneMobile {
      display: block;
   }
}

.statusOrderingDoneReject {
   font-size: 12px;
   font-family: var(--content-txt);
   color: #d36f1d;
   border-radius: 4px;
   padding: 4px 8px;
   white-space: nowrap;
   background-color: #fcf1e9;
   display: block;
}

.statusOrderingDoneSucces {
   font-size: 12px;
   font-family: var(--content-txt);
   color: #00b654;
   border-radius: 4px;
   padding: 4px 8px;
   white-space: nowrap;
   background-color: #e6f5ea;
   display: block;
}

.orderingDoneItem {
   width: calc(100% + 100px);
   margin-top: 20px;
   margin-bottom: 20px;
   gap: 8px;
   display: flex;
   flex-wrap: nowrap;
   align-items: center;

   >img {
      width: 80px;
      height: 80px;
      margin-right: 10px;
   }

   >button {
      opacity: 0;
      font-weight: 500;
      margin-top: 35px;
      margin-left: 18px;
      padding: 8px 12px;
      border-radius: 6px;
      cursor: pointer;
      background-color: var(--grey-100);
      border: none;
      transition: 0.3s ease-in;
      flex-grow: 10;
   }
}

@media screen and (max-width: 950px) {
   .orderingDoneItem>button {
      margin: 0;
      margin-top: 20px;
   }
}

@media screen and (max-width: 940px) {
   .orderingDoneItem>button {
      margin: 0;
      margin-top: 0px;
      display: none;
   }
}

@media screen and (max-width: 950px) {
   .orderingDoneItem {
      flex-wrap: wrap;
      margin-bottom: 0px;
      width: 400px;
   }
}

@media screen and (max-width: 790px) {
   .orderingDoneItem {
      flex-wrap: wrap;
      width: 100%;
   }
}

@media screen and (max-width: 500px) {
   .orderingDoneItem {
      margin-top: 10px;
   }
}

.orderingDoneItemHeader {
   >p:first-child {
      white-space: nowrap;
      margin: 0;
      font-weight: 600;
      font-size: clamp(16px, 10vw - 5rem, 18px);
   }

   >p:nth-child(2) {
      margin: 0;
      margin-top: 20px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: start;
      align-items: center;
      gap: 12px;
   }
}

@media screen and (max-width: 950px) {
   .orderingDoneItemHeader>p:nth-child(2) {
      margin-top: 10px;
   }
}

.orderingDoneItemPrice {
   white-space: nowrap;
   font-weight: 700;
   font-size: 16px;
}

.orderingDoneItemDiscount {
   font-size: 12px;
   color: var(--blue-200);
   text-decoration: line-through;
   white-space: nowrap;
}

.orderingDoneItemDiscountExtra {
   font-size: 12px;
   font-weight: 500;
   background-color: #f3cb67;
   border-radius: 4px;
   text-align: center;
   padding: 0px 2px;
   white-space: nowrap;
}

.popupVideo {
   display: flex;
   flex-direction: column;
   gap: 20px;
   position: absolute;
   width: fit-content;
   border-radius: 20px;
   max-width: 800px;
   min-height: 200px;
   margin-left: -350px;
   background-color: var(--grey-100);
   z-index: 1;
   border: 2px solid var(--grey-200);
   padding: 30px;

   >span {
      width: 100%;
      text-align: end;

      >img:hover {
         cursor: grab;
      }
   }

   >video {
      width: 100%;
   }
}

.activeButton {
   font-weight: 500;
   border-radius: 6px;
   cursor: pointer;
   background-color: var(--grey-100);
   border: none;
   transition: 0.3s ease-in;

   &:hover {
      transform: scale(1.01);
   }
}

.orderingPendingItem {
   margin-top: 20px;
   margin-bottom: 20px;
   gap: 8px;
   display: flex;
   flex-wrap: nowrap;
   align-items: center;

   >img {
      width: 80px;
      height: 80px;
      margin-right: 10px;
   }
}