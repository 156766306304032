.aboutCompanyMain {
   >h3 {
      margin: 0;
      font-family: var(--title-txt);
      font-weight: 700;
      font-size: clamp(18px, 10vw - 5rem, 32px);
   }

   >div:nth-child(7) {
      margin-top: 80px;
   }
}

.slider {
   width: 100%;
   margin-left: auto;
   overflow: hidden;
   position: relative;
   height: 564px;
   border-radius: 16px;
}

.slideWrapper {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border-radius: 16px;
   transition: all 0.7s ease;
   opacity: 1;
   display: none;
}

.prevSlide {
   left: - 100%;
   opacity: 1;
   display: block;
   border-radius: 8px;
   height: inherit;
   background-position: center;
   background-size: 135%;
   background-repeat: no-repeat;

   >img {
      opacity: 0;
   }
}

.nextSlide {
   left: 100%;
   opacity: 1;
   display: block;
   border-radius: 8px;
   height: inherit;
   background-position: center;
   background-size: 135%;
   background-repeat: no-repeat;

   >img {
      opacity: 0;
   }
}

.activeSlide {
   top: 0;
   left: 0;
   opacity: 1;
   display: block;
   border-radius: 8px;
   height: inherit;
   background-position: center;
   background-size: 135%;
   background-repeat: no-repeat;

   >img {
      opacity: 0;
   }
}

// @media screen and (max-width: 1300px) {
//    .aboutCompanyMain {
//       height: 2420px;
//    }
// }
// @media screen and (max-width: 900px) {
//    .aboutCompanyMain {
//       height: 2320px;
//    }
// }
// @media screen and (max-width: 825px) {
//    .aboutCompanyMain {
//       height: 2420px;
//    }
// }
// @media screen and (max-width: 675px) {
//    .aboutCompanyMain {
//       height: 2720px;
//    }
// }
// @media screen and (max-width: 355px) {
//    .aboutCompanyMain {
//       height: 2920px;
//    }
// }
// @media screen and (max-width: 530px) {
//    .aboutCompanyMain {
//       height: 2520px;
//    }
// }
@media screen and (max-width: 650px) {
   .aboutCompanyMain>h3 {
      font-weight: 600;
   }

   .aboutCompanyMain>div:nth-child(7) {
      margin-top: 20px;
   }

   .aboutCompanyMain>div:nth-child(4) {
      margin-top: 4px;
   }

   .aboutCompanyMain>div:nth-child(5) {
      margin-bottom: 4px;
   }
}

.aboutCompanyMainSliderController {
   position: relative;
   top: 88%;
   display: flex;
   justify-content: end;
   gap: 8px;
   width: 95%;
   height: 49px;

   >div {
      cursor: pointer;
      background-color: #2b2a1f;
      height: 39px;
      width: 39px;
      border-radius: 50px;
      border: 1px solid #4d5250;
      justify-content: center;
      display: flex;

      >img {
         transition: 0.3s ease-in;
         width: 22px;

         &:hover {
            transform: scale(1.1);
         }
      }
   }
}

@media screen and (max-width: 1100px) {
   .slider {
      height: 450px;
   }
}

@media screen and (max-width: 900px) {
   .slider {
      height: 400px;
   }
}

.rightSliderArrow {
   transform: rotate(180deg);
}

.aboutCompanyInfo {
   margin-top: 40px;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   box-sizing: border-box;
   justify-content: space-between;
   width: 100%;
   gap: 20px;
   margin-bottom: 60px;
   margin-top: 80px;

   >div {
      max-width: 617px;
      font-size: clamp(14px, 10vw - 5rem, 18px);

      >img {
         width: 100%;
         min-width: 343px;
         border-radius: 12px;
         height: auto;
         image-orientation: 1;
      }

      >p {
         margin-top: 0px;
         font-weight: 400;
         line-height: 28px;
      }
   }
}

@media screen and (max-width: 850px) {
   .slider {
      height: 350px;
   }
}

@media screen and (max-width: 750px) {
   .aboutCompanyInfo {
      flex-wrap: wrap;
      justify-content: center;
   }

   .aboutCompanyMainSliderController {
      position: relative;
      top: 78%;
   }

   .slider {
      height: 320px;
   }

   .aboutCompanyInfo>div {
      color: var(--black-200);
   }

   .aboutCompanyInfo>div>p {
      line-height: 20px;
   }
}

@media screen and (max-width: 680px) {
   .slider {
      height: 250px;
   }
}

@media screen and (max-width: 550px) {
   .aboutCompanyInfo {
      margin-top: 10px;
   }

   .activeSlide {
      top: 0;
      left: 0;
      opacity: 1;
      display: block;
      border-radius: 8px;
      height: inherit;
      background-position: center;
      background-size: 175%;
      background-repeat: no-repeat;

      >img {
         opacity: 0;
      }
   }

   .slider {
      height: 226px;
   }
}

.aboutCompanyMainSlider {
   padding: 0;
   margin: 0;
   margin-bottom: -30px;
   margin-top: 20px;
   width: 100%;

   >img {
      width: 100%;
      border-radius: 12px;
   }
}

.mapItems {
   position: relative;
   top: -522px;
   left: 12px;

   >a {
      >ul {
         >li {
            padding-right: 2px;
         }
      }

      background-color: white;
      padding-bottom: 12px;
      margin-bottom: 0px;
      width: 319px;
      gap: 10px;
      height: 492px;
   }
}

@media screen and (max-width: 375px) {
   .slider {
      height: 226px;
   }
}

@media screen and (max-width: 900px) {
   .mapItems {
      top: -120px;
      left: 21px;
   }
}

.mapItemsList {
   overflow: scroll;
   scroll-snap-type: x mandatory;
   scrollbar-width: none;
   width: 318px;
   height: 540px;
   position: relative;
   overflow-x: hidden;

   top: -554px;
   left: 12px;
   z-index: 20;

   div:first-child {
      scroll-snap-type: x mandatory;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: start;
      gap: 2px;
      scrollbar-width: none;
      z-index: 20;
   }
}

@media screen and (max-width: 680px) {
   .mapItemsList>div:first-child {
      flex-direction: column;
      width: 318px;
   }

   .mapItemsList {
      position: relative;
      flex-direction: row;
      width: 80%;
      margin: 0px auto;
      margin-top: -140px;
      z-index: 20;
   }
}

@media screen and (max-width: 675px) {
   .mapItemsList>div:first-child {
      width: 322px;
      justify-content: start;
      flex-wrap: nowrap;
      overflow-x: scroll;
      scrollbar-width: none;
      z-index: 1;
      margin-top: 30px;
   }

   .mapItemsList {
      justify-content: start;
      flex-wrap: nowrap;
      overflow-x: scroll;
      scrollbar-width: none;
      width: 340px;
      z-index: 1;
      margin: 0 auto;
      margin-top: 330px;
      height: 563px;
      margin-bottom: 200px;
   }
}

.contactActive {
   border: solid 2px var(--black-400);
   padding-bottom: 12px;
}