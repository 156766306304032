.wrapper {
   width: 22%;
}

.title {
   font-size: 18px;
   font-weight: 600;
   line-height: 28px;
   color: #0b0c0e;
   position: relative;
   p {
      margin: 0;
   }
}

.cross {
   position: absolute;
   right: 10px;
   top: 10px;
   display: none;
}

.sliderWrapper {
   width: 100%;
}

.clearBtn {
   margin-top: 20px;
   color: #fff;
   background: #0b0c0e;
   padding: 12px 26px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 8px;
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
   border: 2px solid #000;
   transition: all 0.3s ease-in-out;

   &:hover {
      box-shadow: 10px 5px 5px #aeaeae;
      cursor: pointer;
      &:active {
         transform: scale(0.95);
         transition: 0.05s ease-out;
         box-shadow: none;
      }
   }
}

.diaposonInputs {
   display: flex;
   justify-content: space-between;
   gap: 16px;
   font-size: 14px;
   font-weight: 400;
   line-height: 20px;
   > div {
      width: 50%;
   }

   input {
      border: 1px solid #e3e4e7;
      border-radius: 8px;
      height: 44px;
      padding: 0 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #0b0c0e;
      appearance: none;
      width: 90%;
   }
}

.powerTitle {
   display: flex;
   justify-content: space-between;
   align-items: center;
   cursor: pointer;
   font-size: 16px;
   font-weight: 500;
   line-height: 22px;
   color: #0b0c0e;
   p {
      margin: 20px 0 0 0;
   }
   img {
      height: 16px;
      transition: all 0.3s ease;
      margin: 20px 5px 0 0;
   }
}

.rotateImg {
   transform: rotate(90deg);
}

.isOpenImg {
   transform: rotate(270deg);
}

.powerInput {
   margin-top: 10px;
}

@media screen and (max-width: 1330px) {
   .wrapper {
      width: 30%;
   }
}
@media screen and (max-width: 1080px) {
   .wrapper {
      display: none;
      min-width: 100%;
   }
   .title {
      text-align: center;
      padding: 10px 0;
      border-bottom: 1px solid #aeaeae;
   }
   .cross {
      display: block;
   }
}
