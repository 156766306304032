.wrapper {
   display: block;
   flex-direction: column;
   position: fixed;
   right: 16px;
   bottom: 10%;
   z-index: 1;
   div,
   a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 52px;
      width: 52px;
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
      &:hover {
         cursor: pointer;
         box-shadow: 3px 3px 10px #e2e4e9;
      }
   }
}

.call {
   background: #46a758;
}

.toTop {
   background: #f8f8f8;
   margin-top: 10px;
}

@media screen and (max-width: 1280px) {
   .wrapper {
      display: none;
   }
}
