.wrapper {
   display: none;
   height: 60px;
   background: #0b0c0e;
   position: fixed;
   bottom: 0;
   width: 100%;
   color: #fff;
   justify-content: space-around;
   align-items: center;
   z-index: 999;
}
.noScroll {
   overflow: hidden;
}
.fon {
   background: rgba(0, 0, 0, 0.5);
   width: 100%;
   height: 100%;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 1;
}

@media screen and (max-width: 1280px) {
   .wrapper {
      display: flex;
   }
}
