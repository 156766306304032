.cartItem,
.cartItemActive {
   padding: 16px;
   box-sizing: border-box;
   border-radius: 12px;
   border: solid 1px var(--grey-200);
   width: 100%;
   flex-wrap: nowrap;
   display: flex;
   justify-content: space-between;
   align-items: baseline;
   gap: 18px;
   > * {
      flex-grow: 1;
   }
}
@media screen and (max-width: 680px) {
   .cartItem,
   .cartItemActive {
      flex-wrap: wrap;
      padding: 10px;
      gap: 4px;
   }
}

.cartItemActive {
   border: solid 1px var(--black-400);
}
.cartItemFst {
   max-width: 120px;
   min-width: 100px;
   padding: 0;
   max-height: 120px;
   cursor: pointer;
   > label {
      width: 120px;
      height: 120px;
      position: relative;
      > input {
         z-index: -1;
         position: relative;
         top: 0px;
         -webkit-appearance: none;
         height: 14px;
         width: 14px;
         border-radius: 2px;
         margin: 0;
         border: 1px solid var(--grey-200);
         outline: none;
         background-color: white;
         transform: scale(1.5);
         margin-right: 18px;
      }
      > input:hover {
         cursor: pointer;
      }
      > input:checked {
         border: none;
         border-radius: 2px;
         color: white;
         background-color: black;
      }
      input:checked:after {
         border-radius: 2px;
         transform: scale(0.7);
         position: absolute;
         display: block;
         top: -1px;
         left: 0.3px;
         content: url(../../../accests/image/Vector_check.svg);
      }
      > img {
         position: relative;
         top: -20px;
         width: 100%;
         cursor: pointer;
         z-index: -2;
      }
   }
}

@media screen and (max-width: 680px) {
   .cartItemFst {
      max-width: 80px;
      min-width: 60px;
   }
}
.cartItemScnd {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: start;
   gap: 30px;
   > span {
      display: flex;
      flex-wrap: nowrap;
      gap: 10px;
      width: 100%;
      > p {
         width: fit-content;
      }
   }
   > h4,
   p {
      margin: 0;
   }
   > h4 {
      font-size: clamp(14px, 10vw - 5rem, 20px);
   }
}

@media screen and (max-width: 680px) {
   .cartItemScnd {
      margin-bottom: 20px;
   }
}
@media screen and (max-width: 480px) {
   .cartItemScnd {
      max-width: 223px;
      margin-bottom: 20px;
   }
}
@media screen and (max-width: 650px) {
   .cartItemScnd > span:nth-child(3) {
      flex-direction: column;
   }
}
@media screen and (max-width: 420px) {
   .cartItemScnd > span:nth-child(3) {
      order: 2;
   }
   .cartItemScnd > h4 {
      order: 1;
   }
}
@media screen and (max-width: 420px) {
   .cartItemScnd > span:nth-child(3) {
      order: 3;
   }
}
.cartItemNew,
.cartItemHit,
.cartItemDiscount {
   padding: 2px 6px;
   border-radius: 4px;
   font-size: clamp(12px, 10vw - 5rem, 14px);
   transition: 0.3s ease-in;
   cursor: pointer;
   &:hover {
      transform: scale(1.1);
   }
}
.cartItemNew {
   color: #f76b15;
   border: solid 1px #f76b15;
}
.cartItemDiscount {
   color: #0090ff;
   border: solid 1px #0090ff;
}
.cartItemHit {
   color: #46a758;
   border: solid 1px #46a758;
}
.cartItemScnd {
   > span:last-child {
      font-size: clamp(12px, 10vw - 5rem, 14px);
      color: var(--grey-400);
      > p {
         > b {
            color: black;
         }
      }
   }
}
.cartItemFrd {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   gap: 60px;
   > h4 {
      display: flex;
      align-items: center;
      justify-content: end;
      margin: 0;
      > span:first-child {
         font-size: clamp(16px, 10vw - 5rem, 24px);
      }
      > .priceSlice {
         color: var(--blue-200);
         font-size: 13px;
         text-decoration: line-through;
         margin: 0 8px;
      }
      > .priceDiscount {
         font-weight: 400;
         background-color: #f3cb67;
         padding: 2px 4px;
         border-radius: 4px;
         font-size: clamp(12px, 10vw - 5rem, 14px);
      }
   }
   > p {
      margin: 0;
      display: flex;
      flex-wrap: nowrap;
      justify-content: end;
      align-items: center;
      gap: 8px;
   }
}

@media screen and (max-width: 680px) {
   .cartItemFrd {
      justify-content: center;
      gap: 15px;
   }
   .cartItemFrd > h4 {
      justify-content: center;
      width: 100%;
   }
   .cartItemFrd > p {
      justify-content: center;
      width: 100%;
   }
}
@media screen and (max-width: 400px) {
   .cartItemFrd > p {
      justify-content: space-between;
      flex-grow: 2;
   }
}
.cartItemButton {
   cursor: pointer;
   padding: 8px;
   width: fit-content;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 12px;
   background-color: var(--grey-100);
   border: solid 1px var(--grey-100);
   transition: 0.3s ease-in;
   &:hover {
      transform: scale(1.1);
      background: #d5d4d4;
      border: 1px solid var(--grey-200);
   }
}

.itemAmount {
   cursor: pointer;
}
.itemAmountInput {
   box-sizing: border-box;
   width: 70px;
   color: var(--black-400);
   font-size: 16px;
   font-weight: 500;
   border: 1px solid var(--black-100);
   border-radius: 8px;
   font-family: var(--content-txt);
   padding: 12px;
   &:focus-visible {
      border-radius: 8px;
      outline: solid 2px #ecd06c;
   }
}
.labelActive {
   margin-left: 12px;
   padding: 13px;
   border-radius: 10px;
   background: #f8f8f8;
   transition: all 0.3s ease-in-out;
   &:hover {
      cursor: pointer;
      background: #d5d4d4;
   }
}

.compareActive {
   background: #ecd06c;
}

@media screen and (max-width: 400px) {
   .cartItemButton :nth-child(3) {
      margin-right: 1%;
   }
}

@media screen and (max-width: 400px) {
   .cartItemButton {
      border-radius: 8px;
      img {
         height: 16px;
      }
   }
   .labelActive {
      padding: 10px 12px;
      img {
         height: 16px;
      }
   }
}
