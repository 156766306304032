.wrapper {
    border: 1px solid #e2e4e9;
    border-radius: 12px;
    transition: all 0.3s ease;
    width: 303px;
    overflow: visible !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* Добавьте это свойство */

    &:hover {
        border: 1px solid #fff;
        box-shadow: 0px 0px 18px 3px rgba(11, 12, 14, 0.0588235294);
    }
}

.mainImage {
    flex-shrink: 0;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 238px;
    overflow: hidden;
}

.linkImg {
    height: 100%;
    width: 100%;

    &:hover {
        cursor: pointer;
    }
}

.imageItem {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.description {
    //flex: 1 0 auto;
    padding: 0px 24px 15px 24px;
    justify-content: flex-start;
    //margin-bottom: auto;

    &:hover {
        cursor: pointer;
    }
}

.name {
    font-family: var(--title-txt);
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    height: 58px;
    // max-width: 278px;
    overflow: hidden;
    padding: 16px 24px 0px;
}

.descriptionValue {
    font-family: var(--content-txt);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: var(--grey-400);
    margin-top: 9px;

    span {
        margin-left: 5px;
        font-weight: 500;
        color: var(--black-400);
    }
}

.labels {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding: 7px 24px 5px 24px;
    font-family: var(--content-txt);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    height: 22px;
    max-width: 268px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    &:hover {
        cursor: pointer;
    }

    div {
        margin-right: 4px;
        white-space: nowrap;
    }
}

.sallary {
    border: 1px solid #0090ff;
    color: #0090ff;
    border-radius: 4px;
    padding: 1px 7px;
}

.newItem {
    border: 1px solid #f76b15;
    color: #f76b15;
    border-radius: 4px;
    padding: 1px 7px;
}

.hit {
    border: 1px solid #46a758;
    color: #46a758;
    min-width: 72px;
    border-radius: 4px;
    padding: 1px 7px;
}

.otherTag {
    border-radius: 4px;
    padding: 1px 7px;
}

.discountWrapper {
    display: flex;
    height: 22px;
    margin: 12px 0 2px 0;
}

.pricesWrapper {
    display: flex;
    font-family: var(--content-txt);
    font-size: 14px;
    font-weight: 400;
    align-items: start;
    flex-direction: column;
    padding: 0 24px;
    //margin-top: 6px;
    margin-top: auto;
    flex-shrink: 0;

    div {
        margin-right: 12px;
    }
}

.total_price {
    font-size: 24px;
    font-weight: 600;
    margin-top: 2px;
    line-height: 32px;
    color: var(--black-400);
}

.price {
    color: #80899c;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-decoration: line-through;
}

.salleryPrice {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: var(--black-400);
    background: #ecd06c;
    border-radius: 4px;
    padding: 1px 6px;
}

.activeBlock {
    display: flex;
    gap: 8px;
    padding: 23px 24px 24px 24px;
    justify-content: space-between;
    flex-wrap: nowrap;

    >div {
        flex: 1;
        min-width: 0;
    }
}

.labelActive {
    margin-left: 12px;
    height: 44px;
    width: 44px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f8f8f8;
    transition: all 0.3s ease-in-out;

    &:hover {
        cursor: pointer;
        background: #e2e4e9;
    }
}

.compareActive {
    background: #ecd06c;
}

@media screen and (max-width: 1420px) {
    .wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 1070px) {
    .wrapper {
        min-width: 32%;
    }
}

@media screen and (max-width: 985px) {
    .wrapper {
        min-width: 48%;
    }
}

@media screen and (max-width: 740px) {
    .total_price {
        font-size: 18px;
    }

    .wrapper {
        &:hover {
            border: 1px solid #e2e4e9;
            box-shadow: 0px 0px 0px 0px #0b0c0e0f;
        }
    }
}

@media screen and (max-width: 670px) {
    .wrapper {
        min-width: 308px;
    }
}

@media screen and (max-width: 600px) {
    .wrapper {
        min-width: 238px;
        border-radius: 9px;
    }

    .mainImage {
        height: 148px;
    }

    .name {
        font-size: 14px;
        line-height: 20px;
        height: 45px;
    }

    .description {
        padding: 0px 15px 9px 15px;
    }

    .hit {
        font-size: 12px;
        min-width: 61px;
    }

    .sallary {
        font-size: 12px;
    }

    .newItem {
        font-size: 12px;
    }

    .otherTag {
        font-size: 12px;
    }

    .descriptionValue {
        font-size: 12px;
        margin-top: 2px;
    }

    .discountWrapper {
        margin: 7px 0 2px 0;
    }

    .labels {
        padding: 2px 15px 1px 15px;
    }

    .salleryPrice {
        height: 16px;
        padding: 1px 5px;
    }

    .total_price {
        font-size: 18px !important;
        margin-top: -7px;
    }

    .pricesWrapper {
        padding: 0 15px;

        div {
            font-size: 12px;
        }
    }

    // .labelActive {
    //    height: 36px;
    //    width: 44px;
    // }

    .activeBlock {
        padding: 5px 12px 11px 12px;
        gap: 6px;

        >div {
            padding: 8px 12px;
            font-size: 12px;
            letter-spacing: -0.3px;
        }
    }
}

@media screen and (max-width: 375px) {
    .activeBlock {
        padding: 5px 8px 11px 8px;
        gap: 4px;

        >div {
            padding: 8px 10px;
            font-size: 11px;
            letter-spacing: -0.4px;
        }
    }
}

@media screen and (max-width: 320px) {
    .wrapper {
        min-width: 278px;
    }
}