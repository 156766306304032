.wrapper {
   background: #000;
}

.container {
   display: flex;
   align-items: center;
   color: #fff;
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
   height: 60px;
}

.catalog {
   position: relative;
   z-index: 3;
   display: flex;
   background: #2d3139;
   cursor: pointer;
   border: none;
   align-items: center;
   height: 60px;
   padding: 0 90px 0 24px;
   margin-right: 12px;
   text-wrap: nowrap;
}

.catalogImg {
   margin-right: 8px;
}

.catalogList {
   position: absolute;
   top: 60px;
   left: 0;
   z-index: 1;
}

.catalogListItemWrapper {
   border: 1px solid #e2e4e9;
   border-top: none;
   border-bottom-left-radius: 10px;
   border-bottom-right-radius: 10px;
   background: #f8f8f8;
   box-shadow: 0px 5px 24px 5px #0b0c0e0f;
   overflow: hidden;
}

.test {
   margin-left: 11px;
   fill: #8c94a6;
   transition: all 0.3s ease;
   transform: rotate(90deg);
}

.test.active {
   transform: rotate(270deg);
}

.menuLink {
   display: flex;
   padding-left: 6px;
}

.menuLinkItem {
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 0 16px;
   font-size: 14px;
   font-weight: 600;
   border-radius: 8px;
   height: 44px;
   cursor: pointer;
   color: #fff;
   transition: all 0.3s ease-in-out;
   position: relative;
   &:hover {
      background: #2d3139;
      cursor: pointer;
   }
}

.menuLinkOptionsWrapper {
   position: absolute;
   top: 60px;
   left: 0;
   width: 100%;
   z-index: 1;
   box-shadow: 0px 0px 10px #878787;
   border-radius: 8px;
}

.menuLinkOption {
   color: #16181d;
   padding: 8px;
   padding: 10px;
   background: #fff;
   font-size: 14px;
   font-weight: 600;
   line-height: 20px;
   position: relative;
   display: block;
   transition: all 0.3s ease;

   &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
   }
   &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top: 2px solid #d5d5d5;
   }
   p {
      margin: 0;
   }
   &:hover {
      cursor: pointer;
      background: #d9d9d9;
   }
}

.closeSelect {
   position: fixed;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   cursor: auto;
}

.linkIcon {
   position: absolute;
   right: 10px;
   top: 10px;
}

.logout {
   margin-left: auto;
   display: flex;
   align-items: center;
   cursor: pointer;
   &:hover {
      text-decoration: underline;
   }
}

.userImg {
   margin-right: 10px;
}

@media screen and (max-width: 1280px) {
   .wrapper {
      display: none;
   }

   .catalogList {
      display: none;
   }
}
