.wrapper {
   display: flex;
   justify-content: space-between;
   margin: 65px auto 10px auto;
   width: 100%;
   overflow: hidden;
   position: relative;
}

.wrapperLogo {
   display: flex;
   justify-content: center;
   box-sizing: border-box;
   border: 2px solid #e2dfdf;
   border-radius: 14px;
   overflow: hidden;
   align-items: center;
   transition: all 0.4s ease-in-out;

   &:hover {
      cursor: pointer;

      border: 2px solid #acaaaa;
   }
   img {
      border-radius: 12px;
      width: 100%;
   }
}

.hideBitImg {
   display: none;
}

.activeBigImg {
   display: block;
}

.sliderWrapper {
   margin-top: 20px;
   position: relative;
}

.mobileSliderWrapper {
   display: flex;
   align-items: center;
   // gap: 20px;
   margin-top: 20px;
   overflow-x: scroll;
   scroll-snap-type: x mandatory;
   scroll-behavior: smooth;
   box-sizing: border-box;
   -ms-overflow-style: none;
   overflow: -moz-scrollbars-none;
   scrollbar-width: none;
   &:-webkit-scrollbar {
      width: 0;
   }
}

.wrapperArrow {
   display: flex;
   justify-content: center;
   align-items: center;
   background: #fff;
   border-radius: 50%;
   position: absolute;
   height: 40px;
   width: 40px;
   top: calc(50% - 21px);
   z-index: 1;
   box-shadow: 0px 8px 32px 0px #0000001a;
   fill: #0b0c0e;
   transition: all 0.3s ease;
   &:hover {
      fill: #f3cb67;
   }
}

.leftPos {
   position: absolute;
   cursor: pointer;
   z-index: 1;
   transform: rotate(180deg);
   left: -19px;
}

.rightPos {
   right: -20px;
   z-index: 1;
   cursor: pointer;
}

.smallSliderItem {
   border: 1px solid #e2e4e9;
   display: flex !important;
   justify-content: center;
   overflow: hidden;
   align-items: center;
   border-radius: 12px;
   padding: 1px;
   cursor: pointer;
   height: 102px;
   filter: grayscale(1);
   opacity: 0.6;
   transition: all 0.4s ease;
   &:hover {
      opacity: 1;
      filter: grayscale(0);
   }
   .slick-active {
      margin: 0 20px !important;
   }
}

.companyWrapper {
   position: relative;
   > div {
      > div {
         > div {
            > div {
               > div {
                  display: flex;
                  justify-content: center;
                  .slick-active {
                     margin-right: 20px;
                  }
               }
            }
         }
      }
   }
}

@media screen and (max-width: 1280px) {
   .wrapper {
      margin-top: 35px;
   }
   .smallSliderItem {
      // min-width: calc((100vw / 3) - 30px);
   }
}

@media screen and (max-width: 1080px) {
   .smallSliderItem {
      img {
         width: 100%;
      }
   }
}

@media screen and (max-width: 768px) {
   .smallSliderItem {
      // max-width: calc(100% / 2 - 20px);
      min-width: 140px;
      // width: calc(100% / 2 - 20px);
      border-radius: 10px;
      height: 52px;
   }

   .mobileSliderWrapper {
      gap: 8px;
   }
}

@media screen and (max-width: 320px) {
   .smallSliderItem {
      max-width: calc(100% / 2 - 20px);
      min-width: calc(100% / 2 - 20px);
      width: calc(100% / 2 - 20px);
      border-radius: 10px;
      height: 50px;
   }
}
