.wrapper {
   color: #fff;
   background: #0b0c0e;
   padding: 10px 23px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 8px;
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
   border: 2px solid #0b0c0e;
   transition: all 0.3s ease-in-out;
   text-wrap: nowrap;

   img {
      margin-right: 8px;
      height: 18px;
   }

   &:hover {
      cursor: pointer;
      border: 2px solid #16181d;
      background: #16181d;

      &:active {
         transform: scale(0.95);
         transition: 0.05s ease-out;
         box-shadow: none;
      }
   }
}

.inverted {
   background-color: white;
   color: #0b0c0e;
   border: 2px solid #0b0c0e;

   &:hover {
      background-color: #0b0c0e;
      color: white;
   }
}

@media screen and (max-width: 600px) {
   .wrapper {
      letter-spacing: -0.6px;
   }
}

@media screen and (max-width: 375px) {
   .wrapper {
      padding: 10px 14px;
      font-size: 11px;
   }
}