.slider {
   width: 100%; /* Ширина контейнера */
   overflow: hidden; /* Прячем всё, что выходит за границы контейнера */
   background-color: #f8f8f8; /* Фон для наглядности */
   position: relative;
}

.sliderTrack {
   display: flex;
   width: calc(150%); /* Учтем общую ширину margin */
   animation: scroll 18s linear infinite; /* Анимация прокрутки увеличена на 36s */
}

.slide {
   flex: 0 0 calc(16.6% - 32px); /* Ширина слайда с учетом отступов */
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 1.5rem; /* Размер текста */
   margin-right: 32px; /* Отступ между слайдами */
}

@keyframes scroll {
   0% {
      transform: translateX(0);
   }
   100% {
      transform: translateX(-50%);
   }
}
