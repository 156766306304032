.wrapper {
   color: #0b0c0e;
   display: flex;
   min-width: 190px;
   align-items: center;
   padding: 0 89px 0 24px;
   height: 56.7px;
   font-family: var(--title-txt);
   font-size: 14px;
   font-weight: 600;
   line-height: 28px;
   border-bottom: 1px solid #e2e4e9;
   position: relative;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   svg {
      position: absolute;
      right: 22px;
      fill: #8c94a6;
   }
   &:last-child {
      border-bottom: none;
   }
   &:hover {
      background: #e2e4e9;
   }
}
