.newsContentItemSmall {
   cursor: pointer;
   padding: 0;
   min-width: 300px;
   max-width: 416px;
   max-height: 472px;
}

.newsContentItemSmall > img {
   width: 100%;
   border-radius: 12px;
}

.newsContentItemSmall > p {
   margin-top: 1px;
   display: flex;
   justify-content: space-between;
}

.newsContentItemSmall > p > span {
   position: relative;
   font-family: var(--content-txt);
   font-size: clamp(12px, 10vw - 5rem, 14px);
}

.newsContentItemSmall > h3 {
   margin-top: 1px;
   font-family: var(--content-txt);
   font-size: clamp(16px, 10vw - 5rem, 18px);
   font-weight: 500;
   line-height: 28px;
   margin-bottom: 11px;
}

.newsContentItemSmall > h4 {
   font-family: var(--content-txt);
   font-size: 18px;
   font-weight: 400;
   color: var(--grey-400);
   margin-top: -2px;
}
