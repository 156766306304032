.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Полупрозрачный фон */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modalCentered {
    background: #fff;
    /* Белый фон для модального окна */
    border-radius: 10px;
    //box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    padding: 32px;
    max-width: 375px;
    width: 90%;
    position: relative;

    @media screen and (max-width: 600px) {
        width: 75%;
        max-width: 75%;
    }
}

.modalContent {
    width: 100%;
}

.modalTitle {
    composes: modalContent;
    font-size: 24px;
    font-family: Inter;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 28px;

    @media screen and (max-width: 600px) {
        font-size: 20px;
        line-height: 24px;
        white-space: nowrap;
    }
}

.modalDescription {
    color: #666;
    font-size: 14px;
    margin-bottom: 20px;
    line-height: 140%;
    font-family: Inter;
    font-weight: 400;
    margin-top: 5px;

    @media screen and (max-width: 600px) {
        font-size: 12px;
        margin-bottom: 16px;
    }
}

.input {
    width: 100%;
    padding: 10px;
    margin-top: 0;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    box-sizing: border-box;
}

.checkboxGroup {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    color: var(--Grayscale-950, #0B0C0E);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    @media screen and (max-width: 600px) {
        font-size: 12px;
    }

    .checkboxLabel {
        display: flex;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;

        &:last-child {
            margin-right: 0;
        }
    }

    input[type="checkbox"] {
        appearance: none;
        /* Убираем стандартный стиль чекбокса */
        width: 20px;
        height: 20px;
        border: 1px solid #E2E4E9;
        border-radius: 7.2px;
        /* Делаем углы закругленными */
        background-color: #fff;
        cursor: pointer;
        display: inline-block;
        position: relative;
        margin-right: 10px;

        @media screen and (max-width: 600px) {
            width: 16px;
            height: 16px;
            margin-right: 4px;
        }
    }

    input[type="checkbox"]:checked {
        background-color: #000;
        /* Цвет фона при выборе */
        border-color: #000;
        /* Цвет границы при выборе */
    }

    input[type="checkbox"]:checked::after {
        content: '';
        position: absolute;
        top: 0px;
        left: 5px;
        width: 6px;
        height: 10px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);

        @media screen and (max-width: 600px) {
            left: 3px;
            width: 5px;
            height: 9px;
        }
    }
}

.submitButton {
    width: 100%;
    padding: 10px;
    background: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.submitButton.disabled {
    background: #ccc;
    cursor: not-allowed;
}

.submitButton:hover {
    box-shadow: 10px 5px 5px #aeaeae;
    cursor: pointer;
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.closeButton:hover {
    color: #333;
}

.privacyPolicy {
    font-size: 12px;
    color: #666;
    margin-top: 10px;
}

.popupWrapper {
    padding: 20px;
    border-radius: 10px;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}