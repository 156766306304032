.wrapper {
   margin-top: 80px;
   padding-bottom: 60px;
}

.title {
   font-family: var(--title-txt);
   font-size: 32px;
   font-weight: 700;
   line-height: 48px;
   letter-spacing: 0.02em;
}
.itemsWrapper {
   display: flex;
   justify-content: space-between;
   gap: 30px;
   margin-top: 20px;
}
.iconSvg {
   width: 25%;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100%;
   margin-right: 12px;
   padding-left: 12px;
}
.sizeIcon {
   height: 48px;
   width: 48px;
}
.item {
   background: #f8f8f8;
   display: flex;
   align-items: center;
   padding: 10px 20px;
   border-radius: 12px;
   color: var(--black-400);
   font-size: 18px;
   font-weight: 500;
   line-height: 28px;
   justify-content: flex-start;
   padding-left: 0;
   text-align: start;
   width: 25%;
}

@media screen and (max-width: 1280px) {
   .itemsWrapper {
      flex-direction: column;
   }
   .iconSvg {
      width: 10%;
   }
   .item {
      width: 100%;
      padding: 10px 0;
   }
}

@media screen and (max-width: 768px) {
   .title {
      font-size: 24px;
   }
}

@media screen and (max-width: 430px) {
   .wrapper {
      padding-bottom: 5px;
   }
}
