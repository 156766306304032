.selectAll {
   cursor: pointer;
   transition: 0.3s ease-in;
   &:hover {
      transform: scale(1.05);
   }
   input {
      border: solid 1px var(--grey-200);
      -webkit-appearance: none;
      height: 14px;
      width: 14px;
      border-radius: 2px;
      margin: 0;
      border: 1px solid var(--grey-200);
      outline: none;
      background-color: white;
      transform: scale(1.5);
      margin-right: 18px;
      cursor: pointer;
      &:checked {
         border: none;
         border-radius: 2px;
         color: white;
         background-color: black;
      }
      &:checked:after {
         border-radius: 2px;
         transform: scale(0.7);
         position: absolute;
         display: block;
         top: -1px;
         left: 0.3px;
         content: url(../../../accests/image/Vector_check.svg);
      }
   }
}

.cartPageController {
   box-sizing: border-box;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-between;
   width: 100%;
   padding: 16px;
   border-radius: 12px;
   border: solid 1px var(--grey-200);
   font-size: clamp(14px, 10vw - 5rem, 16px);
   span {
      cursor: pointer;
      transition: 0.3s ease-in;
      &:hover {
         transform: scale(1.05);
      }
   }
}

@media screen and (max-width: 320px) {
   .cartPageController {
      width: 96%;
      font-size: 12px;
      display: flex;
      align-items: center;
   }
}
