.banner {
    position: relative;
    height: 600px;
    width: 100%;

    .layout {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        /* На всю ширину экрана */
        height: 100%;
        /* Высота родителя */
        //background-image: url('../../../images/banner.png');
        /* Замените на реальный путь к картинке */
        background-size: cover;
        background-position: center;
        z-index: -1;

        .con {
            position: relative;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            z-index: -1;

            .lowerHalf {
                display: none;
            }
        }


        /* Позади основного контента */
        @media (max-width: 1280px) {
            width: 97vw;
            border-radius: 16px;

            //filter: brightness(0.5);
            .con {
                position: relative;
                width: 100%;
                height: 100%;
                filter: brightness(0.5);

                .lowerHalf {
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    height: 50%;
                    filter: brightness(0.5);
                    background: inherit;
                    background-position: center bottom;
                }
            }

        }

    }

    .contentBox {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        @media (max-width: 1280px) {
            box-sizing: border-box;
        }
    }

    .content {
        width: 100vw;
        height: 100%;
        object-fit: cover;
        position: relative;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: 768px) {
            width: 100%;
            height: auto;
            aspect-ratio: 3/4;
            left: 0;
            transform: none;
        }
    }

    .overlay {
        position: absolute;
        bottom: 0;
        color: white;
        width: 100%;
        transform: translateX(-55%);
        //height: 43%;
        background: rgba(37, 40, 49, 0.10);
        border-radius: 0px 16px 0px 0px;

        backdrop-filter: blur(40px);

        @media screen and (max-width: 1280px) {
            display: none;
        }
    }

    .decpr {
        position: absolute;
        bottom: 0;
        color: white;
        width: 40%;
        padding-bottom: 40px;

        @media (max-width: 1280px) {
            width: 90%;
            text-align: left;
            padding: 20px;
        }
    }

    .title {
        font-family: "Noto Sans";
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 48px;
        /* 150% */
        letter-spacing: 0.64px;

        @media (max-width: 1280px) {
            font-size: 30px;
            font-weight: 600;
            line-height: 40px;
            letter-spacing: 0.30;
        }

        @media (max-width: 1000px) {
            font-size: 24px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: 0.1;
        }

    }

    .description {
        font-family: Inter;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px;
        margin-bottom: 30px;
        margin-top: 10px;

        @media (max-width: 1280px) {
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            letter-spacing: 0.2;
        }

        @media (max-width: 1000px) {
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: 0;
        }

        /* 155.556% */
    }

    .button {
        padding: 20px 30px;
        background: transparent;
        color: white;
        border-radius: 8px;
        border: 1px solid #FFF;
        cursor: pointer;
        color: #FFF;

        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;

        &:hover {
            box-shadow: 3px 2px 2px #aeaeae;
            cursor: pointer;
            transition: all 0.3s ease;


        }

        @media (max-width: 1280px) {
            width: 100%;
            margin-top: 10px;
            background: white;
            color: var(--black-400);

            &:hover {
                background: #fff;
                color: var(--black-400);
                transition: all 0.3s ease;
            }

        }
    }

    .modal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;

        .modalContent {
            background: white;
            padding: 32px;
            border-radius: 8px;
            position: relative;
            width: 100%;
            max-width: 400px;

            h3 {
                margin-bottom: 24px;
                font-size: 24px;
            }
        }

        .input {
            width: 100%;
            padding: 12px;
            border: 1px solid #ddd;
            border-radius: 4px;
            margin-bottom: 16px;
        }

        .submitButton {
            width: 100%;
            padding: 12px;
            background: #007bff;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;

            &:hover {
                background: #0056b3;
            }
        }

        .closeButton {
            position: absolute;
            top: 16px;
            right: 16px;
            background: none;
            border: none;
            font-size: 24px;
            cursor: pointer;
            color: #666;

            &:hover {
                color: #333;
            }
        }
    }
}