.callbackMining {
   margin-top: 60px;
   width: 100%;
   background-color: black;
   overflow: hidden;
   position: relative;
   z-index: 1;
}

.callbackMiningContent {
   margin: 0 auto;
   width: 1440px;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;
}

.crossWrapper {
   border-radius: 50%;
   background: red;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 6px;
}

@media screen and (max-width: 1440px) {
   .callbackMiningContent {
      width: 100%;
   }
}

@media screen and (max-width: 950px) {
   .callbackMiningContent {
      flex-direction: column;
      align-items: start;
   }
}

.callbackInfoBaner {
   width: 45%;
   height: 344px;

   >img {
      position: relative;
      top: -150px;
      height: 200%;
   }
}

@media screen and (max-width: 650px) {
   .callbackInfoBaner {
      display: none;
   }
}

.callbackForm {
   align-items: center;
   gap: 10px;
   padding: 0px 15px;
   z-index: 10;
   width: 496px;
   height: 344px;
   display: flex;
   background-color: transparent;
   margin-top: 40px;
   flex-direction: column;
   gap: 16px;

   >div {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      gap: 18px;
      margin-bottom: 28px;
      color: white;

      >h4,
      p {
         margin: 0;
         line-height: 20px;
         font-size: 14px;
         font-weight: 400;

         >a {
            color: white;
            text-decoration: underline;
         }
      }

      p {
         width: 60%;
      }

      >h4 {
         font-weight: 600;
         font-size: 24px;
      }
   }

   >input:focus-visible {
      border-radius: 8px;
      outline: solid 2px #ecd06c;
   }

   >input {
      box-sizing: border-box;
      width: 100%;
      border: none;
      font-size: clamp(11px, 10vw - 5rem, 14px);
      border-radius: 8px;
      padding: 14px 18px;
   }

   >button {
      width: 100%;
      padding: 16px 18px;
      background-color: white;
      transition: 0.3s ease;
      color: #fff;
      color: black;
      font-family: var(--content-txt);
      border-radius: 8px;
      font-size: 14px;
      text-align: center;
      font-weight: 400;
      border: 2px solid #000;

      &:hover {
         box-shadow: 10px 5px 5px #aeaeae;
         cursor: pointer;
      }
   }
}

@media screen and (max-width: 1180px) {
   .callbackForm {
      box-shadow: 15px 55px 85px 55px #00000050;
      width: 100%;
      background-color: #00000050;
      margin-top: 0px;
      padding-top: 40px;
   }
}

@media screen and (max-width: 950px) {
   .callbackForm {
      box-shadow: 55px 15px 15px 55px #00000050;
      box-sizing: border-box;
      padding: 20px 40px;
      width: 100%;
      margin: 0 auto;
      flex-direction: column;
      align-items: start;
      margin-top: -200px;
      background-color: #00000050;
   }
}

@media screen and (max-width: 650px) {
   .callbackForm {
      margin: 0;
      padding: 20px 20px;
   }
}

@media screen and (max-width: 400px) {
   .callbackForm>div>p {
      width: 90%;
   }
}

.callbackInfoBanerPhone {
   display: none;
}

@media screen and (max-width: 650px) {
   .callbackInfoBanerPhone {
      display: block;
      margin-bottom: -3px;
   }
}