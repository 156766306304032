.faqColumn {
   padding: 0;
   display: flex;
   margin: 0;
   width: 49%;
   flex-direction: column;
   justify-content: start;
   align-items: start;
   gap: 10px;

   >li {
      min-height: 60px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      gap: 5px;

      >span:first-child {
         font-weight: 500;
         font-size: clamp(16px, 10vw - 5rem, 18px);
         padding: 13px 0px;
         cursor: pointer;

         >p {
            margin: 0;
         }
      }

      >span:nth-child(2) {
         max-width: 95%;
         padding: 10px 0px;
         font-size: clamp(14px, 10vw - 5rem, 16px);
         color: var(--blue-100);
         display: none;

         >p {
            margin: 0;
            text-indent: 10px;
         }
      }

      >span:nth-child(2).active {
         display: block;
      }

      >span:last-child {
         margin-top: 8px;
         padding: 0;
         box-sizing: border-box;
         width: 100%;
         height: 2px;
         background: linear-gradient(to right, #e5e5e5, #ffffff);
      }
   }
}

@media screen and (max-width: 900px) {
   .faqColumn {
      width: 100%;
      gap: 3px;
   }
}

@media screen and (max-width: 600px) {
   .faqColumn>li>span:first-child {
      padding-top: 18px;
   }

   .faqColumn>li>span:nth-child(2) {
      padding-top: 2px;
      max-width: 100%;
      line-height: 19px;
   }
}