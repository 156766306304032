.stopScroll {
   overflow: hidden;
}

.hiddenPop {
   display: none;
}
.authPopupBackground {
   z-index: 999;
   position: fixed;
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
   background-color: rgba(22, 22, 22, 0.489);
   top: 0;
}
.authPopupMain {
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 15px;
   padding: 32px;
   background-color: white;
   opacity: 1;
   border-radius: 8px;
   box-sizing: border-box;
   max-width: 375px;
   min-width: 343px;
   > p {
      margin: 0;
      font-family: var(--content-txt);
      font-size: 14px;
   }
   .error {
      color: red;
   }
}
.PopupHeader {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
   align-items: center;
   > h1 {
      padding-top: 20px;
      white-space: nowrap;
      font-family: var(--content-txt);
      font-weight: 700;
      font-size: 20px;
      margin: 0;
      grid-column: 6;
   }
   > span {
      margin-top: -20px;
      width: 20px;
      display: flex;
      grid-column: 9;
      border-radius: 8px;
      padding: 5px;
      border: 1px solid white;
      cursor: pointer;
      transition: 0.3s ease-in;
      &:hover {
         background-color: var(--grey-200);
      }
   }
}
.PopupController {
   display: flex;
   flex-wrap: nowrap;
   padding: 4px;
   border-radius: 8px;
   background-color: var(--grey-600);
   justify-content: center;
   white-space: nowrap;
   height: 44px;
   > button {
      width: 100%;
      background-color: var(--grey-600);
      border-radius: 8px;
      padding: 10px 52px;
      font-weight: 400;
      font-family: var(--content-txt);
      font-size: 14px;
      border: none;
      transition: 0.3s ease-in;
      cursor: pointer;
      &:hover {
         background-color: var(--grey-200);
      }
      &.active {
         background-color: white;
      }
   }
}
.PopupPhone,
.PopupMail {
   > label {
      display: flex;
      flex-direction: column;
      justify-content: start;
      gap: 11px;
      margin-bottom: 20px;
      > p {
         font-size: 14px;
         font-weight: 400;
         margin: 0;
      }
      > span {
         display: flex;
         justify-content: start;
         align-content: center;
         > input {
            box-sizing: border-box;
            width: 100%;
            color: var(--black-400);
            font-size: 14px;
            font-weight: 500;
            border: 1px solid var(--black-100);
            border-radius: 8px;
            font-family: var(--content-txt);
            padding: 14px;
         }
         & > input:focus-visible {
            border-radius: 8px;
            outline: solid 2px #ecd06c;
         }
      }
   }
}
.PopupCodeSend {
   transition: 0.3s ease-in;
   width: 100%;
   color: #fff;
   background: #0b0c0e;
   padding: 12px 26px;
   border-radius: 8px;
   font-size: 14px;
   text-align: center;
   font-weight: 500;
   border: 2px solid #000;
   &:hover {
      box-shadow: 10px 5px 5px #aeaeae;
      cursor: pointer;
   }
}
.PopupPendingBtn {
   transition: 0.3s ease-in;
   width: 100%;
   color: black;
   background: var(--grey-100);
   padding: 12px 26px;
   border-radius: 8px;
   font-size: 14px;
   text-align: center;
   font-weight: 500;
   border: 2px solid var(--grey-100);
}

.PopupHeaderSend {
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
   align-items: center;
   > h1 {
      font-family: var(--content-txt);
      font-weight: 600;
      font-size: 18px;
      margin: 0;
      grid-column: 1;
      white-space: nowrap;
   }
   > span {
      width: 20px;
      display: flex;
      grid-column: 9;
      border-radius: 8px;
      padding: 5px;
      border: 1px solid white;
      cursor: pointer;
      transition: 0.3s ease-in;
      &:hover {
         background-color: var(--grey-200);
      }
   }
   > p {
      margin: 0;
      grid-column: 1/9;
      color: var(--grey-500);
      font-weight: 400;
      font-size: 14px;
      white-space: nowrap;
   }
}

.PopupAuthCode {
   margin-top: 14px;
   display: flex;
   flex-direction: column;
   justify-content: start;
   gap: 14px;
   > p:first-child,
   p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      color: var(--black-400);
   }
   > .PopupAuthCodeError {
      color: red;
      margin-bottom: 8px;
   }
   > span {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      > input {
         box-sizing: border-box;
         width: 38px;
         height: 44px;
         color: var(--black-400);
         font-size: 14px;
         font-weight: 500;
         text-align: center;
         border: 1px solid var(--black-100);
         border-radius: 8px;
         font-family: var(--content-txt);
         padding: 14px;
      }
      & > input:focus-visible {
         border-radius: 8px;
         outline: solid 2px #ecd06c;
      }
   }
}
.PopupAuthCodeErrorButtons {
   display: flex;
   flex-direction: column-reverse;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;
   margin-top: 18px;
   gap: 6px;
}
.PopupCodeSendAuth {
   transition: 0.3s ease-in;
   width: 100%;
   color: #fff;
   background: #0b0c0e;
   padding: 12px 26px;
   border-radius: 8px;
   font-size: 14px;
   text-align: center;
   font-weight: 500;
   border: 2px solid #000;
   &:hover {
      box-shadow: 10px 5px 5px #aeaeae;
      cursor: pointer;
   }
}
.PopupCodeBack {
   text-align: center;
   width: 100%;
   font-size: 14px;
   // color: black;
   // display: flex;
   // grid-column: 9;
   border-radius: 8px;
   padding: 12px 16px;
   border: 1px solid white;
   cursor: pointer;
   transition: 0.3s ease-in;
   background-color: var(--grey-100);
   &:hover {
      background-color: var(--grey-200);
   }
}
.register {
   cursor: pointer;
   text-decoration: underline;
   color: var(--grey-400);
}
@media screen and (max-width: 400px) {
   .authPopupMain {
      padding: 28px;
      margin: 0px 15px;
   }
   .PopupController {
      > button {
         padding: 9px;
         padding: 10px 42px;
      }
   }
}
.PopupAuthCodeErrorTimer {
   display: flex;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;
}
