.paymentMethods,
.paymentMethodsError {
   box-sizing: border-box;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: start;
   gap: 10px;
   border-radius: 8px;
   padding: 22px 18px;
   background-color: var(--grey-100);
   flex-grow: 2;
   > h3 {
      font-size: 20px;
      font-family: var(--title-txt);
      margin: 0;
   }
}
.paymentMethodsError {
   border: solid 1px red;
}
.paymentMethodsCheckBlock {
   cursor: pointer;
   box-sizing: border-box;
   height: 60px;
   border-radius: 8px;
   padding: 0px 18px;
   background-color: white;
   width: 295px;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;
   input {
      -webkit-appearance: none;
      height: 14px;
      width: 14px;
      border-radius: 2px;
      margin: 0;
      border: 1px solid var(--grey-200);
      outline: none;
      background-color: white;
      transform: scale(1.5);
   }
   input:checked {
      border: none;
      border-radius: 2px;
      color: white;
      background-color: black;
   }
   input:checked:after {
      border-radius: 2px;
      transform: scale(0.7);
      position: absolute;
      display: block;
      top: -1px;
      left: 0.3px;
      content: url(../../../../../accests/image/Vector_check.svg);
   }
   p {
      font-weight: 500;
      font-size: 18px;
      padding-left: 10px;
      margin: 0;
      flex-grow: 10;
   }
}
.paymentMethodsCheck {
   width: 100%;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: start;
   gap: 30px;
}
.active {
   border: 1px solid black;
   cursor: pointer;
   box-sizing: border-box;
   height: 60px;
   border-radius: 8px;
   padding: 0px 18px;
   background-color: white;
   width: 295px;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;
   input {
      -webkit-appearance: none;
      height: 14px;
      width: 14px;
      border-radius: 2px;
      margin: 0;
      border: 1px solid black;
      outline: none;
      background-color: white;
      transform: scale(1.5);
   }
   input:checked {
      border: none;
      border-radius: 2px;
      color: white;
      background-color: black;
   }
   input:checked:after {
      border-radius: 2px;
      transform: scale(0.7);
      position: absolute;
      display: block;
      top: -1px;
      left: 0.3px;
      content: url(../../../../../accests/image/Vector_check.svg);
   }
   p {
      font-weight: 500;
      font-size: 18px;
      padding-left: 10px;
      margin: 0;
      flex-grow: 10;
   }
}

@media screen and (max-width: 1250px) {
   .paymentMethodsCheck {
      flex-wrap: wrap;
      width: 100%;
      gap: 12px;
   }
   .active,
   .paymentMethodsCheckBlock {
      width: 100%;
      flex-wrap: wrap;
   }
}
