.sidePopup {
   width: fit-content;
   height: 70px;
   padding: 14px;
   font-size: 16px;
   border-radius: 4px;
   border: solid 1px var(--grey-200);
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   gap: 4px;
   justify-content: space-between;
   align-items: center;
   position: fixed;
   top: 10%;
   z-index: 99999;
   right: 0%;
   animation: slider;
   animation-duration: 2s;
   animation-iteration-count: 1;
   background-color: white;
   animation-fill-mode: forwards;
   color: black;
   box-shadow: 11px 11px 11px black;
   > span:nth-child(1) > img {
      max-width: 55px;
      max-height: 55px;
   }
   > span:nth-child(2) {
      font-size: 14px;
      color: green;
   }
   > span:nth-child(3) {
      font-size: 14px;
      color: black;
      > p {
         width: 100%;
         margin: 0;
         line-height: 22px;
      }
      > span:nth-child(4) {
         font-size: 14px;
         color: green;
      }
   }
}
@keyframes slider {
   0% {
      position: fixed;
      top: 10%;
      right: -3%;
   }
   25% {
      position: fixed;
      top: 10%;
      right: 0;
   }
   40% {
      opacity: 1;
   }
   60% {
      opacity: 0.75;
   }
   70% {
      opacity: 0.55;
   }
   80% {
      opacity: 0.2;
   }
   90% {
      opacity: 0.1;
   }
   100% {
      opacity: 0;
      display: none;
   }
}
