.usersDataMain {
   box-sizing: border-box;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: start;
   gap: 20px;
   border-radius: 8px;
   padding: 22px 18px;
   background-color: var(--grey-100);
   flex-grow: 2;
   > h3 {
      margin: 0;
      font-size: 20px;
      font-family: var(--title-txt);
      margin: 0;
   }
}

.inputWrapper,
.inputWrapperError {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: start;
   gap: 7px;
   > p {
      margin: 0;
      color: var(--black-400);
      font-size: 14px;
      font-weight: 500;
   }
   > input {
      box-sizing: border-box;
      width: 100%;
      color: var(--black-400);
      font-size: 14px;
      font-weight: 500;
      border: 1px solid var(--black-100);
      border-radius: 8px;
      font-family: var(--content-txt);
      padding: 14px;
   }
   & > input:focus-visible {
      border-radius: 8px;
      outline: solid 2px #ecd06c;
   }
}
.inputWrapperError {
   > input {
      border: 1px solid red;
   }
   > .inputWrapperWarning {
      display: inline;
      color: red;
      font-size: 11px;
   }
}
.inputWrapper {
   > .inputWrapperWarning {
      display: none;
   }
}
.usersName,
.usersComercialData,
.usersContacts {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: start;
   gap: 30px;
}
@media screen and (max-width: 1140px) {
   .usersName,
   .usersComercialData,
   .usersContacts {
      flex-wrap: wrap;
   }
}
