.wrapper {
   display: flex;
   justify-content: space-between;
   align-items: center;
   height: 64px;
}

.logo {
   margin-right: 35px;
   cursor: pointer;
}

.linksWrapper {
   display: flex;
}

.link,
.linkLaptop {
   display: flex;
   align-items: center;
   cursor: pointer;
   padding: 10px 18px;
   padding: 10px;
   transition: all 0.3s ease-in-out;
   border-radius: 8px;

   &:hover {
      background: #f8f8f8;
      div {
         border: 1px solid #e2e4e9;
      }
   }
}

.linkText {
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
   color: var(--black-400);
   @media (max-width: 1280px) {
      display: none;
   }
}

.linkImg {
   height: 20px;
   &:hover {
      //background: #e2e4e9;
   }
}

.mobileWrapper {
   display: none;
}

.mobilePosBlock {
   padding: 16px;
   justify-content: space-between;
   align-items: center;
   display: flex;
}

.comparePosition {
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: center;
   p {
      margin: 4px 0 0 0;
   }
}

.notificationCount {
   position: absolute;
   top: 1px;
   left: calc(50% + 4px);
   background: #ecd06c;
   height: 14px;
   width: fit-content;
   padding: 0 4px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 4px;
   color: #0b0c0e;
   font-size: 12px;
   font-weight: 400;
   line-height: 18px;
   border: 1px solid #fff;
   transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1280px) {
   .wrapper {
      padding: 0 10px;
   }
   .notificationCount {
      left: 25px;
      top: 5px;
   }
   .linkLaptop {
      padding: 0 20pxgit;
   }
   .linkImg {
      margin: 0;
      padding: 10px;
   }
   .linkLaptop {
      display: none;
   }
   .comparePosition {
      padding: 0 2px;
      height: 44px;
   }
}

@media screen and (max-width: 768px) {
   .wrapper {
      display: none;
   }
   .link {
      margin-left: 5px;
   }

   .mobileWrapper {
      display: block;
   }

   .mobilePosBlock {
      padding-bottom: 0;
   }
   .notificationCount {
      top: 6px;
      left: 27px;
   }
}

@media (max-width: 320px) {
   .link {
      padding: 0;
   }
   .linkImg {
      height: 14px;
   }
   .notificationCount {
      left: 19px;
      top: 3px;
      font-size: 10px;
   }
}
