.title {
   font-family: Noto, sans-serif;
   font-size: 32px;
   font-weight: 700;
   line-height: 48px;
   letter-spacing: 0.02em;
   color: #0b0c0e;
}

.select {
   display: flex;
   justify-content: space-between;
   align-items: center;
   font-size: 14px;
   height: 44px;
   font-weight: 400;
   padding: 0 10px 0 22px;
   line-height: 20px;
   color: #0b0c0e;
   border: 1px solid #e2e4e9;
   border-radius: 8px;
   position: relative;
   width: 208px;
   cursor: pointer;
}

.optionsWrapper {
   position: absolute;
   top: 48px;
   left: 0px;
   display: flex;
   flex-direction: column;
   z-index: 1;
   border: 1px solid #0a090b1f;
   width: 239px;
   border-radius: 8px;
   background: #fff;
}

.option {
   padding: 9px 10px 9px 21px;
   border-bottom: 1px solid #e2e4e9;
   &:last-child {
      border: none;
   }
   &:hover {
      background: #f8f8f8;
      cursor: pointer;
   }
}

.filterImg {
   background: #f8f8f8;
   justify-content: center;
   align-items: center;
   height: 44px;
   width: 44px;
   border-radius: 8px;
   margin-right: 12px;
   display: none;
   &:hover {
      background: #d5d1d1;
   }
}

.closeFilter {
   position: fixed;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   background: none;
   opacity: 0;
}

.selectWrapper {
   display: flex;
}

.filterItemsWrapper {
   display: flex;
}

.itemsWrapper {
   width: 76%;
}

.contentWrapper {
   display: flex;
   margin-top: 20px;
   justify-content: space-between;
}

.itemsHeader {
   display: flex;
   justify-content: space-between;
   align-items: center;
}

.items {
   display: flex;
   justify-content: start;
   flex-wrap: wrap;
   gap: 16px;
   margin-top: 18px;
   > div {
      width: 32%;
   }
}

.resultNone {
   text-align: center;
   padding: 20px;
   font-size: 26px;
   font-weight: 700;
}

.countItems {
   display: flex;
   align-items: center;
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
   color: #0b0c0e;
   padding: 4px 10px;
   border-radius: 8px;
   background: #f8f8f8;
   p {
      margin: 0 0 0 8px;
   }
}

@media screen and (max-width: 1330px) {
   .items {
      gap: 9px;
      > div {
         min-width: 49%;
      }
   }
   .itemsWrapper {
      width: 67%;
   }
}

@media screen and (max-width: 1080px) {
   .filterImg {
      display: flex;
   }
   .items {
      width: 100%;
      > div {
         min-width: 32%;
      }
   }
   .itemsWrapper {
      width: 100%;
   }
}
@media screen and (max-width: 710px) {
   .wrapper {
      overflow: hidden;
   }
   .items {
      flex-direction: column;
      > div {
         min-width: 99%;
      }
   }
}
@media screen and (max-width: 590px) {
   .countItems {
      margin-top: 10px;
   }
   .itemsHeader {
      flex-direction: column-reverse;
      align-items: start;
   }
}

@media screen and (max-width: 590px) {
   .countItems {
      font-size: 12px;
   }
   .select {
      font-size: 12px;
      width: 197px;
   }
}
