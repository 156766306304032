.wrapper {
   display: flex;
   align-items: center;
   margin-right: 32px;
   > img {
      width: 20px;
      height: 20px;
   }
}

.text {
   font-size: 13px;
   font-weight: 500;
   line-height: 18px;
   margin: 1px 0 0 4px;
}

.arrow {
   margin-left: 5px;
}

.rate {
   // margin-right: 32px;
   font-size: 14px;
}

.value {
   font-size: 13px;
   margin-left: 6px;
   font-weight: 600;
}
