.wrapper {
   display: none;
   height: 70px;
   background: #fff;
   position: fixed;
   bottom: 0;
   left: 0;
   width: 100%;
   color: #fff;
   justify-content: center;
   align-items: center;
   z-index: 9999;
}

.buttonWrapper {
   width: 100%;
   height: 100%;
   border: 1px solid #aeaeae;
   background: #fff;
   display: flex;
   justify-content: center;
   align-items: center;
   div {
      width: 300px;
      margin-top: 7px;
   }
}

.content {
   background: #fff;
   position: fixed;
   bottom: 70px;
   left: 0;
   padding: 10px;
   border-top-left-radius: 16px;
   border-top-right-radius: 16px;
   > div {
      display: block;
      width: calc(100vw - 20px);
   }
}

.close {
   position: fixed;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;
   opacity: 0.6;
   background: #000;
}

@media screen and (max-width: 1080px) {
   .wrapper {
      display: flex;
   }
}
