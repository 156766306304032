.profileListSwitcherMain {
   display: flex;
   flex-wrap: nowrap;
   > .profileListSwitcher {
      list-style: none;
      width: 200px;
      border-radius: 12px;
      padding: 5px;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: start;
      gap: 6px;
      font-size: clamp(14px, 10vw - 5rem, 16px);
      color: var(--grey-400);
      background-color: var(--grey-600);
      > li {
         white-space: nowrap;
         padding: 12px 16px;
         border-radius: 10px;
         cursor: pointer;
      }
   }
}
@media screen and (max-width: 750px) {
   .profileListSwitcherMain > .profileListSwitcher {
      flex-direction: row;
      width: calc(100% - 70px);
   }
}
@media screen and (max-width: 600px) {
   .profileListSwitcherMain > .profileListSwitcher {
      flex-direction: row;
      width: fit-content;
      gap: 3px;
   }
   .profileListSwitcherMain > .profileListSwitcher > li {
      padding: 12px 12px;
   }
}

@media screen and (max-width: 600px) {
   .profileListSwitcherMain {
      overflow-x: scroll;
      border-radius: 12px;
      width: 100%;
      scrollbar-width: none;
   }
}
.profileSwitcherActive {
   color: var(--black-400);
   background-color: white;
}
