.wrapper {
   width: 100%;
   font-size: 16px;
   font-weight: 500;
   line-height: 22px;
   color: #0b0c0e;
}

.title {
   display: flex;
   justify-content: space-between;
   align-items: center;
   cursor: pointer;
   p {
      margin: 20px 0 0 0;
   }
   img {
      height: 16px;
      transition: all 0.3s ease;
      margin: 20px 5px 0 0;
   }
}

.rotateImg {
   transform: rotate(90deg);
}

.isOpenImg {
   transform: rotate(270deg);
}

.optionsWrapper {
   font-size: 15px;
   font-weight: 500;
   line-height: 22px;
   letter-spacing: -0.3px;
   color: #0b0c0e;
}

.option {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 8px;
   cursor: pointer;

   > div {
      > .text {
         transition: all 0.3s ease;
         text-decoration: none;
         border-bottom: 1px solid #fff;
      }
   }

   &:hover {
      > div {
         > .text {
            border-bottom: 1px solid #000;
         }
      }
   }
}

.activeNameOption {
   display: flex;
   align-items: center;
}

.checkBox {
   border-radius: 4px;
   border: 1px solid #e2e4e9;
   margin-right: 8px;
   background: #fff;
   transition: all 0.3s ease-in-out;
   height: 24px;
   width: 24px;
}

.activeCheckBox {
   background: #ecd06c;
   border: 1px solid #ecd06c;
}

.count {
   font-size: 14px;
   font-weight: 400;
   line-height: 20px;
   color: #434956;
}
