.callback {
   margin: auto;
   width: 95dvw;
   max-width: 1280px;
   overflow-x: hidden;
   margin-top: 90px;
   min-height: 100px;
   border-radius: 10px;
   background-color: var(--grey-100);
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;
   overflow: initial;
}

.callbackInfo {
   padding: 0px 22px;
}

.callbackInfo > h4 {
   margin: 14px 0px;
   font-family: var(--content-txt);
   font-weight: 600;
   font-size: clamp(18px, 10vw - 5rem, 20px);
}

.callbackInfo > p {
   font-family: var(--content-txt);
   letter-spacing: -0.5px;
   color: var(--grey-300);
   font-size: clamp(14px, 10vw - 5rem, 15px);
}

.callbackInfo > p > a {
   text-decoration: underline;
   color: var(--black-300);
}

.callbackInfo > p > a:visited {
   color: var(--black-300);
}

.callbackForm {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   gap: 10px;
   padding: 0px 15px;
}

.callbackForm > input {
   width: 195px;
   border: none;
   font-size: clamp(11px, 10vw - 5rem, 14px);
   border-radius: 8px;
   padding: 14px 18px;
}

.callbackForm > input:focus-visible {
   border-radius: 8px;
   outline: solid 2px #ecd06c;
}

.callbackForm > button {
   padding: 11.5px 18px;
   background-color: var(--black-400);
   transition: 0.3s ease;
   color: #fff;
   color: var(--grey-100);
   font-family: var(--content-txt);
   border-radius: 8px;
   font-size: 14px;
   text-align: center;
   font-weight: 400;
   border: 2px solid #000;
   &:hover {
      box-shadow: 10px 5px 5px #aeaeae;
      cursor: pointer;
   }
}

.crossWrapper {
   border-radius: 50%;
   background: red;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 6px;
}

@media screen and (max-width: 1000px) {
   .callbackForm {
      margin: 10px 0px;
      flex-direction: column;
   }

   .callbackForm > button {
      width: 100%;
   }
}

@media screen and (max-width: 500px) {
   .callback {
      margin-top: 40px;
      flex-direction: column;
   }

   .callbackForm,
   .callbackForm > input {
      width: 92%;
   }

   .callbackInfo > h4 {
      font-size: 18px;
   }

   .callbackInfo > p {
      font-size: 14px;
   }

   .callbackForm > input {
      font-size: 14px;
   }

   .callbackForm > button {
      width: 102%;
      font-size: 14px;
   }
}
