.wrapper {
   background: #f8f8f8;
   padding: 3px 0;
}

.container {
   display: flex;
   justify-content: space-between;
   align-items: center;
   height: 32px;
}

.newMenuWrapper {
   display: flex;
   align-items: center;
   gap: 26px;
   > div {
      font-size: 14px;
      color: #596173;
      font-weight: 600;
      letter-spacing: -0.2px;
      line-height: 20px;
      &:hover {
         cursor: pointer;
      }
   }
}

@media screen and (max-width: 1280px) {
   .wrapper {
      display: none;
   }
}
