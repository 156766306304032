.cartPageMain {
   display: flex;
   flex-direction: column;
   justify-content: start;
   flex-wrap: nowrap;
   gap: 10px;
   > h3 {
      display: flex;
      align-items: baseline;
      justify-content: start;
      gap: 8px;
      margin: 0;
      flex-wrap: nowrap;
      > span:first-child {
         font-size: clamp(18px, 10vw - 5rem, 32px);
      }
      > span:last-child {
         font-weight: 400;
         font-size: clamp(14px, 10vw - 5rem, 18px);
      }
   }
}
.cartPageContent {
   display: flex;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: stretch;
   gap: 20px;
   margin-top: 22px;
}
@media screen and (max-width: 1300px) {
   .cartPageContent {
      flex-wrap: wrap;
   }
}
.cartPageContentLeftCol {
   display: flex;
   flex-direction: column;
   justify-content: start;
   flex-wrap: nowrap;
   gap: 20px;
   flex-grow: 2;
}
.cartPageContentRightCol {
   display: flex;
   flex-direction: column;
}

@media screen and (max-width: 1300px) {
   .cartPageContentRightCol {
      width: 100%;
   }
}
