.card {
    flex-direction: column;
    width: 100%;
    min-height: 100px;
    overflow: hidden;
    //padding: 24px;
    background: #fff;
    border-radius: 16px;
    border: 1px solid #E2E4E9;
    height: 30vh;

    //box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    .imageBackground {
        width: 100%;
        height: 50%;
        /* Половина высоты карточки */
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .content {
        padding: 16px;
        /* Внутренний отступ для текста */
        flex: 1;
    }

    .icon {
        width: 40px;
        height: 40px;
        margin-bottom: 16px;
        filter: grayscale(100%);

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 12px;
        line-height: 1.3;
        margin-top: 0;

        @media screen and (max-width: 1280px) {
            margin-bottom: 8px;
            line-height: 1.1;
            margin-top: 0;
        }
    }

    .text {
        color: #666;
        font-size: clamp(12px, 2vw, 16px);
        word-wrap: break-word;
        overflow-wrap: break-word;
        line-height: 1.5;
        margin-top: 0;

        p {
            margin-top: 0;
            margin-bottom: 0;
        }

        @media screen and (max-width: 1280px) {
            line-height: 1.2;
            margin-bottom: 16px;
        }
    }
}