.wrapper {
   display: flex;
   position: relative;
}

img {
   pointer-events: none;
}

.sliderWrapper {
   display: flex;
   margin: 20px 0 0 auto;
   width: 74.7%;
   position: relative;
}

.wrapperArrow {
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid #4d5250;
   height: 48px;
   width: 48px;
   border-radius: 50%;
   background: #2b2a1f;

   &:hover {
      cursor: pointer;
      transform: scale(1.1);
      transition: all 0.3s ease;
   }
}

.navigate {
   position: absolute;
   bottom: 20px;
   right: 20px;
   display: flex;
   > div {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #4d5250;
      height: 48px;
      width: 48px;
      border-radius: 50%;

      &:hover {
         cursor: pointer;
         transform: scale(1.1);
         transition: all 0.3s ease;
      }
   }
}

.rightPos {
   position: absolute;
   bottom: 21px;
   right: 20px;
   z-index: 1;
}

.leftPos {
   position: absolute;
   bottom: 21px;
   right: 82px;
   z-index: 1;
}

.arrowImg {
   height: 20px;
}

.rotateImg {
   transform: rotate(180deg);
}

.slider {
   width: 100%;
   position: relative;
   border-radius: 16px;
   overflow: hidden;
   > div {
      &:nth-child(2) {
         height: 326px;
         > div {
            height: 326px;
            > div {
               > div {
                  > a {
                     > img {
                        border-radius: 16px;
                        height: 326px;
                     }
                  }
               }
            }
         }
      }
   }
}

.slick-slide {
   margin: 0;
   padding: 0;
}

.slick-list {
   overflow: hidden;
}

.slick-track {
   display: flex;
   align-items: center;
}

.slick-slide img {
   width: 100%;
   height: auto;
   display: block;
}

.mainBanner {
   border-radius: 16px;
   height: 330px;
}

.slideWrapper {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border-radius: 16px;
   transition: all 0.7s ease;
   opacity: 0;
   display: none;
}

.prevSlide {
   left: -1000px;
   opacity: 1;
   display: block;
}

.nextSlide {
   left: 1000px;
   opacity: 1;
   display: block;
}

.activeSlide {
   top: 0;
   left: 0;
   opacity: 1;
   display: block;
}

.catalogList {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 1;
}

.catalogListItem {
   border: 1px solid #e2e4e9;
   border-top: none;
   border-bottom-left-radius: 10px;
   border-bottom-right-radius: 10px;
   background: #f8f8f8;
   box-shadow: 0px 5px 24px 5px #0b0c0e0f;
   overflow: hidden;
}

@media screen and (max-width: 1440px) {
   .sliderWrapper {
      width: 71.7%;
   }
}

@media screen and (max-width: 1280px) {
   .sliderWrapper {
      width: 100%;
   }
   .catalogList {
      display: none;
   }
   .slider {
      width: 100%;
      height: 340px;
      img {
         width: 100%;
         height: 100%;
      }
   }
   .prevSlide {
      // left: -1170px;
      left: -103%;
   }
   .nextSlide {
      // left: 1170px;
      left: 103%;
   }
}
@media screen and (max-width: 960px) {
   .slider {
      height: 300px;
   }
}
@media screen and (max-width: 768px) {
   .sliderWrapper {
      min-width: 100vw;
   }

   .navigate {
      bottom: 14px;
      right: 16px;
      > div {
         height: 30px;
         width: 30px;
      }
   }
   .navigateItem {
      height: 36px;
      width: 36px;
   }
   .arrowImg {
      height: 16px;
   }
   .slider {
      height: 260px;
      border-radius: 0px;
   }
   .mainBanner {
      border-radius: 0px;
   }
   .wrapper {
      justify-content: center;
   }
}

.navigateBar {
   position: absolute;
   top: 16px;
   left: calc(50% - 68px);
   z-index: 1;
   display: flex;
   align-items: center;
   gap: 4px;
   transition: all 0.3s ease;
   > div {
      transition: all 0.3s ease;
      border: none;
      background: #e2e4e9;
      width: 32px;
      height: 4px;
      border-radius: 4px;
      cursor: pointer;
   }
   .activeBar {
      transition: all 0.3s ease;
      background: #000;
   }
}

.unActiveBar {
   border: none;
   background: #e2e4e9;
   width: 32px;
   height: 4px;
   border-radius: 4px;
   cursor: pointer;
}

.activeBar {
   border: none;
   width: 32px;
   height: 4px;
   border-radius: 4px;
   cursor: pointer;
   background: #000;
}

.dots {
   display: flex;
   justify-content: center;
   gap: 6px;
   width: 100%;
   list-style-type: none;
   padding: 0;
   margin: 0;
}

.dot {
   width: 32px;
   height: 4px;
   border-radius: 2px;
   background: #e2e4e9;
}

.dot.active {
   background: #2d3139;
}

@media screen and (max-width: 1280px) {
   .slider {
      width: 100%;
      position: relative;
      border-radius: 16px;
      overflow: hidden;
      height: auto;
      > div {
         &:nth-child(2) {
            height: auto;
            > div {
               height: auto;
               > div {
                  > div {
                     > a {
                        > img {
                           border-radius: 16px;
                           height: auto;
                        }
                     }
                  }
               }
            }
         }
      }
   }
   .wrapperArrow {
      display: none;
   }
}

@media screen and (max-width: 768px) {
   .slider {
      border-radius: 0px;
      > div {
         &:nth-child(2) {
            > div {
               > div {
                  > div {
                     > a {
                        > img {
                           border-radius: 0px;
                        }
                     }
                  }
               }
            }
         }
      }
   }
   .dots {
      gap: 4px;
      li {
         width: 16px;
         margin: 0 !important;
      }
   }

   .dot {
      width: 16px;
      height: 2px;
   }
}
