.wrapper {
   min-width: 320px;
}

.imgage {
   width: 100%;
   border-bottom: 1px solid #e2e4e9;
   display: flex;
   justify-content: center;
   align-items: center;
   img {
      height: 268px;
      width: 240px;
   }
}

.price {
   font-size: 24px;
   font-weight: 800;
   line-height: 32px;
   color: var(--black-400);
}

.oldPrice {
   color: #80899c;
   font-size: 13.13px;
   font-weight: 400;
   line-height: 20px;
   text-decoration: line-through;
   margin: 0 8px;
}

.salleryPrice {
   font-size: 14px;
   font-weight: 400;
   line-height: 20px;
   color: var(--black-400);
   background: #ecd06c;
   border-radius: 4px;
   padding: 1px 6px;
}

.pricesWrapper {
   display: flex;
   font-family: var(--content-txt);
   align-items: center;
   margin-top: 20px;
   padding: 0 10px;

   div {
      margin-right: 6px;
   }
}

.name {
   font-family: var(--title-txt);
   font-size: 18px;
   font-weight: 600;
   line-height: 28px;
   height: 56px;
   padding: 0 10px;
   margin-top: 16px;
}

.activWrapper {
   padding: 0 10px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 30px 0 15px 0;
   > div {
      &:first-child {
         width: 60%;
      }
   }
}

.cross {
   border-radius: 8px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #f8f8f8;
   height: 50px;
   width: 50px;
   cursor: pointer;
   transition: all 0.3s ease-in-out;
   &:hover {
      background: #bfbfbf;
   }
}

.hashRateWrapper {
   max-height: 820px;
   overflow-y: auto;
}
.hashRateWrapper::-webkit-scrollbar {
   width: 6px; /* Ширина полосы прокрутки */
}

.hashRateWrapper::-webkit-scrollbar-track {
   background: transparent; /* Цвет фона полосы прокрутки */
}

.hashRateWrapper::-webkit-scrollbar-thumb {
   background-color: rgba(255, 255, 255, 0.2); /* Цвет ползунка полосы прокрутки */
   border-radius: 10px; /* Закругленные углы ползунка */
}

/* Стилизация для Firefox */
.hashRateWrapper {
   scrollbar-width: none; /* Узкая полоса прокрутки */
   scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* Цвет ползунка и фона полосы прокрутки */
}

.hashRateWrapper::-webkit-scrollbar-button {
   display: none; /* Скрыть стрелки прокрутки */
}

/* Стилизация для Edge и IE */
@supports (-ms-overflow-style: none) {
   .hashRateWrapper {
      -ms-overflow-style: -ms-autohiding-scrollbar; /* Автоматически скрывающаяся полоса прокрутки */
   }
}

.hashItem {
   display: flex;
   flex-direction: column;
}

.tableItem {
   display: flex;
   flex-direction: column;
   justify-content: end;
   width: 100%;
   border-top: 1px solid #e2e4e9;
   > div {
      padding: 18px 13px;
      height: 58px;
      display: flex;
      flex-direction: column;
      justify-content: end;

      &:nth-child(2n) {
         background-color: #f8f8f8;
      }
      &:last-child {
         border-bottom-left-radius: 12px;
         border-bottom-right-radius: 12px;
      }
   }

   p {
      margin: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: #596173;
   }
   span {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      color: #0b0c0e;
   }
}

@media screen and (max-width: 1500px) {
   .cross {
      height: 42px;
      width: 42px;
   }
}

@media screen and (max-width: 430px) {
   .wrapper {
      min-width: 100%;
   }
   .tableItem {
      > div {
         padding: 8px 13px;
      }
   }
}
