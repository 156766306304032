.wrapper {
   background: #fff;
   border-radius: 12px;
   display: flex;
   width: 100%;
}

.mainImage {
   padding: 5px 30px;
   display: flex;
   justify-content: center;
   align-items: center;
   img {
      max-width: 153px;
   }
   &:hover {
      cursor: pointer;
   }
}

.discountWrapper {
   display: flex;
   height: 22px;
   align-items: center;
   margin-top: 8px;
}

.description {
   padding: 25px;
   &:hover {
      cursor: pointer;
   }
}

.name {
   font-family: var(--title-txt);
   font-size: 18px;
   font-weight: 600;
   line-height: 28px;
   height: 30px;
}

.descriptionValue {
   font-family: var(--content-txt);
   font-size: 14px;
   font-weight: 400;
   line-height: 18px;
   color: #596173;
   display: flex;
   margin-right: 10px;
   span {
      margin-left: 4px;
      font-weight: 500;
      color: var(--black-400);
   }
}

.descrWrapper {
   display: flex;
   align-items: center;
}

.labels {
   display: flex;
   align-items: center;
   padding: 15px 0;
   font-family: var(--content-txt);
   font-size: 14px;
   font-weight: 500;
   line-height: 20px;
   div {
      margin-right: 8px;
      white-space: nowrap;
   }
}

.sallary {
   border: 1px solid #0090ff;
   color: #0090ff;
   border-radius: 4px;
   padding: 0px 8px;
}

.newItem {
   border: 1px solid #f76b15;
   color: #f76b15;
   border-radius: 4px;
   padding: 0px 8px;
}

.hit {
   border: 1px solid #46a758;
   color: #46a758;
   min-width: 72px;
   border-radius: 4px;
   padding: 0px 8px;
}

.otherTag {
   border-radius: 4px;
   padding: 0px 8px;
}

.pricesWrapper {
   display: flex;
   font-family: var(--content-txt);
   align-items: end;
   padding: 0 15px;
   flex-direction: column;
}
.total_price {
   font-size: 24px;
   font-weight: 600;
   margin-top: 4px;
   line-height: 32px;
   color: var(--black-400);
}

.price {
   color: #80899c;
   font-size: 13.13px;
   font-weight: 300;
   line-height: 20px;
   text-decoration: line-through;
   margin-right: 8px;
}

.salleryPrice {
   font-size: 14px;
   font-weight: 600;
   line-height: 20px;
   color: var(--black-400);
   background: #ecd06c;
   border-radius: 4px;
   padding: 1px 6px;
}

.activeBlock {
   display: flex;
   align-items: center;
   padding: 15px;
}

.labelActive {
   margin-left: 12px;
   height: 44px;
   width: 44px;
   border-radius: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #f8f8f8;
   transition: all 0.3s ease-in-out;
   &:hover {
      cursor: pointer;
      background: #e2e4e9;
   }
}

.activeBlockWrapper {
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
}
.contentWrapper {
   display: flex;
   justify-content: space-between;
   width: 100%;
}

.compareActive {
   background: #ecd06c;
}

@media screen and (max-width: 1550px) {
   .descrWrapper {
      flex-direction: column;
      align-items: flex-start;
   }
}

@media screen and (max-width: 1500px) {
   .name {
      line-height: 18px;
   }
   .pricesWrapper {
      padding: 0 10px;
   }
   .activeBlockWrapper {
      width: 50%;
      align-items: self-end;
   }
   .labelActive {
      height: 43px;
      width: 46px;
   }
}

@media screen and (max-width: 1280px) {
   .wrapper {
      margin: 0;
      flex-direction: column;
   }
   .mainImage {
      img {
         max-width: 300px;
      }
      align-self: center;
   }
   .description {
      padding: 10px;
   }
   .activeBlock {
      padding: 10px;
   }
   .activeBlockWrapper {
      justify-content: space-between;
   }
}

@media screen and (max-width: 880px) {
   .name {
      font-size: 16px;
   }
   .activeBlockWrapper {
      width: 100%;
   }
}
@media screen and (max-width: 550px) {
   .activeBlockWrapper {
      width: 100%;
      align-items: start;
      margin-top: 10px;
   }
   .description {
      padding: 0 10px;
   }
   .contentWrapper {
      flex-direction: column;
      padding: 0 10px;
   }
   .pricesWrapper {
      align-items: start;
   }
   .price {
      margin: 0px 8px 0 0;
   }
}
