.modalWrapper {
   position: fixed;
   bottom: 0;
   left: 0;
   width: 100%;
   height: calc(100% - 60px);
   display: flex;
   justify-content: flex-end;
   z-index: 3;
}

.activeModalWrapper {
   position: fixed;
   bottom: 60px;
   left: 0;
   width: 100%;
   height: calc(100% - 60px);
   display: flex;
   justify-content: flex-end;
   z-index: 3;
}

.modalContent {
   // position: absolute;
   margin-top: auto;
   width: 100vw;
   // bottom: 70px;
   height: fit-content;
   border-top-left-radius: 20px;
   border-top-right-radius: 20px;
   background: #f8f8f8;
   z-index: 2;
}

.text {
   font-size: 16px;
   font-weight: 600;
   line-height: 22px;
}

.title {
   font-size: 16px;
   font-weight: 500;
   line-height: 22px;
   text-align: center;
   display: flex;
   flex-direction: column;
   height: 42px;
   position: relative;
   padding-top: 18px;
   p {
      font-size: 18px;
      font-weight: 800;
      line-height: 28px;
   }
}

.closeImg {
   position: absolute;
   top: 20px;
   right: 20px;
   cursor: pointer;
   z-index: 1;
}

.menuLink {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 0 24px;
   max-height: 62px;
   border-top: 1px solid #e2e4e9;
   &:hover {
      background: #d2d2d2;
      cursor: pointer;
   }
}

.contact {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   width: 49%;
   height: 62px;
   &:first-child {
      border-right: 1px solid #e2e4e9;
   }
}

.contactWrapper {
   display: flex;
   justify-content: center;
   border-top: 1px solid #e2e4e9;
   align-items: center;
}

.svgPhone {
   margin-right: 6px;
   margin-top: 4px;
}

.telMenu {
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 0 17px;
   height: 62px;
   border-top: 1px solid #e2e4e9;
   &:hover {
      background: #d2d2d2;
      cursor: pointer;
   }
}

@media screen and (max-width: 320px) {
   .menuLink {
      height: 50px;
   }
}
