.contactsItem {
   box-sizing: border-box;
   width: 308px;
   height: 510px;
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: start;
   gap: 8px;
   border-radius: 12px;
   margin-bottom: 15px;
   background-color: white;
   border: solid 1px var(--grey-200);

   > p {
      padding-left: 16px;
      padding-right: 5px;
      margin: 0;
   }

   > img {
      transition: 0.3s ease-in;
      width: calc(100% - 8px);
      height: 248px;
      border-radius: 12px;
      margin: 2px;

      &:hover {
         box-shadow: 1px 1px 30px #e2e4e9;
      }
   }

   > h4 {
      padding: 0 16px;
      margin: 0;
      max-width: calc(100% - 30px);
      font-family: var(--content-txt);
      font-weight: 600;
      font-size: 20px;
   }
}

@media screen and (max-width: 680px) {
   .contactsItem {
      padding-bottom: 12px;
      margin-bottom: 0px;
      width: 319px;
      gap: 10px;
      height: fit-content;
   }

   .contactsItem > img {
      width: calc(319px - 6px);
   }
}

.contactActive {
   border: solid 2px var(--black-400);
   padding-bottom: 12px;
   box-sizing: border-box;
   width: 308px;
   height: 510px;
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: start;
   gap: 8px;
   border-radius: 12px;
   margin-bottom: 15px;
   background-color: white;

   > p {
      padding-left: 16px;
      padding-right: 5px;
      margin: 0;
   }

   > img {
      transition: 0.3s ease-in;
      width: calc(100% - 4px);
      height: 248px;
      border-radius: 12px;
      margin: 2px;

      &:hover {
         box-shadow: 1px 1px 30px #e2e4e9;
      }
   }

   > h4 {
      padding: 0 16px;
      margin: 0;
      max-width: calc(100% - 30px);
      font-family: var(--content-txt);
      font-weight: 600;
      font-size: 20px;
   }
}

@media screen and (max-width: 680px) {
   .contactActive {
      padding-bottom: 12px;
      margin-bottom: 0px;
      width: 319px;
      gap: 10px;
      height: fit-content;
   }

   .contactActive > img {
      width: calc(319px - 8px);
   }
}

.btnCom {
   display: inline-block;
   font-family: var(--content-txt);
   transition: 0.3s ease;
   color: var(--black-400);
   width: 77%;
   background: var(--grey-100);
   margin: 0 auto;
   padding: 12px 26px;
   border-radius: 8px;
   font-size: 14px;
   text-align: center;
   font-weight: 500;
   border: none;

   &:hover {
      box-shadow: 10px 5px 5px #aeaeae;
      cursor: pointer;
   }
}

.listParent {
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: start;
   margin: 0;
   gap: 14px;
   padding: 11px;
}

@media screen and (max-width: 680px) {
   .listParent {
      margin-top: 2px;
      gap: 16px;
   }

   .list {
      padding-right: 4px;
   }
}

.list {
   display: flex;
   justify-content: start;
   align-items: center;
   flex-wrap: nowrap;
   padding: 0 7px;
   margin-top: -4px;
   font-size: 14px;
   font-weight: 500;
   font-family: var(--content-txt);
   line-height: 20px;
   gap: 15px;

   &:last-child {
      flex-wrap: nowrap;
      gap: 12px;
   }

   > p,
   a {
      text-decoration: none;
      margin: 0px;
   }

   > img {
      transition: 0.3s ease-in;
      border-radius: 6px;

      &:hover {
         transform: translate3D(2px, 2px, 1px);
      }
   }
}
@media screen and (max-width: 320px) {
   .contactActive {
      width: 265px;
      > img {
         width: 98%;
      }
   }

   .list {
      p {
         font-size: 12px;
      }
   }
}
