.logout {
   margin-left: auto;
   display: flex;
   align-items: center;
   cursor: pointer;
   position: relative;
   justify-content: center;
   padding: 0 17px;
   border-radius: 8px;
   height: 44px;
   transition: all 0.3s ease-in-out;

   &:hover {
      background: #2d3139;
      cursor: pointer;
   }
}

.logoutMobile {
   display: none;
}

.userImg {
   margin-right: 10px;
}

@media screen and (max-width: 1340px) {
   .menuLinkItem {
      font-size: 11px;
   }
}

@media screen and (max-width: 1280px) {
   .wrapper {
      display: none;
   }

   .catalogList {
      display: none;
   }

   .logoutMobile {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      justify-content: center;
      border-radius: 8px;
      transition: all 0.3s ease-in-out;
      margin-left: 20px;
      height: 44px;
      width: 44px;
      background-color: #f8f8f8;
      > span {
         display: flex;
         align-items: center;
      }

      .userImg {
         display: none;
      }
   }
}

// .logout {
//    margin-left: 20px;

//    .userImg {
//       display: none;
//    }
//    &:hover {
//       background-color: #e2e4e9;
//    }
// }

@media screen and (max-width: 768px) {
   .logoutMobile {
      margin-left: 6px;
   }
}

@media screen and (max-width: 320px) {
   .logoutMobile {
      height: auto;
      padding: 0 10px;
      transition: all 0.3s ease-in-out;
      border-radius: 8px;

      > span {
         > img {
            height: 14px;
         }
      }
   }
}

.logoutBtn {
   display: block;
   margin-left: 14px;
   cursor: pointer;

   &:hover {
      text-decoration: underline;
   }
}

.profileDropdown {
   width: 400px;
   background-color: var(--grey-100);
   padding: 0;
   border-radius: 8px;
   position: absolute;
   margin-top: 200px;
   right: 0;
   top: -160px;
   z-index: 99;
   overflow: hidden;
   box-shadow: 0px 10px 20px 1px var(--grey-400);

   ul {
      margin: 0;
      padding: 0;
      border-radius: 8px;

      li {
         border-bottom: 1px solid var(--grey-200);
         display: flex;
         justify-content: start;
         align-items: center;
         padding: 13px 24px;
         color: black;
         font-weight: 600;
         font-size: 16px;

         > img {
            border-radius: 50%;
         }

         &:is(.authProfile):hover {
            cursor: default;
            background-color: var(--grey-100);
         }

         &:hover {
            background-color: var(--grey-200);
         }

         &:first-child {
            gap: 20px;
         }

         &:not(:first-child) {
            border-bottom: 1px solid var(--grey-200);
         }

         &:last-child {
            border-bottom: none;
         }
      }
   }
}

@media screen and (max-width: 428px) {
   .profileDropdown {
      width: 90dvw;
      > ul {
         > li {
            font-size: 14px;
         }
      }
   }
}
