.wrapper {
   height: auto;
}

.title {
   font-size: 24px;
   font-weight: 700;
   margin-top: 34px;
}

.subTitle {
   margin-top: 14px;
   font-weight: 500;
   font-size: 17px;
}

@media screen and (max-width: 375px) {
   .subTitle {
      font-size: 13px;
   }
   .title {
      font-size: 19px;
   }
}
