.contactMain {
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: start;
   gap: 2px;
   height: calc(100% - 500px);

   >h3 {
      margin: 0;
      font-family: var(--title-txt);
      font-weight: 700;
      font-size: clamp(18px, 10vw - 5rem, 32px);
   }
}

@media screen and (max-width: 650px) {
   .contactMain>h3 {
      font-weight: 600;
   }
}

.contactsMap {
   width: 100%;
   height: 516px;
}

.ourContacts {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: start;
   align-items: baseline;
   overflow-x: hidden;
   gap: 16px;
}

@media screen and (max-width: 1350px) {
   .ourContacts {
      justify-content: center;
   }
}

@media screen and (max-width: 680px) {
   .ourContacts {
      width: 100%;
      justify-content: start;
      flex-wrap: nowrap;
      overflow-x: scroll;
      scrollbar-width: none;
      z-index: 1;
      margin-top: -130px;
   }

   .contactMain {
      margin-bottom: -200px;
   }
}

.mapItemsList {
   overflow: scroll;
   scroll-snap-type: x mandatory;
   scrollbar-width: none;
   width: 318px;
   height: 516px;
   position: relative;
   overflow: hidden;
   left: 12px;
   z-index: 0;

   div:first-child {
      scroll-snap-type: x mandatory;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: start;
      gap: 2px;
      scrollbar-width: none;
      z-index: 20;
   }
}

@media screen and (max-width: 680px) {
   .mapItemsList>div:first-child {
      flex-direction: column;
      width: 326px;
   }

   .mapItemsList {
      position: relative;
      flex-direction: row;
      width: 80%;
      margin: 0px auto;
      margin-top: -140px;
      z-index: 20;
   }
}

@media screen and (max-width: 680px) {
   .mapItemsList {
      overflow-x: scroll;
      scrollbar-width: none;
      width: 100%;
      z-index: 1;
      margin: 0 auto;
      margin-top: 330px;
      height: 593px;
      margin-bottom: 200px;

      div:first-child {
         width: 100dvw;
         display: flex;
         justify-content: center;
         flex-wrap: nowrap;
         overflow-x: scroll;
         scrollbar-width: none;
         z-index: 1;
         margin-top: 30px;
      }
   }
}