.formDataNone {
   display: none;
}
.formData {
   display: flex;
   flex-direction: column;
   > div {
      > label {
         > p {
            margin: 0;
            margin-bottom: 12px;
            font-size: 14px;
         }
         > span {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: start;
            > img {
               position: relative;
               top: -1px;
               z-index: -1;
               left: 10px;
            }
            > input {
               font-family: var(--content-txt);
               font-size: 14px;
               position: relative;
               z-index: 0;
               left: 2px;
               border: 1px solid var(--grey-200);
               background-color: white;
               padding: 12px 18px;
               border-radius: 8px;
               padding-left: 20px;
               margin-right: -10px;
               width: 120px;
               &:focus-visible {
                  border-radius: 8px;
                  outline: solid 2px #ecd06c;
               }
            }
         }
      }
   }
}
@media screen and (max-width: 750px) {
   .formData > div > label > snap > input {
      margin-right: -25px;
   }
}
.formNames,
.formContacts {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-between;
   gap: 5%;
}
.formData {
   > .formNames {
      justify-content: start;
      gap: 6%;
   }
}

@media screen and (max-width: 950px) {
   .formData > .formNames {
      flex-direction: column;
      gap: 10px;
   }
   .formData > .formNames > label > span > input {
      width: 100%;
   }
}
@media screen and (max-width: 450px) {
   .formData > .formNames > label > span > input {
      width: 81%;
   }
}

div.formContacts {
   width: 100%;
   margin-top: 20px;
   flex-wrap: wrap;
   gap: 15px 1px;
   > label {
      width: 96%;
      > span {
         width: 100%;
         > input {
            max-width: 100%;
            width: 100%;
         }
      }
   }
}
.formButtons {
   display: flex;
   width: 96%;
   justify-content: end;
   margin-top: 25px;
   gap: 30px;
}
@media screen and (max-width: 450px) {
   .formButtons {
      width: 100%;
      justify-content: space-around;
      gap: 10px;
   }
}

.formButtonCancel,
.buttonDone {
   padding: 12px 16px;
   border-radius: 8px;
   background-color: transparent;
   border: 1px solid transparent;
   font-size: 14px;
   font-weight: 500;
   font-family: var(--content-txt);
   transition: 0.3s ease-in;
   &:hover {
      cursor: pointer;
      border: 1px solid var(--grey-200);
   }
}
.formButtonApply {
   padding: 12px 16px;
   border-radius: 8px;
   background-color: var(--black-400);
   border: none;
   font-size: 14px;
   font-weight: 500;
   color: white;
   font-family: var(--content-txt);
   transition: 0.3s ease-in;
   white-space: nowrap;
   &:hover {
      box-shadow: 10px 5px 5px #aeaeae;
      cursor: pointer;
   }
}
@media screen and (max-width: 450px) {
   .formButtonApply {
      width: 100%;
   }
}
.pencil {
   display: none;
   &:hover {
      cursor: pointer;
      border: 1px solid var(--grey-200);
   }
}

@media screen and (max-width: 750px) {
   .pencil {
      display: block;
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid transparent;
      transition: 0.3s ease-in;
   }
}
@media screen and (max-width: 750px) {
   .buttonDone {
      display: none;
   }
}
