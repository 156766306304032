.wrapper {
   font-size: 13px;
   font-weight: 400;
   line-height: 18px;
   text-align: left;
   display: flex;
   align-items: center;
}

.language {
   display: flex;
   align-items: center;
   margin-right: 30px;
}

.langImg {
   margin-right: 10px;
   border-radius: 3px;
}

.phone {
   display: flex;
   align-items: center;
   color: #16181d;
   font-size: 16px;
   font-weight: 700;
   letter-spacing: 0.45px;
}

.phoneImg {
   margin-right: 5px;
}

@media screen and (max-width: 1310px) {
   .wrapper {
      font-size: 12px;
   }
}
