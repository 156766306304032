.title {
   font-size: 32px;
   font-weight: 700;
   line-height: 48px;
   letter-spacing: 0.02em;
   color: #0b0c0e;
}

.noContent {
   text-align: center;
   font-weight: 400;
   height: 400px;
   padding-top: 30px;
   width: 100%;
   span {
      font-weight: 700;
      &:hover {
         text-decoration: underline;
         cursor: pointer;
      }
   }
}

.wrapperItem {
   display: flex;
   justify-content: start;
   gap: 12px;
   flex-wrap: wrap;
   > div {
      max-width: 24%;
      min-width: 24%;
   }
}

@media screen and (max-width: 1280px) {
   .wrapperItem {
      > div {
         max-width: 32%;
         min-width: 32%;
      }
   }
}

@media screen and (max-width: 1050px) {
   .wrapperItem {
      > div {
         max-width: 48%;
         min-width: 48%;
      }
   }
}

@media screen and (max-width: 700px) {
   .wrapperItem {
      > div {
         max-width: 99%;
         min-width: 99%;
      }
   }
}
