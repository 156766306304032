.wrapper {
   border: 1px solid #e2e4e9;
   border-radius: 12px;
   background: #f4f5f7;
   display: flex;
   align-items: center;
   padding: 0 10px;
   margin-top: 60px;
   max-width: 1260px;
}

.discountTime {
   color: var(--black-400);
   padding: 0 30px;
   h4 {
      font-size: 32px;
      font-weight: 700;
      line-height: 48px;
      letter-spacing: 0.02em;
      margin-bottom: 0;
      text-align: center;
      @media (max-width: 1280px) {
         margin: 0;
         font-size: 24px;
      }
   }
   p {
      text-align: center;
      border-radius: 8px;
      background: #ecd06c;
      padding: 4px;
      font-size: 18px;
      font-weight: 200;
      line-height: 28px;
      margin: 16px auto 30px auto;
      width: 133px;
      @media (max-width: 1280px) {
         margin: 0;
      }
   }
}

@media screen and (max-width: 1550px) {
   .wrapper {
      padding: 10px;
   }
}

@media screen and (max-width: 1280px) {
   .wrapper {
      flex-direction: column;
   }
   .discountTime {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding: 0 10px 10px 10px;
   }
}
