.formDelivery,
.formDeliveryError {
   flex-grow: 2;
   box-sizing: border-box;
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: start;
   gap: 24px;
   border-radius: 8px;
   padding: 22px 18px;
   background-color: var(--grey-100);
}
.formDeliveryError {
   border: 1px solid red;
}
.formDeliveryHeader {
   box-sizing: border-box;
   padding-right: 17px;
   width: 100%;
   display: flex;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;
   gap: 20px;
   h3 {
      font-weight: 700;
      flex-grow: 10;
      margin: 0;
      flex-grow: 10;
      font-size: 20px;
      font-family: var(--title-txt);
   }
   input {
      -webkit-appearance: none;
      height: 14px;
      width: 14px;
      border-radius: 2px;
      margin: 0;
      border: 1px solid var(--grey-200);
      outline: none;
      background-color: white;
      transform: scale(1.5);
   }
   input:hover {
      cursor: pointer;
   }
   input:checked {
      border: none;
      border-radius: 2px;
      color: white;
      background-color: black;
   }
   input:checked:after {
      border-radius: 2px;
      transform: scale(0.7);
      position: absolute;
      display: block;
      top: -1px;
      left: 0.3px;
      content: url(../../../../accests/image/Vector_check.svg);
   }
}
.formDeliveryAdress,
.formDeliveryAdressError {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: start;
   gap: 7px;
   > p {
      margin: 0;
      color: var(--black-400);
      font-size: 14px;
      font-weight: 500;
   }
   > input {
      box-sizing: border-box;
      width: 100%;
      color: var(--black-400);
      font-size: 14px;
      font-weight: 500;
      border: 1px solid var(--black-100);
      border-radius: 8px;
      font-family: var(--content-txt);
      padding: 14px;
   }
   & > input:focus-visible {
      border-radius: 8px;
      outline: solid 2px #ecd06c;
   }
}
.formDeliveryAdressError {
   border: solid 1px red;
}
.deliveryMethodsCheck {
   width: 100%;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: start;
   gap: 30px;
   > label {
      cursor: pointer;
      box-sizing: border-box;
      height: 60px;
      border-radius: 8px;
      padding: 0px 18px;
      background-color: white;
      width: 295px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      > input {
         -webkit-appearance: none;
         height: 14px;
         width: 14px;
         border-radius: 2px;
         margin: 0;
         border: 1px solid var(--black-100);
         outline: none;
         background-color: white;
         transform: scale(1.5);
      }
      > input:checked {
         border: none;
         border-radius: 2px;
         color: white;
         background-color: black;
      }
      input:checked:after {
         border-radius: 2px;
         transform: scale(0.7);
         position: absolute;
         display: block;
         top: -1px;
         left: 0.3px;
         content: url(../../../../accests/image/Vector_check.svg);
      }
      > p {
         font-weight: 500;
         font-size: 18px;
         padding-left: 10px;
         margin: 0;
         flex-grow: 10;
      }
   }
   .active {
      border: 1px solid black;
   }
}

.deliveryMethodsCheckSelects {
   cursor: pointer;
   box-sizing: border-box;
   height: 60px;
   border-radius: 8px;
   padding: 0px 18px;
   background-color: white;
   width: 295px;
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: space-between;
   align-items: center;
   input {
      -webkit-appearance: none;
      height: 14px;
      width: 14px;
      border-radius: 2px;
      margin: 0;
      border: 1px solid var(--black-100);
      outline: none;
      background-color: white;
      transform: scale(1.5);
   }
   input:checked {
      border: none;
      border-radius: 2px;
      color: white;
      background-color: black;
   }
   input:checked:after {
      border-radius: 2px;
      transform: scale(0.7);
      position: absolute;
      display: block;
      top: -1px;
      left: 0.3px;
      content: url(../../../../accests/image/Vector_check.svg);
   }
   p {
      font-weight: 500;
      font-size: 18px;
      padding-left: 10px;
      margin: 0;
      flex-grow: 10;
   }
}

@media screen and (max-width: 1250px) {
   .deliveryMethodsCheck {
      flex-wrap: wrap;
      width: 100%;
      gap: 12px;
   }
   .deliveryMethodsCheckSelects,
   .deliveryMethodsCheck > label {
      width: 100%;
      flex-wrap: wrap;
   }
}
